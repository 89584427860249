import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import DatePicker from "react-datepicker"
import Select from 'react-select'
import { Dropdown } from 'react-bootstrap'
import XLSX from 'xlsx'

import BalanceDetails from './BalanceDetails'
import FilterSearch from 'pages/parts/FilterSearch'
import FilterPagination from 'pages/parts/FilterPagination'

import { GET, httpOk } from 'helpers/api'
import { formatMoney, returnSign, findKeyFromArrayByValue, formatDate } from 'helpers/helpers'

function Index() {
	const { t } = useTranslation()

	const reduxAccount = useSelector(state => state.account)

	const quantityOrders = [
		{ 'id': null, 'name': t('all') },
		{ 'id': 'above_zero', 'name': `${t('quantity')} > 0` },
		{ 'id': 'less_zero', 'name': `${t('quantity')} < 0` }
	]

	const [poses, setPoses] = useState([])
	const [elementTypes, setElementTypes] = useState([])
	const [wms, setWms] = useState([])
	const [categories, setCategories] = useState([])
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		'date': formatDate(new Date(), 'yyyy-MM-dd'),
		'pos_id': reduxAccount.pos_id,
		'quantity_order': null,
		'modalProducts': false,
		'search': '',
	})
	const [pagination, setPagination] = useState({
		url: '/report-balance',
		response: null
	})
	const [data, setData] = useState({
		'data': [],
		'total': 0,
		'total_amount': 0,
	})

	async function searchWithFilter() {
		var filterUrl = "/report-balance"
		var urlParams = ""
		checkFilter()
		var filterDataCopy = { ...filterData }

		if (filterDataCopy.pos_id)
			urlParams += returnSign(urlParams) + 'pos_id=' + filterDataCopy.pos_id
		if (filterDataCopy.element_type)
			urlParams += returnSign(urlParams) + 'element_type=' + filterDataCopy.element_type
		if (filterDataCopy.wms_id)
			urlParams += returnSign(urlParams) + 'wms_id=' + filterDataCopy.wms_id
		if (filterDataCopy.category_id)
			urlParams += returnSign(urlParams) + 'category_id=' + filterDataCopy.category_id
		if (filterDataCopy.quantity_order)
			urlParams += returnSign(urlParams) + 'quantity_order=' + filterDataCopy.quantity_order
		if (filterDataCopy.date)
			urlParams += returnSign(urlParams) + 'date=' + filterDataCopy.date
		if (filterDataCopy.search)
			urlParams += returnSign(urlParams) + 'search=' + filterDataCopy.search
		if (filterDataCopy.page)
			urlParams += returnSign(urlParams) + 'page=' + filterDataCopy.page

		filterDataCopy.urlParams = urlParams

		if (`/report-balance${urlParams}` !== filterUrl)
			setFilterDataExist(true)

		filterUrl = filterUrl + urlParams

		const response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) {
			setData(response.data)
			setPagination({ ...pagination, 'url': filterUrl, 'response': response.data })
		}
		setFilterData(filterDataCopy)
		setDropdownIsOpen(false)
	}

	async function exportToExcel() {
		var filterUrl = `/report-balance-excel${filterData.urlParams}`

		var excelHeaders = [[t('pos'), t('name2'), t('quantity'), t('unit'), t('total_amount')]]
		var response = await GET(filterUrl)
		if (httpOk(response)) {
			response = response.data
			for (let i = 0; i < response.length; i++) {
				var excelItems = []
				excelItems.push(response[i]['pos_name'])
				excelItems.push(response[i]['element_name'])
				excelItems.push(Number(response[i]['balance']).toFixed(2))
				excelItems.push(response[i]['unit_name'])
				excelItems.push(Number(response[i]['total_amount']).toFixed(2))
				excelHeaders.push(excelItems)
			}
			excelHeaders.push(['', '', '', '', Number(data.total_amount).toFixed(2)])

			const ws = XLSX.utils.aoa_to_sheet(excelHeaders);
			const wb = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
			XLSX.writeFile(wb, t('balance_report') + ".xlsx");
		}
	}

	function resetFilter() {
		setFilterData({
			date: formatDate(new Date(), 'yyyy-MM-dd'),
			pos_id: null,
			supplier_id: null,
			category_id: null,
			search: '',
		})
		setFilterDataExist(false)
		searchWithFilter()
	}

	function checkFilter() {
		if (
			filterData.date === null &&
			filterData.pos_id === null &&
			filterData.supplier_id === null &&
			filterData.category_id === null &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function paginate(page) {
		setFilterData({ ...filterData, 'page': page })
	}

	async function toggleModal(bool = false, element_id) {
		setFilterData({ ...filterData, 'element_id': element_id, 'modalProducts': bool })
	}

	async function getPoses() {
		const response = await GET('/helper/poses')
		if (httpOk(response)) setPoses(response.data)
	}

	async function getElementTypes() {
		const response = await GET('/helper/products-element-types')
		if (httpOk(response)) setElementTypes(response.data)
	}

	async function getWms() {
		const response = await GET('/helper/wms')
		if (httpOk(response)) setWms(response.data)
	}

	async function getCategories() {
		const response = await GET('/helper/categories')
		if (httpOk(response)) setCategories(response.data)
	}

	useEffect(() => {
		getPoses()
		getElementTypes()
		getWms()
		getCategories()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		searchWithFilter(false)
	}, [filterData.search, filterData.page]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header">
				<h4 className="vertical-center h-100">
					{t('balance')} [{data.total}]
				</h4>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex justify-content-between flex-wrap mb-3">
						<div className="d-flex flex-wrap gap-2">
							<div>
								<FilterSearch
									filterData={filterData}
									setFilterData={setFilterData}>
								</FilterSearch>
							</div>
							<div>
								<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100">
										{t('filter')}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-filter">
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('pos')}</label>
												<Select
													options={poses}
													value={poses.find(option => option.id === filterData.pos_id) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'pos_id': option.id })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
											<div className="form-group col-md-6">
												<label>{t('type')}</label>
												<Select
													options={elementTypes}
													value={elementTypes.find(option => option.id === filterData.element_type) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'element_type': option.id })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('warehouse')}</label>
												<Select
													options={wms}
													value={wms.find(option => option.id === filterData.wms_id) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'wms_id': option.id })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
											<div className="form-group col-md-6">
												<label>{t('category')}</label>
												<Select
													options={categories}
													value={categories.find(option => option.id === filterData.category_id) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'category_id': option.id })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('date')}</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.date ? new Date(filterData.date) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'date': formatDate(date, 'yyyy-MM-dd') })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
											<div className="form-group col-md-6">
												<label>{t('quantity')}</label>
												<Select
													options={quantityOrders}
													value={quantityOrders.find(option => option.id === filterData.quantity_order) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'quantity_order': option.id })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex justify-content-end gap-2">
													<button className="btn btn-secondary"
														onClick={() => setDropdownIsOpen(false)}>
														{t('cancel')}
													</button>
													<button className="btn btn-primary"
														onClick={() => searchWithFilter(false)}>
														{t('apply')}
													</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
							<div>
								<button className="btn btn-outline-primary btn-wide"
									onClick={() => exportToExcel()}>
									EXCEL
								</button>
							</div>
						</div>
						<div className="vertical-center">
							<h4>
								<b>{t('amount')}</b>: {formatMoney(data.total_amount)}
							</h4>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('filter')}</strong>
								<br />
								<span className="me-2">
									<b>{t('date')}: </b>
									{filterData.date &&
										<span>{formatDate(filterData.date)}</span>
									}
								</span>
								{filterData.pos_id &&
									<span className="me-2">
										<b>{t('pos')}: </b>
										{findKeyFromArrayByValue(poses, 'id', filterData.pos_id, 'name')}
									</span>
								}
								{filterData.element_type &&
									<span className="me-2">
										<b>{t('type')}: </b>
										{findKeyFromArrayByValue(elementTypes, 'id', filterData.element_type, 'name')}
									</span>
								}
								{filterData.quantity_order &&
									<span className="me-2">
										<b>{t('quantity')}: </b>
										{findKeyFromArrayByValue(quantityOrders, 'id', filterData.quantity_order, 'name')}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('search')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase"
									onClick={resetFilter}>
									{t('reset')}
								</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '20%' }}>{t('pos')}</th>
									<th style={{ 'width': '20%' }}
										onClick={() => searchWithFilter('name')}>
										{t('name2')}
									</th>
									<th style={{ 'width': '10%' }}>{t('type')}</th>
									<th style={{ 'width': '20%' }}></th>
									<th style={{ 'width': '10%' }} className="text-end"
										onClick={() => searchWithFilter('balance')}>
										{t('quantity')}
									</th>
									<th style={{ 'width': '10%' }}>{t('unit')}</th>
									<th style={{ 'width': '10%' }} className="text-end"
										onClick={() => searchWithFilter('total_amount')}>
										{t('amount')}
									</th>
								</tr>
							</thead>
							<tbody>
								{data?.data?.map((item, index) => (
									<tr key={index}>
										<td className="text-center">{item.row_number}</td>
										<td>{item.pos_name}</td>
										<td className="text-primary cursor table-first-column-clickable"
											onClick={() => toggleModal(true, item.element_id)}>
											{item.element_name}
										</td>
										<td>{item.product_type_name}</td>
										<td></td>
										<td className="text-end">
											<span className={`${item.balance < 0 ? 'text-danger' : ''}`}>{formatMoney(item.balance, 2)}</span>
										</td>
										<td>{item.unit_name}</td>
										<td className="text-end">{formatMoney(item.total_amount)}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					{pagination.response &&
						<FilterPagination
							pagination={pagination}
							paginate={paginate}>
						</FilterPagination>
					}
				</div>
			</div>

			<BalanceDetails
				filterDataParent={filterData}
				setFilterDataParent={setFilterData}
				poses={poses}>
			</BalanceDetails>
		</>
	)
}

export default Index