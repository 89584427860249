import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Modal, Dropdown } from 'react-bootstrap'
import DatePicker from "react-datepicker"
import Select from 'react-select'
import XLSX from 'xlsx';

import DeleteModal from 'pages/parts/DeleteModal'
import FilterSearch from 'pages/parts/FilterSearch'
import FilterPagination from 'pages/parts/FilterPagination'

import Rightbar from './Rightbar'
import { GET, DELETE, httpOk } from 'helpers/api'
import { formatDate, returnSign, formatMoney, findKeyFromArrayByValue } from 'helpers/helpers'
import CustomTooltip from "pages/parts/CustomTooltip"

function Index() {
	const { t } = useTranslation()
	const date = new Date()
	
	const reduxAccount = useSelector(state => state.account)

	const paymentTypes = [
		{ 'id': 1, 'name': t('cash') },
		{ 'id': 2, 'name': t('terminal') }
	]

	const orderTypes = [
		{ "id": 1, "name": t('hall') },
		{ "id": 2, "name": t('takeaway') },
		{ "id": 3, "name": t('delivery') },
	]

	const orderDeliveryTypes = [
		{ "id": 1, "name": t('pos') },
		{ "id": 2, "name": t('telegram') },
		{ "id": 3, "name": t('call_center') },
		{ "id": 4, "name": t('website') },
		{ "id": 5, "name": t('MOBILE_APP') },
	]

	const statuses = [
		{ 'id': 10, 'name': t('success') },
		{ 'id': -1, 'name': t('deleted') },
	]

	const chequeTypes = [
		{ 'id': 'loyalty', 'name': t('loyalty') },
		{ 'id': 'debt', 'name': t('debt') },
		{ 'id': 'discount', 'name': t('discount') },
		{ 'id': 'agents', 'name': t('agents_bonus') },
		{ 'id': 'bool_no_profit', 'name': t('no_profit') },
		{ 'id': 'bool_no_service_percentage', 'name': t('no_service_percentage') },
		{ 'id': 'organizations', 'name': t('organization') },
	]

	const [poses, setPoses] = useState([])
	const [cashiers, setCashiers] = useState([])
	const [couriers, setCouriers] = useState([])
	const [waiters, setWaiters] = useState([])
	const [operators, setOperators] = useState([])
	const [halls, setHalls] = useState([])
	const [modalDelete, setModalDelete] = useState(false)
	const [deleteModalParams, setDeleteModalParams] = useState({ id: 0, index: 0 })
	const [showModal, setShowModal] = useState(false)
	const [showRightBar, setShowRightBar] = useState(false)
	const [selectedItem, setSelectedItem] = useState({})
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		start_date: formatDate(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd'),
		end_date: formatDate(new Date(), 'yyyy-MM-dd'),
		pos_id: reduxAccount.pos_id,
		status: 10,
		random: 0,
		search: '',
	})
	const [pagination, setPagination] = useState({
		url: '/cheques',
		response: null
	})
	const [data, setData] = useState([])

	async function searchWithFilter() {
		var filterDataCopy = { ...filterData }
		var filterUrl = "/cheques"
		var urlParams = ""
		checkFilter()

		if (filterDataCopy.pos_id)
			urlParams += returnSign(urlParams) + 'pos_id=' + filterDataCopy.pos_id
		if (filterDataCopy.id)
			urlParams += returnSign(urlParams) + 'id=' + filterDataCopy.id
		if (filterDataCopy.cashier_login)
			urlParams += returnSign(urlParams) + 'cashier_login=' + filterDataCopy.cashier_login
		if (filterDataCopy.waiter_login)
			urlParams += returnSign(urlParams) + 'waiter_login=' + filterDataCopy.waiter_login
		if (filterDataCopy.operator_login)
			urlParams += returnSign(urlParams) + 'operator_login=' + filterDataCopy.operator_login
		if (filterDataCopy.courier_login)
			urlParams += returnSign(urlParams) + 'courier_login=' + filterDataCopy.courier_login
		if (filterDataCopy.order_type)
			urlParams += returnSign(urlParams) + 'order_type=' + filterDataCopy.order_type
		if (filterDataCopy.order_type_delivery)
			urlParams += returnSign(urlParams) + 'order_type_delivery=' + filterDataCopy.order_type_delivery
		if (filterDataCopy.status)
			urlParams += returnSign(urlParams) + 'status=' + filterDataCopy.status
		if (filterDataCopy.shift_id)
			urlParams += returnSign(urlParams) + 'shift_id=' + filterDataCopy.shift_id
		if (filterDataCopy.client_phone)
			urlParams += returnSign(urlParams) + 'client_phone=' + filterDataCopy.client_phone
		if (filterDataCopy.courier_id)
			urlParams += returnSign(urlParams) + 'courier_id=' + filterDataCopy.courier_id
		if (filterDataCopy.hall_id)
			urlParams += returnSign(urlParams) + 'hall_id=' + filterDataCopy.hall_id
		if (filterDataCopy.payment_type_id)
			urlParams += returnSign(urlParams) + 'payment_type_id=' + filterDataCopy.payment_type_id
		if (filterDataCopy.cheque_type)
			urlParams += returnSign(urlParams) + 'cheque_type=' + filterDataCopy.cheque_type
		if (filterDataCopy.start_date)
			urlParams += returnSign(urlParams) + 'start_date=' + filterDataCopy.start_date
		if (filterDataCopy.end_date)
			urlParams += returnSign(urlParams) + 'end_date=' + filterDataCopy.end_date
		if (filterDataCopy.search)
			urlParams += returnSign(urlParams) + 'search=' + filterDataCopy.search
		if (filterDataCopy.page)
			urlParams += returnSign(urlParams) + 'page=' + filterDataCopy.page
		filterDataCopy.urlParams = urlParams

		if (`/cheques${urlParams}` !== filterUrl)
			setFilterDataExist(true)

		if (filterDataCopy.sort) {
			if (filterDataCopy.tableSortKey === filterDataCopy.sort) {
				filterDataCopy.tableSortDirection === ',asc' ? filterDataCopy.tableSortDirection = ',desc' : filterDataCopy.tableSortDirection = ',asc'
			} else {
				filterDataCopy.tableSortDirection = ',asc'
			}
			filterDataCopy.tableSortKey = filterDataCopy.sort
			urlParams += returnSign(urlParams) + 'sort=' + filterDataCopy.sort + filterDataCopy.tableSortDirection
		}

		filterUrl = filterUrl + urlParams

		const response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) {
			setData(response.data)
			setPagination({ ...pagination, 'url': filterData.url, 'response': response.data })
		}

		setFilterData(filterDataCopy)
		setDropdownIsOpen(false)
	}

	async function exportToExcel() {
		var filterUrl = `/cheques-excel${filterData.urlParams}`

		var excelHeaders = [['ID', t('pos'), t('cashbox'), t('cashier'), t('waiter'), t('check_number'),
			t('guests'), t('cheque_date'), t('sync_date'), t('total')]]
		const response = await GET(filterUrl)
		if (httpOk(response)) {
			for (let i = 0; i < response.data.length; i++) {
				var item = response.data[i]
				var excelItems = []
				excelItems.push(item.id)
				excelItems.push(item.pos_name)
				excelItems.push(item.cashbox_name)
				excelItems.push(item.cashier_first_name)
				excelItems.push(item.waiter_first_name)
				excelItems.push(item.cheque_number)
				excelItems.push(item.people_number)
				excelItems.push(item.cheque_closed_at)
				excelItems.push(formatDate(item.created_at, 'dd.MM.yy HH:mm'))
				excelItems.push(Number(item.total_amount_service))
				excelHeaders.push(excelItems)
			}

			const ws = XLSX.utils.aoa_to_sheet(excelHeaders);
			const wb = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
			XLSX.writeFile(wb, t('cheques') + ".xlsx");

		}
	}

	async function chequeTotals() {
		var dataCopy = { ...data }

		const response = await GET(`/cheques-details${filterData.urlParams}`, {}, { loader: true })
		if (httpOk(response)) {
			dataCopy.total_hall = response.data.total_hall
			dataCopy.total_takeaway = response.data.total_takeaway
			dataCopy.total_delivery = response.data.total_delivery
			dataCopy.total_delivery_manager = response.data.total_delivery_manager
			dataCopy.total_delivery_telegram = response.data.total_delivery_telegram
			dataCopy.total_delivery_call_center = response.data.total_delivery_call_center
			dataCopy.total_people_number = response.data.total_people_number
			dataCopy.total_deleted = response.data.total_deleted
			dataCopy.total_discount = response.data.total_discount
			dataCopy.total_delivery_price = response.data.total_delivery_price
			dataCopy.total_agents = response.data.total_agents
			dataCopy.total_debtor = response.data.total_debtor
			dataCopy.total_no_profit = response.data.total_no_profit
			dataCopy.total_no_service_percentage = response.data.total_no_service_percentage
			setData(dataCopy)
			setShowModal(true)
		}
	}

	async function deleteItem() {
		const response = await DELETE(`/cheques/${deleteModalParams.id}`, {}, { loader: true })
		if (httpOk(response)) {
			searchWithFilter()
			toggleModalDelete(false)
		}
	}

	async function toggleRightbar(boolean = false, item, index) {
		var dataCopy = [...data.data]

		for (let i = 0; i < dataCopy.length; i++) {
			dataCopy[i]['showSidebar'] = false
		}
		if (boolean) {
			const response = await GET(`/cheque-params/${item.id}`, {}, { loader: true })
			if (httpOk(response)) {
				dataCopy[index]['products'] = response.data.products
				dataCopy[index]['payments'] = response.data.payments
				dataCopy[index]['loyalty'] = response.data.loyalty
				dataCopy[index]['showSidebar'] = boolean

				setSelectedItem(dataCopy[index])
				setShowRightBar(boolean)
			}

		} else {
			setShowRightBar(boolean)
			setSelectedItem({})
		}
	}

	async function handleSelect(option) {
		getHalls(option.id)
		getCashiers(option.id)
		getWaiters(option.id)
		getCouriers(option.id)

		setFilterData({ ...filterData, 'pos_id': option.id })
	}

	function toggleModalDelete(bool) {
		if (bool) {
			setDeleteModalParams({ id: selectedItem.id })
			setShowRightBar(false)
		}

		setModalDelete(bool)
	}

	function resetFilter() {
		var filterDataCopy = {
			start_date: formatDate(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd'),
			end_date: formatDate(new Date(), 'yyyy-MM-dd'),
			pos_id: null,
			status: null,
			cashier_login: null,
			search: '',
			random: filterData.random + 1
		}
		setFilterData(filterDataCopy)
	}

	async function checkFilter() {
		if (
			filterData.pos_id === null &&
			filterData.cashier_login === null &&
			filterData.status === null &&
			filterData.start_date === null &&
			filterData.end_date === null &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function paginate(page) {
		setFilterData({ ...filterData, 'page': page })
	}

	async function getPoses() {
		const response = await GET('/helper/poses')
		if (httpOk(response)) {
			setPoses(response.data)
		}
	}

	async function getCashiers(pos_id) {
		const response = await GET(`/helper/users-type?pos_id=${pos_id}&type=cashier`)
		if (httpOk(response)) setCashiers(response.data)
	}

	async function getWaiters(pos_id) {
		const response = await GET(`/helper/users-type?pos_id=${pos_id}&type=waiter`)
		if (httpOk(response)) setWaiters(response.data)
	}

	async function getOperators(pos_id) {
		const response = await GET(`/helper/users-type?pos_id=${pos_id}&type=operator`)
		if (httpOk(response)) setOperators(response.data)
	}

	async function getCouriers(pos_id) {
		const response = await GET(`/helper/users-type?pos_id=${pos_id}&type=courier`)
		if (httpOk(response)) setCouriers(response.data)
	}

	async function getHalls(pos_id) {
		const response = await GET(`/helper/halls?pos_id=${pos_id}`)
		if (httpOk(response)) setHalls(response.data)
	}

	useEffect(() => {
		getPoses()
		getHalls(reduxAccount.pos_id)
		getCashiers(reduxAccount.pos_id)
		getWaiters(reduxAccount.pos_id)
		getCouriers(reduxAccount.pos_id)
		getOperators(reduxAccount.pos_id)
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		searchWithFilter()
	}, [filterData.search, filterData.page, filterData.random]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex mb-2">
				<h4 className="vertical-center">
					{t('cheques')} [{data?.total}]
				</h4>
			</div>

			<div className="card">
				<div className="card-body">

					<div className="d-flex justify-content-between mb-3">
						<div className="d-flex gap-2">
							<div>
								<FilterSearch
									filterData={filterData}
									setFilterData={setFilterData}>
								</FilterSearch>
							</div>
							<div>
								<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100">
										{t('filter')}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-filter">
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('pos')}</label>
												<Select
													options={poses}
													value={poses.find(option => option.id === filterData.pos_id) || ''}
													onChange={(option) => handleSelect(option)}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
											<div className="form-group col-md-6">
												<label>{t('status')}</label>
												<Select
													isClearable
													options={statuses}
													value={statuses.find(option => option.id === filterData.status) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'status': option ? option.id : null })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('cashier')}</label>
												<Select
													options={cashiers}
													value={cashiers.find(option => option.login === filterData.cashier_login) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'cashier_login': option ? option.login : null })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.first_name}
													getOptionValue={(option) => option.login}
												/>
											</div>
											<div className="form-group col-md-6">
												<label>{t('waiter')}</label>
												<Select
													options={waiters}
													value={waiters.find(option => option.login === filterData.waiter_login) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'waiter_login': option ? option.login : null })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.first_name}
													getOptionValue={(option) => option.login}
												/>
											</div>
										</div>
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('courier')}</label>
												<Select
													options={couriers}
													value={couriers.find(option => option.login === filterData.courier_login) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'courier_login': option ? option.login : null })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.first_name}
													getOptionValue={(option) => option.login}
												/>
											</div>
											<div className="form-group col-md-6">
												<label>{t('operators')}</label>
												<Select
													options={operators}
													value={operators.find(option => option.login === filterData.operator_login) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'operator_login': option ? option.login : null })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.first_name}
													getOptionValue={(option) => option.login}
												/>
											</div>
										</div>
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('order_type')}</label>
												<Select
													isClearable
													options={orderTypes}
													value={orderTypes.find(option => option.id === filterData.order_type) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'order_type': option ? option.id : null })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
											<div className="form-group col-md-6">
												<label>{t('delivery_type')}</label>
												<Select
													isClearable
													options={orderDeliveryTypes}
													value={orderDeliveryTypes.find(option => option.id === filterData.order_type_delivery) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'order_type_delivery': option ? option.id : null })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('halls')}</label>
												<Select
													isClearable
													options={halls}
													value={halls.find(option => option.id === filterData.hall_id) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'hall_id': option ? option.id : null })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
											<div className="form-group col-md-6">
												<label>{t('payment')}</label>
												<Select
													isClearable
													options={paymentTypes}
													value={paymentTypes.find(option => option.id === filterData.payment_type_id) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'payment_type_id': option ? option.id : null })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('cheque')} ID</label>
												<input type="text" className="form-control"
													onChange={(e) => setFilterData({ ...filterData, 'id': e.target.value })} />
											</div>
											<div className="form-group col-md-6">
												<label>{t('cheque_type')}</label>
												<Select
													isClearable
													options={chequeTypes}
													value={chequeTypes.find(option => option.id === filterData.cheque_type) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'cheque_type': option ? option.id : null })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('shift')} ID</label>
												<input type="text" className="form-control"
													onChange={(e) => setFilterData({ ...filterData, 'shift_id': e.target.value })} />
											</div>
											<div className="form-group col-md-6">
												<label>{t('client')} {t('phone')}</label>
												<input type="text" className="form-control"
													onChange={(e) => setFilterData({ ...filterData, 'client_phone': e.target.value })} />
											</div>
										</div>
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('period')}</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.start_date ? new Date(filterData.start_date) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'start_date': formatDate(date, 'yyyy-MM-dd') })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
											<div className="form-group col-md-6">
												<label className="invisible">.</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.end_date ? new Date(filterData.end_date) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'end_date': formatDate(date, 'yyyy-MM-dd') })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex justify-content-end gap-2">
													<button className="btn btn-secondary" onClick={() => setDropdownIsOpen(false)}>{t('cancel')}</button>
													<button className="btn btn-primary" onClick={() => searchWithFilter()}>{t('apply')}</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
							<div>
								<button type="button" className="btn btn-outline-primary btn-wide-2"
									onClick={() => exportToExcel()}>
									<CustomTooltip text={t('EXCEL')}>
										<i className="uil uil-file-download-alt"></i>
									</CustomTooltip>
								</button>
							</div>
							<div>
								<button type="button" className="btn btn-outline-primary btn-wide-2"
									onClick={() => chequeTotals()}>
									<CustomTooltip text={t('additional_info')}>
										<i className="uil uil-comment-info"></i>
									</CustomTooltip>
								</button>
							</div>
						</div>
						<div className="vertical-center">
							<h4 className="text-end">
								<b>{t('amount')}</b>: {formatMoney(data.total_cheques_amount)}
							</h4>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('filter')}</strong>
								<br />
								{(filterData.start_date || filterData.end_date) &&
									<span className="me-2">
										<b>{t('period')}: </b>
										{filterData.start_date &&
											<span>{formatDate(filterData.start_date)}</span>
										}
										{(filterData.start_date && filterData.end_date) &&
											<span>|</span>
										}
										{filterData.end_date &&
											<span>{formatDate(filterData.end_date)}</span>
										}
									</span>
								}
								{filterData.pos_id &&
									<span className="me-2">
										<b>{t('pos')}: </b>
										{findKeyFromArrayByValue(poses, 'id', filterData.pos_id, 'name')}
									</span>
								}
								{filterData.status &&
									<span className="me-2">
										<b>{t('status')}: </b>
										{findKeyFromArrayByValue(statuses, 'id', filterData.status, 'name')}
									</span>
								}
								{filterData.cheque_type &&
									<span className="me-2">
										<b>{t('cheque_type')}: </b>
										{findKeyFromArrayByValue(chequeTypes, 'id', filterData.cheque_type, 'name')}
									</span>
								}
								{filterData.cashier_login &&
									<span className="me-2">
										<b>{t('cashier')}: </b>
										{findKeyFromArrayByValue(cashiers, 'login', filterData.cashier_login, 'first_name')}
									</span>
								}
								{filterData.courier_login &&
									<span className="me-2">
										<b>{t('courier')}: </b>
										{findKeyFromArrayByValue(couriers, 'login', filterData.courier_login, 'first_name')}
									</span>
								}
								{filterData.id &&
									<span className="me-2">
										<b>ID: </b> {filterData.id}
									</span>
								}
								{filterData.shift_id &&
									<span className="me-2">
										<b>{t('shift')} ID: </b> {filterData.shift_id}
									</span>
								}
								{filterData.hall_id &&
									<span className="me-2">
										<b>{t('hall')}: </b>
										{findKeyFromArrayByValue(halls, 'id', filterData.hall_id, 'name')}
									</span>
								}
								{(filterData.order_type) &&
									<span className="me-2">
										<b>{t('order_type')}: </b>
										{findKeyFromArrayByValue(orderTypes, 'id', filterData.order_type, 'name')}
									</span>
								}
								{(filterData.order_type_delivery) &&
									<span className="me-2">
										<b>{t('delivery_type')}: </b>
										{findKeyFromArrayByValue(orderDeliveryTypes, 'id', filterData.order_type_delivery, 'name')}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('search')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase"
									onClick={() => resetFilter()}>
									{t('reset')}
								</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-bordered table-hover mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '20%' }}>{t('pos')}</th>
									<th style={{ 'width': '10%' }}>{t('cashier')}</th>
									<th style={{ 'width': '10%' }}>{t('waiter')}</th>
									<th style={{ 'width': '10%' }} className=" text-center">
										{t('cheque')} №
									</th>
									<th style={{ 'width': '10%' }}>{t('hall')}</th>
									<th style={{ 'width': '10%' }}>{t('table')}</th>
									<th style={{ 'width': '10%' }} className="text-center">{t('open_date')}</th>
									<th style={{ 'width': '10%' }} className="text-center">{t('close_date')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('total')}</th>
								</tr>
							</thead>
							<tbody>
								{data?.data?.map((item, index) => (
									<tr className={`cursor ${item.showSidebar ? 'table-tr-active' : ''}`} key={index}>
										<td className="text-center">
											<CustomTooltip text={item.id}>
												{item.row_number}
											</CustomTooltip>
										</td>
										<td className="td-ellipsis" onClick={() => toggleRightbar(true, item, index)}>
											{item.pos_short_name}
										</td>
										<td className="td-ellipsis" onClick={() => toggleRightbar(true, item, index)}>
											{item.cashier_first_name}
										</td>
										<td className="td-ellipsis" onClick={() => toggleRightbar(true, item, index)}>
											{item.waiter_first_name}
										</td>
										<td onClick={() => toggleRightbar(true, item, index)} className="text-center">
											{item.order_type === 1 &&
												<CustomTooltip text={t('hall')}>
													<span className="bg-primary bg-border text-white">
														<span>{item.cheque_number}</span>
													</span>
												</CustomTooltip>
											}
											{item.order_type === 2 &&
												<CustomTooltip text={t('takeaway')}>
													<span className="bg-success bg-border text-white">
														<span>{item.cheque_number}</span>
													</span>
												</CustomTooltip>
											}
											{item.order_type === 3 &&
												<CustomTooltip text={t('delivery')}>
													<span className="bg-warning bg-border text-white">
														<span>{item.cheque_number}</span>
													</span>
												</CustomTooltip>
											}
										</td>
										<td onClick={() => toggleRightbar(true, item, index)}>
											{item.hall_name}
										</td>
										<td onClick={() => toggleRightbar(true, item, index)}>
											{item.table_name}
										</td>
										<td onClick={() => toggleRightbar(true, item, index)} className="text-center">
											{formatDate(item.cheque_opened_at, 'dd.MM.yy HH:mm')}
										</td>
										<td onClick={() => toggleRightbar(true, item, index)} className="text-center">
											{formatDate(item.cheque_closed_at, 'dd.MM.yy HH:mm')}
										</td>
										<td className={`text-end text-nowrap ${item.client_debtor_id ? 'bg-danger text-white' : ''}`}
											onClick={() => toggleRightbar(true, item, index)}>
											{formatMoney(item.total_amount_service)}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					{pagination.response &&
						<FilterPagination
							pagination={pagination}
							paginate={paginate}>
						</FilterPagination>
					}
				</div>
			</div>

			{/* MODAL ADDITIONAL INFO */}
			<Modal
				show={showModal} animation={false} centered
				dialogClassName="update-modal-width"
				onHide={() => setShowModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('additional_info')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="d-flex justify-content-between mb-1">
						<span>{t('guests')}</span>
						<span>{data.total_people_number}</span>
					</div>
					<div className="d-flex justify-content-between mb-1">
						<span>{t('number_of_checks')}</span>
						<span>{data.total}</span>
					</div>
					<div className="d-flex justify-content-between mb-1">
						<span>{t('number_of_checks')} [ {t('hall')} ]</span>
						<span>{data.total_hall}</span>
					</div>
					<div className="d-flex justify-content-between mb-1">
						<span>{t('number_of_checks')} [ {t('takeaway')} ]</span>
						<span>{data.total_takeaway}</span>
					</div>
					<div className="d-flex justify-content-between mb-1">
						<span>{t('number_of_checks')} [ {t('delivery')} ]</span>
						<span>{data.total_delivery}</span>
					</div>
					<div className="d-flex justify-content-between mb-1 ms-3">
						<span>{t('number_of_checks')} [ {t('manager')} ]</span>
						<span>{data.total_delivery_manager}</span>
					</div>
					<div className="d-flex justify-content-between mb-1 ms-3">
						<span>{t('number_of_checks')} [ {t('telegram')} ]</span>
						<span>{data.total_delivery_telegram}</span>
					</div>
					<div className="d-flex justify-content-between mb-1 ms-3">
						<span>{t('number_of_checks')} [ {t('call_center')} ]</span>
						<span>{data.total_delivery_call_center}</span>
					</div>
					<div className="d-flex justify-content-between mb-1">
						<span>{t('number_of_checks')} [ {t('deleted')} ]</span>
						<span>{data.total_deleted}</span>
					</div>
					<div className="d-flex justify-content-between mb-1">
						<span>{t('number_of_checks')} [ {t('discount')} ]</span>
						<span>{data.total_discount}</span>
					</div>
					<div className="d-flex justify-content-between mb-1">
						<span>{t('number_of_checks')} [ {t('agents')} ]</span>
						<span>{data.total_agents}</span>
					</div>
					<div className="d-flex justify-content-between mb-1">
						<span>{t('number_of_checks')} [ {t('debt')} ]</span>
						<span>{data.total_debtor}</span>
					</div>
					<div className="d-flex justify-content-between mb-1">
						<span>{t('number_of_checks')} [ {t('no_profit')} ]</span>
						<span>{data.total_no_profit}</span>
					</div>
					<div className="d-flex justify-content-between mb-1">
						<span>{t('number_of_checks')} [ {t('no_service_percentage')} ]</span>
						<span>{data.total_no_service_percentage}</span>
					</div>
					<div className="d-flex justify-content-between mb-1">
						<span>{t('discount')}</span>
						<span>{formatMoney(data.total_discount)}</span>
					</div>
					<div className="d-flex justify-content-between mb-1">
						<span>{t('delivery')}</span>
						<span>{formatMoney(data.total_delivery_price)}</span>
					</div>
				</Modal.Body>
			</Modal>
			{/* MODAL ADDITIONAL INFO */}

			<DeleteModal
				modalDelete={modalDelete}
				setModalDelete={setModalDelete}
				deleteItem={deleteItem}>
			</DeleteModal>

			<Rightbar
				showRightBar={showRightBar}
				setShowRightBar={setShowRightBar}
				toggleModalDelete={toggleModalDelete}
				selectedItem={selectedItem}>
			</Rightbar>
		</>
	)
}

export default Index
