import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from "react-redux"
import { Modal, Dropdown } from 'react-bootstrap'
import DatePicker from "react-datepicker"
import Select from 'react-select'
import XLSX from 'xlsx'

import FilterSearch from 'pages/parts/FilterSearch'
import FilterPagination from 'pages/parts/FilterPagination'
import { SET_FILTER_DATA } from "store/filterData/actions"

import { GET, POST, PUT, httpOk } from 'helpers/api'
import {
	formatMoney, returnSign, findKeyFromArrayByValue,
	formatDate, returnMinDate
} from 'helpers/helpers'
import CustomTooltip from "pages/parts/CustomTooltip"

function Index() {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const date = new Date()

	const reduxAccount = useSelector(state => state.account)
	const reduxFilterData = useSelector(state => state.filterData)

	// const searchTypes = [
	// 	{ 'id': 'note', 'name': t('note') },
	// 	{ 'id': 'amount_out', 'name': t('amount') },
	// ]

	const accountTypes = [
		{ 'id': 1, 'name': t('safe') },
		{ 'id': 2, 'name': t('bank_account') },
	]

	const [modalExpense, setModalExpense] = useState(false);
	const [expense, setExpense] = useState({
		'pos_id': reduxAccount.pos_id,
		'payment_purpose_id': 6,
		'payment_type_id': 1,
		'amount_out': '',
		'amount_in': 0,
		'note': '',
		'account_type': 1,
		'created_at': formatDate(date, 'yyyy-MM-dd'),
	});
	const [poses, setPoses] = useState([])
	const [cashiers, setCashiers] = useState([])
	const [paymentPurposeTypes, setPaymentPurposeTypes] = useState([])
	const [totalAmount, setTotalAmont] = useState(0)
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		start_date: formatDate(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd'),
		end_date: formatDate(new Date(), 'yyyy-MM-dd'),
		pos_id: reduxAccount?.pos_id,
		account_type: null,
		search: '',
	})
	const [pagination, setPagination] = useState({
		url: '/report-expenses',
		response: null
	})
	const [data, setData] = useState([])

	async function createExpense(e) {
		e.preventDefault();

		try {
			if (!expense.id) {
				await POST('/expense-web', expense, { loader: true })
				setModalExpense(false)
			} else {
				await PUT('/expense', expense, { loader: true })
			}
			setModalExpense(false)
			setExpense({
				'payment_purpose_id': 6,
				'payment_type_id': 1,
				'amount_out': '',
				'amount_in': 0,
				'note': '',
				'account_type': 1,
				'created_at': formatDate(date, 'yyyy-MM-dd'),
			})
			searchWithFilter()
		} catch (error) {
			//
		}
	}

	async function searchWithFilter(settings = {}, tableSort = "") {
		var filterUrl = "/report-expenses"
		var urlParams = ""
		checkFilter()

		var filterDataCopy = { ...filterData }
		if (settings?.reduxData) {
			filterDataCopy = reduxFilterData
		}

		if (filterDataCopy.pos_id)
			urlParams += returnSign(urlParams) + 'pos_id=' + filterDataCopy.pos_id
		if (filterDataCopy.payment_purpose_id)
			urlParams += returnSign(urlParams) + 'payment_purpose_id=' + filterDataCopy.payment_purpose_id
		if (filterDataCopy.cashier_login)
			urlParams += returnSign(urlParams) + 'cashier_login=' + filterDataCopy.cashier_login
		if (filterDataCopy.user_login)
			urlParams += returnSign(urlParams) + 'category_id=' + filterDataCopy.category_id
		if (filterDataCopy.start_date)
			urlParams += returnSign(urlParams) + 'start_date=' + filterDataCopy.start_date
		if (filterDataCopy.end_date)
			urlParams += returnSign(urlParams) + 'end_date=' + filterDataCopy.end_date
		if (filterDataCopy.search)
			urlParams += returnSign(urlParams) + 'search=' + filterDataCopy.search
		if (filterDataCopy.page)
			urlParams += returnSign(urlParams) + 'page=' + filterDataCopy.page

		if (`/report-expenses${urlParams}` !== filterUrl && !settings?.exportToExcel)
			setFilterDataExist(true)

		if (settings?.tableSort) {

			if (filterDataCopy.tableSortKey === settings?.tableSort) {
				filterDataCopy.tableSortDirection === ',asc' ? filterDataCopy.tableSortDirection = ',desc' : filterDataCopy.tableSortDirection = ',asc'
			} else {
				filterDataCopy.tableSortDirection = ',asc'
			}
			filterDataCopy.tableSortKey = settings?.tableSort

			setFilterData(filterDataCopy)
			urlParams += returnSign(urlParams) + 'sort=' + settings?.tableSort + filterDataCopy.tableSortDirection
		}

		filterUrl = filterUrl + urlParams

		if (settings?.exportToExcel) {
			filterUrl = "/report-expenses-excel" + urlParams + returnSign(filterUrl)

			var excelHeaders = [[t('pos'), t('cashbox'), t('cashier'), t('expense_type'), t('staff'),
			t('note'), t('date'), t('expense_amount')]]
			var response = await GET(filterUrl)
			if (httpOk(response)) {
				response = response.data
				for (let i = 0; i < response.length; i++) {
					var excelItems = []

					excelItems.push(response[i]['pos_name'])
					excelItems.push(response[i]['cashbox_name'])
					excelItems.push(response[i]['cashier_first_name'])
					excelItems.push(response[i]['payment_purpose_name'])
					excelItems.push(response[i]['employee_first_name'])
					excelItems.push(response[i]['note'])
					excelItems.push(formatDate(response[i]['created_at'], 'dd.MM.yy HH:mm'))
					excelItems.push(response[i]['amount_out'])
					excelHeaders.push(excelItems)
				}

				const ws = XLSX.utils.aoa_to_sheet(excelHeaders);
				const wb = XLSX.utils.book_new();
				XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
				XLSX.writeFile(wb, t('expense_report') + ".xlsx");
			}
		} else {
			const response = await GET(filterUrl, {}, { loader: true })
			if (httpOk(response)) {
				setData(response.data.data)
				setPagination({ ...pagination, 'url': filterUrl, 'response': response.data })
				setTotalAmont(response.data.total_amount)
			}
		}

		setDropdownIsOpen(false)
	}

	function resetFilter() {
		setFilterData({
			start_date: formatDate(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd'),
			end_date: formatDate(new Date(), 'yyyy-MM-dd'),
			pos_id: null,
			search: '',
		})
		setFilterDataExist(false)
		searchWithFilter()
	}

	function checkFilter() {
		if (
			filterData.pos_id === null &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function paginate(page) {
		setFilterData({ ...filterData, 'page': page })
	}

	function toggleModal(bool, item = {}) {
		if (bool) {
			setExpense(item)
		} else {
			setExpense({
				'pos_id': reduxAccount.pos_id,
				'payment_purpose_id': 6,
				'payment_type_id': 1,
				'amount_out': '',
				'amount_in': 0,
				'note': '',
				'account_type': 1,
				'created_at': formatDate(date, 'yyyy-MM-dd HH:mm'),
			})
		}

		setModalExpense(bool)
	}

	async function getPoses() {
		const response = await GET('/helper/poses')
		if (httpOk(response)) setPoses(response.data)
	}

	async function getPaymentPurposeTypes() {
		const response = await GET('/helper/payment-purpose-types')
		if (httpOk(response)) setPaymentPurposeTypes(response.data)
	}

	async function getCashiers() {
		const response = await GET('/helper/cashiers')
		if (httpOk(response)) setCashiers(response.data)
	}

	useEffect(() => {
		getPoses()
		getPaymentPurposeTypes()
		getCashiers()
		if (reduxFilterData?.filterData?.url === "/report-expenses") {
			setFilterData(reduxFilterData.filterData)
			searchWithFilter({ exportToExcel: false, reduxData: true })
		} else {
			searchWithFilter()
		}

		return () => {
			dispatch(SET_FILTER_DATA({}))
		}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		searchWithFilter()
	}, [filterData.search, filterData.page]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header">
				<h4 className="vertical-center h-100">
					{t('expenses')} [{pagination?.response?.total}]
				</h4>
			</div>

			<div className="card">
				<div className="card-body">

					<div className="d-flex justify-content-between flex-wrap mb-3">
						<div className="d-flex flex-wrap gap-2">
							<div>
								<FilterSearch
									filterData={filterData}
									setFilterData={setFilterData}>
								</FilterSearch>
							</div>
							<div>
								<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100">
										{t('filter')}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-filter">
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('pos')}</label>
												<Select
													isClearable
													options={poses}
													value={poses.find(option => option.id === filterData.pos_id) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'pos_id': option.id ? option.id : null })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
											<div className="form-group col-md-6">
												<label>{t('expense_type')}</label>
												<Select
													isClearable
													options={paymentPurposeTypes}
													value={paymentPurposeTypes.find(option => option.id === filterData.payment_purpose_id) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'payment_purpose_id': option ? option.id : null })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('user')}</label>
												<Select
													isClearable
													options={cashiers}
													value={cashiers.find(option => option.login === filterData.cashier_login) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'cashier_login': option ? option.login : null })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.first_name}
													getOptionValue={(option) => option.login}
												/>
											</div>
											<div className="form-group col-md-6">
												<label>{t('expense')}</label>
												<Select
													isClearable
													options={accountTypes}
													value={accountTypes.find(option => option.id === filterData.account_type) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'account_type': option ? option.id : null })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('period')}</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.start_date ? new Date(filterData.start_date) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'start_date': formatDate(date, 'yyyy-MM-dd') })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
											<div className="form-group col-md-6">
												<label className="invisible">.</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.end_date ? new Date(filterData.end_date) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'end_date': formatDate(date, 'yyyy-MM-dd') })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex justify-content-end">
													<button className="btn btn-secondary me-2" onClick={() => setDropdownIsOpen(false)}>{t('cancel')}</button>
													<button className="btn btn-primary" onClick={() => searchWithFilter()}>{t('apply')}</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
							<div>
								<CustomTooltip text={t('EXCEL')}>
									<button className="btn btn-outline-primary btn-wide-2"
										onClick={() => searchWithFilter({ exportToExcel: true })}>
										<i className="uil uil-file-download-alt"></i>
									</button>
								</CustomTooltip>
							</div>
							<div>
								<button className="btn btn-outline-primary btn-wide"
									onClick={() => setModalExpense(true)}>
									{t('add')}
								</button>
							</div>
						</div>
						<div className="vertical-center">
							<h4>
								<b>{t('total_amount')}</b>: {formatMoney(totalAmount)}
							</h4>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('filter')}</strong>
								<br />
								{(filterData.start_date || filterData.end_date) &&
									<span className="me-2">
										<b>{t('period')}: </b>
										{filterData.start_date &&
											<span>{formatDate(filterData.start_date)}</span>
										}
										{(filterData.start_date && filterData.end_date) &&
											<span>|</span>
										}
										{filterData.end_date &&
											<span>{formatDate(filterData.end_date)}</span>
										}
									</span>
								}
								{filterData.pos_id &&
									<span className="me-2">
										<b>{t('pos')}: </b>
										{findKeyFromArrayByValue(poses, 'id', filterData.pos_id, 'name')}
									</span>
								}
								{filterData.cashier_login &&
									<span className="me-2">
										<b>{t('user')}: </b>
										{findKeyFromArrayByValue(cashiers, 'login', filterData.cashier_login, 'first_name')}
									</span>
								}
								{filterData.payment_purpose_id &&
									<span className="me-2">
										<b>{t('expense_type')}: </b>
										{findKeyFromArrayByValue(paymentPurposeTypes, 'id', filterData.payment_purpose_id, 'name')}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('search')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>{t('reset')}</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '20%' }}>{t('pos')}</th>
									<th style={{ 'width': '20%' }}>{t('user')}</th>
									<th style={{ 'width': '10%' }}>{t('expense')}</th>
									<th style={{ 'width': '10%' }}>{t('expense_type')}</th>
									<th style={{ 'width': '20%' }}>{t('note')}</th>
									<th style={{ 'width': '10%' }} className="text-center">
										{t('date')}
									</th>
									<th style={{ 'width': '10%' }} className="th-sortable text-center">
										{t('amount')}
									</th>
									<th style={{ 'minWidth': '33px' }}></th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr key={index}>
										<td className="text-center">{item.row_number}</td>
										<td>{item.pos_name}</td>
										<td>{item.created_by}</td>
										<td>
											{!item.account_type &&
												<span>{t('cashbox')}</span>
											}
											{item.account_type === 1 &&
												<span>{t('safe')}</span>
											}
											{item.account_type === 2 &&
												<span>{t('bank_account')}</span>
											}
										</td>
										<td>{item.payment_purpose_name}</td>
										<td>{item.note}</td>
										<td className="text-center">{formatDate(item.created_at, 'dd.MM.yy HH:mm')}</td>
										<td className="text-end">{formatMoney(item.amount_out)}</td>
										<td>
											{item.editable &&
												<div className="d-flex justify-content-center">
													<div className="table-action-button table-action-primary-button"
														onClick={() => toggleModal(true, item)}>
														<i className="uil-edit-alt"></i>
													</div>
												</div>
											}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					{pagination.response &&
						<FilterPagination
							pagination={pagination}
							paginate={paginate}>
						</FilterPagination>
					}
				</div>
			</div>

			{/* MODAL payment */}
			<Modal show={modalExpense} animation={false} centered dialogClassName="update-modal-width"
				backdrop="static" onHide={() => toggleModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('expense')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={createExpense} autoComplete="off">
						{!expense.id &&
							<>
								<div className="form-group">
									<label>{t('date')}</label>
									<div className="calendar-input">
										<DatePicker
											className="form-control"
											dateFormat="dd.MM.yyyy"
											selected={expense.created_at ? new Date(expense.created_at) : ''}
											onChange={(date) => setExpense({ ...expense, 'created_at': formatDate(date, 'yyyy-MM-dd') })}
											minDate={returnMinDate(new Date(), reduxAccount.backdate ?? 3)}
											maxDate={new Date()}
										/>
										<i className="uil uil-calendar-alt"></i>
									</div>
								</div>
								<div className="form-group">
									<label>{t('pos')}</label>
									<Select
										options={poses}
										value={poses.find(option => option.id === expense.pos_id)}
										onChange={(option) => setExpense({
											...expense,
											'pos_id': option.id,
											'safe_balance': option.safe_balance,
											'bank_balance': option.bank_balance,
										})}
										placeholder=""
										noOptionsMessage={() => t('list_empty')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>
								</div>
								<div className="d-flex justify-content-between mb-1">
									<span>{t('safe')}</span>
									<b>{formatMoney(expense.safe_balance)}</b>
								</div>
								<div className="d-flex justify-content-between mb-1">
									<span>{t('bank_account')}</span>
									<b>{formatMoney(expense.bank_balance)}</b>
								</div>
								<div className="form-group">
									<label>{t('payment_type')}</label>
									<Select
										options={accountTypes}
										value={accountTypes.find(option => option.id === expense.account_type)}
										onChange={(option) => setExpense({ ...expense, 'account_type': option.id })}
										placeholder=""
										noOptionsMessage={() => t('list_empty')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>
								</div>
								<div className="form-group">
									<label>{t('expense_type')}</label>
									<Select
										options={paymentPurposeTypes}
										value={paymentPurposeTypes.find(option => option.id === expense.payment_purpose_id)}
										onChange={(option) => setExpense({ ...expense, 'payment_purpose_id': option.id })}
										placeholder=""
										noOptionsMessage={() => t('list_empty')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>
								</div>
							</>
						}
						<div className="form-group">
							<label>{t('amount')}<span className="required-mark">*</span></label>
							<input type="text" className="form-control" name="amount_out"
								value={expense.amount_out ? formatMoney(expense.amount_out) : ''}
								onChange={(e) => setExpense({ ...expense, 'amount_out': e.target.value.replace(/[^0-9.]/g, '') })} />
						</div>
						<div className="form-group">
							<label>{t('note')}</label>
							<input type="text" className="form-control" name="note"
								value={expense.note ?? ''}
								onChange={(e) => setExpense({ ...expense, 'note': e.target.value })} />
						</div>
						<div className="d-flex w-100 mt-3 gap-2">
							<button type="button" className="btn btn-outline-warning w-100"
								onClick={() => setModalExpense(false)} tabIndex="-1">
								{t('cancel')}
							</button>
							{expense.id ?
								<button type="submit" className="btn btn-primary w-100"
									disabled={!expense.amount_out}>
									{t('save')}
								</button>
								:
								<button type="submit" className="btn btn-primary w-100">
									{t('save')}
								</button>
							}
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL payment */}
		</>
	)
}

export default Index