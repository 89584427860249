import React, { useEffect, useState, useRef, Fragment } from "react"
import { Link, useParams, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Select, { components } from 'react-select'
import MaskedInput from 'react-text-mask'
import { toast } from 'react-toastify'
import { Modal } from 'react-bootstrap'
import { SketchPicker } from "@hello-pangea/color-picker";

import Modifiers from './Modifier'
import ReactSelect from 'pages/parts/ReactSelect'

import { GET, POST, PUT, FILE, DELETE, httpOk } from 'helpers/api'
import { formatDate, formatMoney } from "helpers/helpers"
import { smiles } from "helpers/smiles"
import DeleteModal from "pages/parts/DeleteModal"

function CreateUpdate() {
	const { t } = useTranslation()
	const { posId, id } = useParams()
	const navigate = useNavigate()

	const inputRef = useRef(null);
	const tbodyRef = useRef()
	const scrollRef = useRef()

	const productTypes = [
		{ 'id': 0, 'name': t('element') },
		{ 'id': 1, 'name': t('product') },
		{ 'id': 2, 'name': t('dish_preparation') },
		{ 'id': 3, 'name': t('modifiers') },
		{ 'id': 4, 'name': t('SERVICE2') },
	]
	const writeOffTypes = [
		{ 'id': 1, 'name': t('ingredients') },
		{ 'id': 2, 'name': t('product') },
	]

	const [data, setData] = useState({
		'name': '',
		'menu_id': '',
		'in_product': 1,
		'unit_id': 1,
		'status': 1,
		'write_off_type': 1,
		'description': '',
		'image': '',
		'cooking_time': '',
		'showSmiles': false,
		'sizes': [],
		'ingredients': [],
	})
	const [calculateDirection, setCalculateDirection] = useState('right')
	const [categories, setCategories] = useState([])
	const [categoriesInitial, setCategoriesInitial] = useState([])
	const [wms, setWms] = useState([])
	const [menu, setMenu] = useState([])
	const [sizes, setSizes] = useState([])
	const [units, setUnits] = useState([])
	const [menuTop, setMenuTop] = useState([])
	const [elements, setElements] = useState([])
	const [elementsDuplicate, setElementsDuplicate] = useState([])
	const [ingredientsHistory, setIngredientsHistory] = useState([])
	const [productsGallery, setProductsGallery] = useState([])
	const [activeTab, setActiveTab] = useState(1)
	const [mainActiveTab, setMainActiveTab] = useState(1)
	const [isOpen, setIsOpen] = useState(false)
	const [showModal, setShowModal] = useState(false)
	const [modalIngredientsHistory, setModalIngredientsHistory] = useState(false)
	const [showModifierModal, setShowModifierModal] = useState(false)
	const [showAddModifierModal, setShowAddModifierModal] = useState(false)
	const [showIngredientParamsModal, setShowIngredientParamsModal] = useState({ 'bool': false, 'index': null, 'params': null })
	const [modalDeleteIngredient, setModalDeleteIngredient] = useState(false)
	const [modalDeleteSize, setModalDeleteSize] = useState(false)
	const [deleteModalIngredientParams, setDeleteModalIngredientParams] = useState({ 'id': 0, 'index': 0 })
	const [deleteModalSizeParams, setDeleteModalSizeParams] = useState({ 'id': 0, 'index': 0 })

	async function createUpdate(e, saveContinue = false) {
		e.preventDefault()

		var sendData = { ...data }
		sendData.ingredients = data.ingredients
		sendData.prices = data.prices
		sendData.error = false
		sendData.cooking_time = inputRef?.current?.inputElement?.value
		sendData.pos_id = posId

		if (sendData?.sizes?.length > 0) { // Если есть размеры цены обязательные
			for (let i = 0; i < sendData?.sizes.length; i++) {
				if (sendData?.sizes[i]['price'] === '' || sendData?.sizes[i]['sale_price'] === '') {
					sendData.error = true
				}
			}
		}

		if (sendData?.modifiers?.length > 0) { // Если есть модификаторы
			sendData.bool_modifiers = 1
		}

		if (data.in_product === 2) { // Если это заготовка
			sendData.quantity = 1
		}

		var response;
		if (sendData.id) {
			response = await PUT('/products', sendData, { loader: true })
		} else {
			response = await POST('/products', sendData, { loader: true })
		}

		if (httpOk(response)) {
			if (!saveContinue) {
				navigate('/products')
			} else {
				setData({
					...data,
					'name': '',
					'sale_price': '',
					'in_product': 1,
					'unit_id': 1,
					'status': 1,
					'write_off_type': 1,
					'description': '',
					'image': '',
					'cooking_time': '',
					'showSmiles': false,
					'sizes': [],
					'ingredients': [],
				})
			}
		}
	}

	function addIngredients() {
		if (elements.length === 0) {
			toast.error('Произведите приход')
			return
		}

		var dataCopy = { ...data }
		var ingredient = {
			'element_id': null,
			'name': '',
			'unit_id': '',
			'unit_name': '',
			'second_unit_id': '',
			'price': '',
			'quantity': '',
			'loss': '',
			'in_product': '',
			'units': [],
		}

		if (dataCopy.ingredients) {
			dataCopy.ingredients.push(ingredient)
			setData(dataCopy)
		}
	}

	async function toggleModalIngredientsHistory(bool = false) {
		if (bool) {
			var response = await GET(`/products-ingredients-history/${id}`, {}, { loader: true })
			if (httpOk(response)) {
				setIngredientsHistory(response.data)
			}
		}

		setModalIngredientsHistory(bool)
	}

	function addSizes() {
		var dataCopy = { ...data }

		var size = {
			'name': '',
			'price': '',
			'sale_price': '',
			'bool_size_default': '',
		}

		if (!dataCopy.sizes) {
			dataCopy.sizes = []
		}

		dataCopy.sizes.push(size)
		setData(dataCopy)
	}

	async function addModifierToProduct(productModifiers) {
		var sendData = []
		for (let i = 0; i < productModifiers.modifiers.length; i++) {
			sendData.push({
				'product_id': data.id,
				'product_modifier_id': productModifiers.modifiers[i]['id']
			})
		}

		await POST('/products-modifier', sendData, { loader: true })
		setShowAddModifierModal(false)
		getData()
	}

	function handleSelectInProduct(option) {
		setData({
			...data,
			'in_product': option.id,
			'price': option.id === 2 ? 0 : '',
			'sale_price': option.id === 2 ? 0 : '',
			'menu_id': (option.id === 2 || option.id === 3) ? 0 : '',
		})
	}

	function handleIngridientChange(e, index) {
		var dataCopy = { ...data }

		if (e.target.type === 'number' || e.target.type === 'text') {
			dataCopy.ingredients[index][e.target.name] = e.target.value
		}

		if (e.target.type === 'checkbox') {
			dataCopy.ingredients[index][e.target.name] = e.target.checked
		}

		if (e.target.name === 'quantity') {
			calculateCostPrice(dataCopy)
		} else {
			setData(dataCopy)
		}
	}

	function toggleModalDeleteIngredient(item, index) {
		if (item.id) {
			setDeleteModalIngredientParams({ 'id': item.id, 'index': index })
			setModalDeleteIngredient(true)
		} else {
			var dataCopy = { ...data }
			dataCopy.ingredients.splice(index, 1)
			
			calculateCostPrice(dataCopy)
		}
	}

	function toggleModalDeleteSize(item, index) {
		if (item.id) {
			setDeleteModalSizeParams({ 'id': item.id, 'index': index })
			setModalDeleteSize(true)
		} else {
			var dataCopy = { ...data }
			dataCopy.sizes.splice(index, 1)
			calculateCostPrice(dataCopy)
		}
	}

	async function deleteIngredient() {
		await DELETE(`/products-ingredient/${deleteModalIngredientParams.id}`, {}, { loader: true })
		var dataCopy = { ...data }
		dataCopy.ingredients.splice(deleteModalIngredientParams.index, 1)
		calculateCostPrice(dataCopy)
		setModalDeleteIngredient(false)
	}

	async function deleteSize() {
		await DELETE(`/products-size/${deleteModalSizeParams.id}`, {}, { loader: true })
		var dataCopy = { ...data }
		dataCopy.sizes.splice(deleteModalSizeParams.index, 1)
		calculateCostPrice(dataCopy)
		setModalDeleteSize(false)
	}

	function handlePriceChange(e) {
		var dataCopy = { ...data }
		dataCopy[e.target.name] = e.target.value

		calculateCostPrice(dataCopy)
	}

	function handleSizeChange(e, index) {
		var dataCopy = { ...data }

		if (e.id) {
			dataCopy['sizes'][index]['id'] = e.id
			dataCopy['sizes'][index]['price'] = e.price
			dataCopy['sizes'][index]['sale_price'] = e.sale_price

			setData(dataCopy)
			return
		}

		if (e.target.type === 'text') {
			dataCopy['sizes'][index][e.target.name] = e.target.value
		}

		if (e.target.type === 'number') {
			dataCopy['sizes'][index][e.target.name] = e.target.value
		}

		if (e.target.type === 'radio') {
			for (let i = 0; i < dataCopy['sizes'].length; i++) {
				dataCopy['sizes'][i][e.target.name] = false
			}
			dataCopy['sizes'][index][e.target.name] = e.target.checked
		}

		if (e.type === 'click') {
			for (let i = 0; i < dataCopy['sizes'].length; i++) {
				dataCopy['sizes'][i]['active'] = false
			}
			dataCopy['sizes'][index]['active'] = true
			dataCopy.activeSizeIndex = index
		}

		setData(dataCopy)
	}

	async function fileUpload(e) {
		let formData = new FormData();
		formData.append("id", data.id);
		formData.append("image", e.target.files[0]);
		formData.append("db_name", 'products');
		formData.append("column_name", 'image');
		formData.append("path", 'products');

		var response
		if (!data.id) {
			response = await FILE('/upload-image', formData, {}, { loader: true });
		} else {
			response = await FILE(`/update-image`, formData, {}, { loader: true });
		}
		if (httpOk(response)) setData({ ...data, 'image': response.data.url })
	}

	function calculateCostPrice(dataCopyFromDelete) {
		//debugger
		var dataCopy = { ...data }

		if (dataCopyFromDelete) {
			dataCopy = dataCopyFromDelete
		} else {
			dataCopy = { ...data }
		}

		if (dataCopy.ingredients.length) {
			dataCopy['cost_price'] = 0;
		}

		dataCopy['overall_weight_gross'] = 0
		dataCopy['overall_weight_net'] = 0
		dataCopy['overall_weight_finished'] = 0
		for (let i = 0; i < dataCopy.ingredients.length; i++) {
			if (!dataCopy.ingredients[i]['quantity']) {
				dataCopy.ingredients[i]['quantity'] = ''
			}

			if (dataCopy.ingredients[i]['weight_gross']) {
				dataCopy['overall_weight_gross'] += Number(dataCopy.ingredients[i]['weight_gross'])
			}

			if (dataCopy.ingredients[i]['weight_net']) {
				dataCopy['overall_weight_net'] += Number(dataCopy.ingredients[i]['weight_net'])
			}

			if (dataCopy.ingredients[i]['weight_finished']) {
				dataCopy['overall_weight_finished'] += Number(dataCopy.ingredients[i]['weight_finished'])
			}

			dataCopy['cost_price'] =
				dataCopy['cost_price'] +
				Number(dataCopy.ingredients[i]['average_price']) *
				Number(dataCopy.ingredients[i]['quantity'])

			dataCopy.ingredients[i]['cost_price'] =
				Number(dataCopy.ingredients[i]['average_price']) *
				Number(dataCopy.ingredients[i]['quantity'])
		}

		setData(dataCopy)
	}

	function toggleIngredientParamsModal(bool, index, item = {}) {
		var params
		if (bool) {
			if (!item?.params) {
				params = {
					"hall": false,
					"takeaway": false,
					"delivery": false,
				}
			}

			if (item?.params) params = item.params

			setShowIngredientParamsModal({
				...showIngredientParamsModal,
				'bool': bool,
				'index': index,
				'params': params,
				'note': item.note,
				'changeable': item.changeable,
			})
		} else {
			setShowIngredientParamsModal({ ...showIngredientParamsModal, 'bool': bool, 'index': null, 'params': null })
		}
	}

	function handleIngredientParamsChange(e, type) {
		var showIngredientParamsModalCopy = { ...showIngredientParamsModal }
		if (type === 'checkbox' && (
			e.target.name === "hall" ||
			e.target.name === "takeaway" ||
			e.target.name === "delivery"
		)) {
			showIngredientParamsModalCopy.params[e.target.name] = e.target.checked
		}

		if (type === 'checkbox' && e.target.name === "changeable") {
			showIngredientParamsModalCopy.changeable = e.target.checked
		}

		if (type === 'text') {
			showIngredientParamsModalCopy.note = e.target.value
		}

		setShowIngredientParamsModal(showIngredientParamsModalCopy)
	}

	function saveIngredientParams() {
		var dataCopy = { ...data }
		dataCopy.ingredients[showIngredientParamsModal.index]['params'] = showIngredientParamsModal.params
		dataCopy.ingredients[showIngredientParamsModal.index]['note'] = showIngredientParamsModal.note
		dataCopy.ingredients[showIngredientParamsModal.index]['changeable'] = showIngredientParamsModal.changeable

		setData(dataCopy)
		toggleIngredientParamsModal(false)
	}

	function handleParamsChange(key, value) {
		var dataCopy = { ...data }
		dataCopy.params[key] = value
		setData(dataCopy)
	}

	function handleActiveTab(value) {
		if (value === 2) {
			getSizes()
		}
		setActiveTab(value)
	}

	function toggleModalGallery(bool) {
		if (!productsGallery?.length) {
			getProductsGallery()
		}
		setShowModal(bool)
	}

	async function selectCategory(option) {
		setIsOpen(!isOpen)
		setData({ ...data, 'category_id': option?.id, 'category_name': option?.name, })
	}

	// REACTSELECT LOGIC
	function handleSelectSearch(value) {
		if (!value) {
			setElements([...elementsDuplicate])
			return
		}
		var elementsDuplicateCopy = [...elementsDuplicate]

		var filteredItems = elementsDuplicateCopy.filter(item => item.name.toLowerCase().includes(value.toLowerCase()))
		if (filteredItems[0]) {
			filteredItems[0]['selected'] = true
		}
		setElements(filteredItems)
	}

	function handleSelectElement(option, index) {
		var dataCopy = { ...data }
		var found = !!dataCopy.ingredients.find(item => item.element_id === option.id)
		if (found) {
			dataCopy.ingredients[index]['element_id'] = ''
			toast.error(`Ингридиент уже добавлен`)
			return
		}
		dataCopy.ingredients[index] = {}

		dataCopy.ingredients[index]['quantity'] = ""
		dataCopy.ingredients[index]['loss_cold'] = ""
		dataCopy.ingredients[index]['loss_hot'] = ""
		dataCopy.ingredients[index]['weight_net'] = ""
		dataCopy.ingredients[index]['weight_finished'] = ""
		dataCopy.ingredients[index]['weight_gross'] = ""

		dataCopy.ingredients[index]['units'] = []
		dataCopy.ingredients[index]['element_id'] = option.id
		dataCopy.ingredients[index]['name'] = option.name
		dataCopy.ingredients[index]['average_price'] = option.average_price
		dataCopy.ingredients[index]['pos_id'] = option.pos_id
		dataCopy.ingredients[index]['loss'] = option.loss
		dataCopy.ingredients[index]['in_product'] = option.in_product
		dataCopy.ingredients[index]['product_type_name'] = option.product_type_name
		if (option.second_unit_id) {
			dataCopy.ingredients[index]['second_unit_id'] = option.second_unit_id
			dataCopy.ingredients[index]['second_unit_quantity'] = option.quantity

			// auto select unit
			dataCopy.ingredients[index]['exist_second_unit_id'] = true
			dataCopy.ingredients[index]['unit_id'] = option.unit_id
			dataCopy.ingredients[index]['unit_name'] = option.unit_name
			// auto select unit
			dataCopy.ingredients[index]['units'].push({
				'id': option.second_unit_id,
				'name': option.second_unit_name
			})
		} else {
			// auto select unit
			dataCopy.ingredients[index]['unit_id'] = option.unit_id
			dataCopy.ingredients[index]['exist_second_unit_id'] = false
			dataCopy.ingredients[index]['unit_name'] = option.unit_name
			// auto select unit

			dataCopy.ingredients[index]['units'].push({
				'id': option.unit_id,
				'name': option.unit_name
			})
		}

		setData(dataCopy)
	}

	function closeReactSelect() {
		setTimeout(() => {
			var elementsCopy = [...elements]
			for (let i = 0; i < elementsCopy.length; i++) {
				elementsCopy[i]['selected'] = false
			}
			if (elementsCopy[0] && elementsCopy[0]['selected'])
				elementsCopy[0]['selected'] = true
			setElements(elementsCopy)
		}, 100);
	}

	function handleTableKeyDown(event) {
		event.stopPropagation();
		var currentIndex = elements.findIndex(el => el.selected);
		if (currentIndex >= 0) {
			var elementsCopy = [...elements]
			elementsCopy[currentIndex].selected = false
			switch (event.key) {
				case "ArrowUp":
					if (currentIndex === 0) {
						elementsCopy[elements.length - 1].selected = true
						currentIndex = elements.length - 1
					} else {
						elementsCopy[currentIndex - 1].selected = true
					}
					scrollRef.current.scrollTop = (currentIndex * 29.6) - 59.2
					break;
				case "ArrowDown":
					if (currentIndex === elements.length - 1) {
						elementsCopy[0].selected = true
						currentIndex = 0
					} else {
						elementsCopy[currentIndex + 1].selected = true
					}
					scrollRef.current.scrollTop = (currentIndex * 29.6)
					break;
				default: break;
			}

			setElements(elementsCopy)
		}
	}
	// REACTSELECT LOGIC

	async function getData() {
		var response = await GET(`/products/${posId}/${id}`, {}, { loader: true })
		if (httpOk(response)) {
			response = response.data
			var responseUnits = await GET('/products-units')
			if (httpOk(responseUnits)) {
				setUnits(responseUnits.data)
			}

			for (let i = 0; i < response?.ingredients?.length; i++) {
				if (response.ingredients[i]['second_unit_quantity']) {
					if (response.ingredients[i]['loss'] && !response.ingredients[i]['id']) {
						response.ingredients[i]['quantity'] =
							response.ingredients[i]['second_unit_quantity'] -
							response.ingredients[i]['loss']
					}
				}
			}

			if (response) setData(response)
		}
	}

	async function getProductsGallery() {
		const response = await GET('/menu-gallery', {})
		if (httpOk(response)) setProductsGallery(response.data)
	}

	async function getMenu() {
		var response = await GET('/helper/menu')
		if (httpOk(response)) {
			response.data.unshift({ 'id': 0, 'name': t('hidden_menu') })
			setMenu(response.data)
		}
	}

	async function getMenuTop() {
		var response = await GET('/helper/menu-top')
		if (httpOk(response)) {
			setMenuTop(response.data)
		}
	}

	async function getElements() {
		const response = await GET(`/products-elements/${posId}`)
		if (httpOk(response)) {
			setElements(response.data)
			setElementsDuplicate(response.data)
		}
	}

	async function getSizes() {
		const response = await GET(`/products-sizes/${posId}`)
		if (httpOk(response)) setSizes(response.data)
	}

	async function getUnits() {
		const response = await GET('/products-units')
		if (httpOk(response)) setUnits(response.data)
	}

	async function getPoses() {
		var responsePrices = await GET('/helper/poses')
		if (httpOk(responsePrices)) {
			responsePrices = responsePrices.data
			for (let i = 0; i < responsePrices.length; i++) {
				responsePrices[i]['pos_id'] = responsePrices[i]['id']
				responsePrices[i]['cost_price'] = 0
				responsePrices[i]['price'] = ''
				responsePrices[i]['sale_price'] = ''
			}
		}
	}

	async function getWms() {
		const response = await GET(`/helper/wms?pos_id=${posId}`)
		if (httpOk(response)) {
			setWms(response.data)
			const result = response.data.find(item => item.parent_id === null);
			if (result?.id) setData({ ...data, 'wms_id': result.id })
		}
	}

	async function getCategories() {
		var response = await GET('/helper/categories')
		response = response.data

		var arr = []
		for (let i = 0; i < response.length; i++) {
			response[i]['label'] = ` ${response[i]['name']}`
			response[i].child = false;
			if (response[i].parent_id) {
				const index = response.findIndex(x => x.id === response[i].parent_id)
				response[i].child = true;
				if (response[index]?.options && response[index]?.options?.length > 0) {
					response[index].options.push(response[i])
				} else {
					response[index].options = [response[i]]
				}
			} else {
				arr.push(response[i])
			}
		}

		setCategories(arr)
		setCategoriesInitial(response)
	}

	useEffect(() => {
		if (id) {
			getData()
		} else {
			getUnits()
		}
		getWms()
		getPoses()
		getMenu()
		getMenuTop()
		getCategories()
		getElements()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		calculateCostPrice()
	}, [data.id]) // eslint-disable-line react-hooks/exhaustive-deps

	const GroupHead = props => {
		return (
			<div style={{
				padding: '0',
				color: data.category_id === props.data.id ? '#fff' : '#535277',
				fontWeight: 'bold',
				backgroundColor: data.category_id === props.data.id ? '#2684FF' : 'transparent',
				fontSize: '1rem'
			}}
				onClick={() => selectCategory(props.data)}>
				<components.Option {...props} />
			</div>
		);
	};

	const Option = props => {
		return (
			<div style={{
				padding: props.data.child ? '0 0 0 20px' : '0',
				color: '#535277',
				fontWeight: props.data.parent_id === 0 ? 'bold' : 'normal',
				fontSize: '.9rem'
			}}>
				<components.Option {...props} />
			</div>
		);
	};

	function calculateWeight(index) {
		var dataCopy = { ...data }
		if (dataCopy.ingredients[index]['second_unit_quantity']) {
			if (calculateDirection === 'right') {
				if (dataCopy.ingredients[index]['loss_cold']) {
					dataCopy.ingredients[index]['weight_net'] =
						(
							dataCopy.ingredients[index]['quantity'] * dataCopy.ingredients[index]['second_unit_quantity'] -
							Number(dataCopy.ingredients[index]['quantity'] * dataCopy.ingredients[index]['second_unit_quantity'] / 100 * dataCopy.ingredients[index]['loss_cold'])
						).toFixed(3)
				}

				if (!dataCopy.ingredients[index]['loss_cold']) {
					dataCopy.ingredients[index]['weight_net'] =
						dataCopy.ingredients[index]['quantity'] * dataCopy.ingredients[index]['second_unit_quantity']
				}

				if (dataCopy.ingredients[index]['loss_hot']) {
					dataCopy.ingredients[index]['weight_finished'] =
						(
							dataCopy.ingredients[index]['weight_net'] -
							Number(dataCopy.ingredients[index]['weight_net'] * dataCopy.ingredients[index]['loss_hot'] / 100)
						).toFixed(3)
				}

				if (!dataCopy.ingredients[index]['loss_hot']) {
					dataCopy.ingredients[index]['weight_finished'] = dataCopy.ingredients[index]['weight_net']
				}
			}

			if (calculateDirection === 'left') {
				if (dataCopy.ingredients[index]['loss_cold']) {
					dataCopy.ingredients[index]['quantity'] =
						(
							Number(dataCopy.ingredients[index]['weight_net']) / Number(dataCopy.ingredients[index]['second_unit_quantity']) +
							Number(dataCopy.ingredients[index]['weight_net'] / dataCopy.ingredients[index]['second_unit_quantity'] * dataCopy.ingredients[index]['loss_cold'] / 100)
						).toFixed(3)
				}

				if (!dataCopy.ingredients[index]['loss_cold']) {
					dataCopy.ingredients[index]['quantity'] =
						Number(dataCopy.ingredients[index]['weight_net'] / dataCopy.ingredients[index]['second_unit_quantity'])
				}
			}

			dataCopy.ingredients[index]['weight_gross'] = dataCopy.ingredients[index]['quantity'] * (dataCopy.ingredients[index]['second_unit_quantity'] ?? 1)
		}

		if (!dataCopy.ingredients[index]['second_unit_quantity']) {
			if (calculateDirection === 'right') {
				if (dataCopy.ingredients[index]['loss_cold']) {
					dataCopy.ingredients[index]['weight_net'] =
						dataCopy.ingredients[index]['quantity'] -
						Number(dataCopy.ingredients[index]['quantity'] * dataCopy.ingredients[index]['loss_cold'] / 100)
				}

				if (!dataCopy.ingredients[index]['loss_cold']) {
					dataCopy.ingredients[index]['weight_net'] = dataCopy.ingredients[index]['quantity']
				}

				if (dataCopy.ingredients[index]['loss_hot']) {
					dataCopy.ingredients[index]['weight_finished'] =
						dataCopy.ingredients[index]['weight_net'] -
						Number(dataCopy.ingredients[index]['weight_net'] * dataCopy.ingredients[index]['loss_hot'] / 100)
				}

				if (!dataCopy.ingredients[index]['loss_hot']) {
					dataCopy.ingredients[index]['weight_finished'] = dataCopy.ingredients[index]['weight_net']
				}
			}

			if (calculateDirection === 'left') {
				if (dataCopy.ingredients[index]['loss_cold']) {
					dataCopy.ingredients[index]['quantity'] =
						(
							Number(dataCopy.ingredients[index]['weight_net']) +
							Number(dataCopy.ingredients[index]['weight_net'] * dataCopy.ingredients[index]['loss_cold'] / 100)
						).toFixed(3)
				}

				if (!dataCopy.ingredients[index]['loss_cold']) {
					dataCopy.ingredients[index]['quantity'] = dataCopy.ingredients[index]['weight_net']
				}
			}

			dataCopy.ingredients[index]['weight_gross'] = dataCopy.ingredients[index]['quantity']
		}

		calculateCostPrice(dataCopy)
	}

	return (
		<>
			<div className="page-header d-flex mb-2">
				<div className="d-flex">
					<h4 className="vertical-center">
						{t('product')}
					</h4>
					<div className="vertical-center mt-1">
						<i className="uil uil-angle-double-right fz-20"></i>
					</div>
					<div className="vertical-center mt-1">
						{!id ?
							<h6>{t('create')}</h6>
							:
							<h6>{t('edit')}</h6>
						}
					</div>
					{id &&
						<Fragment>
							<div className="vertical-center mt-1">
								<i className="uil uil-angle-double-right fz-20"></i>
							</div>
							<div className="vertical-center mt-1">
								{data.pos_name}
							</div>
						</Fragment>
					}
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<form autoComplete="off">
						<div className="tabs tabs-sm mb-2">
							<ul>
								<li className={mainActiveTab === 1 ? 'active' : ''}
									onClick={() => setMainActiveTab(1)}>
									<span className="link">{t('main')}</span>
								</li>
								<li className={mainActiveTab === 2 ? 'active ' : ''}
									onClick={() => setMainActiveTab(2)}>
									<span className="link">{t('ingredients')}</span>
								</li>
							</ul>
						</div>
						<div className={`row mb-5 ${mainActiveTab === 1 ? '' : 'd-none'}`}>
							<div className="col-md-5">
								<div className="flex-gap10">
									<div className="w-50">
										<div className="con-upload">
											<div className="con-img-upload">
												{data.image &&
													<div className="img-upload">
														<button type="button" className="btn-x-file" onClick={() => setData({ ...data, 'image': '' })}>
															<i className="uil-times"></i>
														</button>
														<img src={`https://backend.mison.uz/uploads/products/${data.image}`}
															style={{ maxWidth: 'none', maxHeight: '100%' }} alt={data.image} />
														<div className="menu-image-button" onClick={() => toggleModalGallery(!showModal)}>
															<i className="uil uil-image-v"></i>
														</div>
													</div>
												}
												{!data.image &&
													<div className="con-input-upload">
														<input type="file" onChange={(e) => fileUpload(e)} />
														<span className="text-input fz14">
															{t('image2')}
														</span>
														<div className="menu-image-button"
															onClick={() => toggleModalGallery(!showModal)}>
															<i className="uil uil-image-v"></i>
														</div>
													</div>
												}
											</div>
										</div>
									</div>
									<div className="w-50">
										<div className="form-group">
											<label>{t("product_name")}<span className="required-mark">*</span></label>
											<div className="position-relative">
												<input type="text" className="form-control" name="name" autoFocus
													value={data.name ?? ''}
													onChange={(e) => setData({ ...data, 'name': e.target.value })} />
												<div className="input-smile-icon"
													onClick={() => setData({ ...data, 'showSmiles': !data.showSmiles })}>🙂</div>
												{data.showSmiles &&
													<div className="input-smile-wrapper">
														{smiles.map((item, index) => (
															<div key={index} onClick={() => setData({ ...data, 'name': data.name + smiles[index] })}>
																{smiles[index]}
															</div>
														))}
													</div>
												}
											</div>
										</div>
										<div className="form-group">
											<label>{t('type')}<span className="required-mark">*</span></label>
											<Select
												isDisabled={data.id}
												options={productTypes}
												value={productTypes.find(option => option.id === data.in_product)}
												onChange={(option) => handleSelectInProduct(option)}
												placeholder=""
												noOptionsMessage={() => t('list_empty')}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
											/>
										</div>
										<div className="form-group">
											<label>{t("menu")}</label>
											<Select
												options={menu}
												value={menu.find(option => option.id === data.menu_id)}
												onChange={(option) => setData({ ...data, 'menu_id': option.id })}
												placeholder=""
												noOptionsMessage={() => t('list_empty')}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
											/>
										</div>
									</div>
								</div>
								<div className="flex-gap10">
									<div className="form-group w-100">
										<label>{t("cost_price")}</label>
										<input type="text" className="form-control" name="cost_price"
											disabled
											value={formatMoney(data.cost_price) ?? ''}
											onChange={() => { }} />
									</div>
									<div className="form-group w-100">
										<label>{t('sale_price')}<span className="required-mark">*</span></label>
										<input type="number" className="form-control" name="sale_price"
											value={data.sale_price ?? ''}
											onChange={(e) => handlePriceChange(e)} />
									</div>
									<div className="form-group w-100">
										<label>{t('income')}<span className="required-mark">*</span></label>
										<input type="text"
											disabled
											className="form-control"
											value={formatMoney(data.sale_price - data.cost_price)} />
									</div>
								</div>
								<div className="flex-gap10">
									<div className="form-group w-100">
										<label>{t('warehouse')}<span className="required-mark">*</span></label>
										<Select
											options={wms}
											value={wms.find(option => option.id === data.wms_id)}
											onChange={(option) => setData({ ...data, 'wms_id': option.id })}
											placeholder=""
											noOptionsMessage={() => t('list_empty')}
											getOptionLabel={(option) => option.name}
											getOptionValue={(option) => option.id}
										/>
									</div>
									<div className="form-group w-100">
										<label>{t('unit')}<span className="required-mark">*</span></label>
										<Select
											isDisabled={data.id}
											options={units}
											value={units.find(option => option.id === data.unit_id)}
											onChange={(option) => setData({ ...data, 'unit_id': option.id })}
											placeholder=""
											noOptionsMessage={() => t('list_empty')}
											getOptionLabel={(option) => option.name}
											getOptionValue={(option) => option.id}
										/>
									</div>
									<div className="form-group w-100">
										<label>{t("time")}</label>
										<MaskedInput
											mask={[/[0-9]/, /\d/, ':', /\d/, /\d/, ':', /\d/, /\d/,]}
											className="form-control"
											placeholder="00:00:00"
											guide={true}
											ref={inputRef}
											value={data.cooking_time}
										/>
									</div>
								</div>
								<div className="flex-gap10">
									<div className="form-group w-100">
										<label>{t("write_off_type")}<span className="required-mark">*</span></label>
										<Select
											options={writeOffTypes}
											value={writeOffTypes.find(option => option.id === data.write_off_type)}
											onChange={(option) => setData({ ...data, 'write_off_type': option.id })}
											placeholder=""
											noOptionsMessage={() => t('list_empty')}
											getOptionLabel={(option) => option.name}
											getOptionValue={(option) => option.id}
										/>
									</div>
									<div className="form-group w-100">
										<label>{t("menu_top")}</label>
										<Select
											options={menuTop}
											value={menuTop.find(option => option.id === data.menu_top_id)}
											onChange={(option) => setData({ ...data, 'menu_top_id': option.id })}
											placeholder=""
											noOptionsMessage={() => t('list_empty')}
											getOptionLabel={(option) => option.name}
											getOptionValue={(option) => option.id}
										/>
									</div>
									<div className="form-group w-100">
										<label>{t('quantity')}</label>
										<input type="number" className="form-control"
											disabled
											value={data.quantity} />
									</div>
								</div>
								<div className="form-group w-100">
									<label>{t('category')}</label>
									<Select
										components={{ GroupHeading: GroupHead, Option }}
										isClearable
										isSearchable={false}
										onMenuOpen={() => setIsOpen(true)}
										onMenuClose={() => setIsOpen(false)}
										onBlur={() => setIsOpen(false)}
										menuIsOpen={isOpen}
										classNamePrefix='react-select'
										options={categories}
										value={categoriesInitial.find(option => option.id === data.category_id)}
										onChange={(option) => selectCategory(option)}
										placeholder={t('categories')}
										noOptionsMessage={() => t('list_empty')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>
								</div>
								<div className="form-group w-100">
									<label>{t("description")}</label>
									<textarea className="form-control" name="description"
										value={data.description ?? ''}
										onChange={(e) => setData({ ...data, 'description': e.target.value })} />
								</div>
								<hr />
								<div className="d-flex justify-content-between">
									<p className="fw-600 vertical-center">{t('on_sale')}</p>
									<div className="form-check form-switch form-switch-lg">
										<input type="checkbox" className="form-check-input" name="status"
											checked={data?.status}
											onChange={(e) => setData({ ...data, 'status': e.target.checked })}
										/>
									</div>
								</div>
								<div className="d-flex justify-content-between">
									<p className="fw-600 vertical-center">{t('devisible_product')}</p>
									<div className="form-check form-switch form-switch-lg">
										<input type="checkbox" className="form-check-input" name="bool_devisible"
											checked={data?.bool_devisible}
											onChange={(e) => setData({ ...data, 'bool_devisible': e.target.checked })}
										/>
									</div>
								</div>
								<div className="d-flex justify-content-between">
									<p className="fw-600 vertical-center">{t('tg_bot')}</p>
									<div className="form-check form-switch form-switch-lg">
										<input type="checkbox" className="form-check-input" name="bool_telegram_bot"
											checked={data?.bool_telegram_bot}
											onChange={(e) => setData({ ...data, 'bool_telegram_bot': e.target.checked })}
										/>
									</div>
								</div>
							</div>
							<div className="col-md-7">
								<div className="tabs tabs-sm">
									<ul>
										<li className={activeTab === 1 ? 'active ' : ''}
											onClick={() => handleActiveTab(1)}>
											<span className={`link ${(data.in_product === 2 || data.in_product === 3) && 'disabled'}`}>{t('sizes')}</span>
										</li>
										{id &&
											<li className={activeTab === 2 ? 'active' : ''}
												onClick={() => handleActiveTab(2)}>
												<span className={'link ' + (data.in_product === 3 ? 'disabled ' : '')}>{t('modifiers')}</span>
											</li>
										}
										{(data.id && data.in_product === 3) &&
											<li className={activeTab === 3 ? 'active' : ''}
												onClick={() => handleActiveTab(4)}>
												<span className="link">{t('in_products')}</span>
											</li>
										}
										<li className={activeTab === 4 ? 'active ' : ''}
											onClick={() => handleActiveTab(4)}>
											<span className="link">
												{t('params')}
											</span>
										</li>
									</ul>
								</div>
								<div className={activeTab !== 1 ? 'd-none' : ''}>
									<div className="input-borer-only p-3 my-3">
										<div className="d-flex justify-content-between mb-2">
											<h4 className="me-2">{t('sizes')}</h4>
											<div className="table-action-button table-action-success-button"
												onClick={() => addSizes()}>
												<i className="uil-plus"></i>
											</div>
										</div>
										<div className="table-responsive">
											<table className="table table-bordered">
												<thead>
													<tr>
														<th>{t('product')}</th>
														<th className="text-center">{t('price')}</th>
														<th className="text-center">{t('sale_price')}</th>
														<th className="text-center">{t('default')}</th>
														<th></th>
													</tr>
												</thead>
												<tbody>
													{data?.sizes?.map((item, index) => (
														<tr key={index}>
															<td>
																<div style={{ 'width': '200px' }}>
																	<Select
																		inputId={index + 1}
																		isDisabled={item.id}
																		options={sizes}
																		value={sizes.find(option => option.id === item.id) || ''}
																		onChange={(option) => handleSizeChange(option, index)}
																		menuPosition="fixed"
																		placeholder=""
																		noOptionsMessage={() => t('list_empty')}
																		getOptionLabel={(option) => option.name}
																		getOptionValue={(option) => option.id}
																	/>
																</div>
															</td>
															<td>
																<div className="d-flex justify-content-center">
																	<input type="number"
																		className="form-control text-center"
																		style={{ 'width': '100px' }}
																		name="price"
																		disabled
																		value={item.price}
																		onChange={() => { }} />
																</div>
															</td>
															<td>
																<div className="d-flex justify-content-center">
																	<input type="number"
																		className="form-control text-center"
																		style={{ 'width': '100px' }}
																		name="sale_price"
																		disabled
																		value={item.sale_price}
																		onChange={() => { }} />
																</div>
															</td>
															<td className="vertical-middle">
																<div className="d-flex justify-content-center">
																	<input type="radio" name="bool_size_default" className="form-check-input"
																		checked={item?.bool_size_default}
																		onChange={(e) => handleSizeChange(e, index)} />
																</div>
															</td>
															<td className="vertical-middle">
																<div className="d-flex justify-content-center">
																	<div className="table-action-button table-action-danger-button"
																		onClick={() => toggleModalDeleteSize(item, index)}>
																		<i className="uil-times"></i>
																	</div>
																</div>
															</td>
														</tr>
													))}
												</tbody>
											</table>
										</div>
									</div>
								</div>
								<div className={activeTab !== 2 ? 'd-none' : ''}>
									<div className="input-borer-only p-3 my-3">
										<div className="d-flex justify-content-between mb-2">
											<div className="vertical-center">
												<h4 className="me-2">{t("modifiers")}</h4>
											</div>
											<div className="d-flex gap-2">
												<button type="button" className="btn btn-primary btn-rounded btn-wide"
													onClick={() => setShowAddModifierModal(true)}>
													{t('add')}
												</button>
												<button type="button" className="btn btn-primary btn-rounded btn-wide"
													onClick={() => setShowModifierModal(true)}>
													{t('create')}
												</button>
											</div>
										</div>
										{data?.modifiers?.map((item, index) => (
											<div key={index} style={{ 'borderBottom': '1px solid #eee' }}>
												<div className="vertical-center">
													<div className="py-1">
														{index + 1}. {item.name}
													</div>
												</div>
												{item?.products?.map((product, productIndex) => (
													<div className="ms-4 py-1" key={productIndex}>
														- {product.name}
													</div>
												))
												}
											</div>
										))}
									</div>

									<Modifiers
										showModifierModal={showModifierModal}
										setShowModifierModal={setShowModifierModal}
										showAddModifierModal={showAddModifierModal}
										setShowAddModifierModal={setShowAddModifierModal}
										addModifierToProduct={addModifierToProduct}
										activeTab={activeTab}
									></Modifiers>
								</div>
								<div className={activeTab !== 3 ? 'd-none' : ''}>
									<div className="input-borer-only p-3 my-3">
										<div className="d-flex justify-content-between mb-2">
											<h4 className="me-2">{t('used_in_products')}</h4>
										</div>
										<div className="table-responsive">
											<table className="table table-bordered">
												<thead>
													<tr>
														<th>{t("element")}</th>
														<th className="text-center">{t("quantity")}</th>
													</tr>
												</thead>
												<tbody>
													{data?.productInProducts?.length > 0 &&
														<>
															{data?.productInProducts?.map((item, index) => (
																<tr key={index}>
																	<td>
																		{item.product_name}
																	</td>
																	<td className="text-center">
																		{item.quantity} шт
																	</td>
																</tr>
															))
															}
														</>
													}
												</tbody>
											</table>
										</div>
									</div>
								</div>
								<div className={activeTab !== 4 ? 'd-none' : ''}>
									<div className="input-borer-only p-3 my-3 d-none">
										<h4 className="fw-600">{t('tg_bot')}</h4>
										<div className="d-flex justify-content-between">
											<p className="fw-600 vertical-center">{t('text')}</p>
											<div className="form-group">
												<input type="text" className="form-control"
													name="tg_text"
													maxLength={4}
													placeholder={t('text')}
													value={data?.params?.tg_text ?? ''}
													onChange={(e) => handleParamsChange('tg_text', e.target.value)} />
											</div>
										</div>
										<div className="d-flex justify-content-between">
											<p className="fw-600 vertical-center">{t('color')}</p>
											<div className="form-group">
												<div className="position-relative">
													<input type="text" className="form-control"
														placeholder={t('color')}
														value={data?.params?.tg_color ?? ''}
														onChange={(e) => handleParamsChange('tg_color', e.target.value)} />
													<i className="color-picker-icon uil-angle-down"
														onClick={() => setData({ ...data, 'bool_color_picker': !data.bool_color_picker })}>
													</i>
												</div>
												{data.bool_color_picker &&
													<SketchPicker
														style={{ 'zIndex': 1 }}
														className="position-absolute"
														onChangeComplete={(option) => handleParamsChange('tg_color', option.hex)}
													/>
												}
											</div>
										</div>
										<div className="d-flex justify-content-between">
											<p className="fw-600 vertical-center">{t('bg_color')}</p>
											<div className="form-group">
												<div className="position-relative">
													<input type="text" className="form-control"
														placeholder={t('bg_color')}
														value={data?.params?.tg_bg_color ?? ''}
														onChange={(e) => handleParamsChange('tg_bg_color', e.target.value)} />
													<i className="color-picker-icon uil-angle-down"
														onClick={() => setData({ ...data, 'bool_color_picker2': !data.bool_color_picker2 })}>
													</i>
												</div>
												{data.bool_color_picker2 &&
													<SketchPicker
														style={{ 'zIndex': 1 }}
														className="position-absolute"
														onChangeComplete={(option) => handleParamsChange('tg_bg_color', option.hex)}
													/>
												}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className={mainActiveTab === 2 ? '' : 'd-none'}>
							<div className="d-flex justify-content-between mb-2">
								<h4>{t("ingredients")}</h4>
								<div className="d-flex gap-2">
									{id &&
										<div className="table-action-button table-action-primary-button"
											onClick={() => toggleModalIngredientsHistory(true)}>
											<i className="uil-list-ui-alt"></i>
										</div>
									}
									<div className="table-action-button table-action-success-button"
										onClick={() => addIngredients()}>
										<i className="uil-plus"></i>
									</div>
								</div>
							</div>
							<div className="table-responsive">
								<table className="table table-bordered">
									<thead>
										<tr>
											<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
											<th style={{ 'width': '30%' }}>{t("name3")}</th>
											<th style={{ 'width': '10%' }}>{t("type")}</th>
											<th style={{ 'width': '5%' }} className="text-nowrap">{t("unit")}</th>
											<th style={{ 'width': '10%' }} className="text-end">{t("quantity")}</th>
											<th style={{ 'width': '10%' }}>Брутто, {t('kg')}</th>
											<th style={{ 'width': '10%' }}>{t('cold_processing_loss')} %</th>
											<th style={{ 'width': '10%' }}>Нетто, {t('kg')}</th>
											<th style={{ 'width': '10%' }}>{t('hot_processing_loss')} %</th>
											<th style={{ 'width': '10%' }}>{t('finished_product')} {t('kg')}</th>
											<th style={{ 'width': '10%' }} className="text-nowrap text-end">{t("cost_price")}</th>
											<th style={{ 'width': '10%' }} className="text-nowrap text-end">1-{t("cost_price")}</th>
											<th style={{ 'minWidth': '64px' }} className="text-center"></th>
										</tr>
									</thead>
									<tbody>
										{data?.ingredients?.map((item, index) => (
											<tr key={index}>
												<td className="text-center">{index + 1}</td>
												<td>
													{(id && item.id) ?
														<div>{item.name}</div>
														:
														<div>
															<ReactSelect
																search={handleSelectSearch}
																value={item.name}
																index={index}
																scrollRef={scrollRef}
																options={elements}
																onClose={() => closeReactSelect()}
																selectItem={(option) => handleSelectElement(option, index)}
																onKeyUp={(e) => {
																	if (e.keyCode === 40 || e.keyCode === 38) {
																		handleTableKeyDown(e)
																	}
																}}
																table={
																	<table className="table mb-0">
																		<tbody ref={tbodyRef}>
																			{elements.map((selectItem, selectIndex) => (
																				<tr
																					id={selectItem.id}
																					className={`cursor ${((selectItem.selected || selectItem.id === item.element_id) && 'selected')}`}
																					tabIndex={-1}
																					key={selectIndex}
																					onClick={() => handleSelectElement(selectItem, index)}
																					onKeyDown={(e) => handleTableKeyDown(e, selectItem, index)}>
																					<td>{selectItem.name}</td>
																					<td>{selectItem.product_type_name}</td>
																					<td className="text-end text-nowrap">{formatMoney(selectItem.average_price)}</td>
																				</tr>
																			))}
																		</tbody>
																	</table>
																}
															/>
														</div>
													}
												</td>
												<td>{item.product_type_name}</td>
												<td>{item.unit_name}</td>
												<td className="td-input" style={!item.quantity ? { 'borderBottom': '2px solid red' } : {}}>
													<input type="number" className="form-control text-end" name="quantity"
														placeholder="0"
														value={item.quantity}
														onChange={(e) => handleIngridientChange(e, index)}
														onKeyUp={(e) => {
															if (e.keyCode === 13) {
																calculateWeight(index)
															}
														}} />
												</td>
												<td className="text-end">{formatMoney(item.weight_gross, 3)}</td>
												<td className="td-input">
													<input type="number" className="form-control text-end" name="loss_cold"
														placeholder="0"
														value={item.loss_cold}
														onChange={(e) => handleIngridientChange(e, index)}
														onKeyUp={(e) => {
															if (e.keyCode === 13) {
																calculateWeight(index)
															}
														}} />
												</td>
												<td className="td-input">
													<input type="number" className="form-control text-end" name="weight_net"
														placeholder="0"
														value={item.weight_net}
														onChange={(e) => handleIngridientChange(e, index)}
														onKeyUp={(e) => {
															if (e.keyCode === 13) {
																calculateWeight(index)
															}
														}} />
												</td>
												<td className="td-input">
													<input type="number" className="form-control text-end" name="loss_hot"
														placeholder="0"
														value={item.loss_hot}
														onChange={(e) => handleIngridientChange(e, index)}
														onKeyUp={(e) => {
															if (e.keyCode === 13) {
																calculateWeight(index)
															}
														}} />
												</td>
												<td className="td-input">
													<input type="number" className="form-control text-end" name="weight_finished"
														placeholder="0"
														value={item.weight_finished}
														onChange={(e) => handleIngridientChange(e, index)}
														onKeyUp={(e) => {
															if (e.keyCode === 13) {
																calculateWeight(index)
															}
														}} />
												</td>
												<td className="text-end">
													{item.cost_price ? formatMoney(item.cost_price) : 0}
												</td>
												<td className="text-nowrap text-end" >
													{item.average_price ? formatMoney(item.average_price) : 0}
												</td>
												<td className="vertical-middle">
													<div className="d-flex justify-content-center gap-1">
														{data.in_product !== 2 &&
															<div className="table-action-button table-action-primary-button"
																onClick={() => toggleIngredientParamsModal(true, index, item)}>
																<i className="uil-setting"></i>
															</div>
														}
														<div className="table-action-button table-action-danger-button"
															onClick={() => toggleModalDeleteIngredient(item, index)}>
															<i className="uil-times"></i>
														</div>
													</div>
												</td>
											</tr>
										))}
									</tbody>
									<tfoot>
										<tr>
											<td></td>
											<td><b>{t('total')}</b></td>
											<td style={{ 'padding': 0 }}>
												<div className="d-flex w-100">
													<button type="button" className="btn-sm btn-primary w-100"
														onClick={() => {
															if (calculateDirection === 'right') {
																setCalculateDirection('left')
															} else {
																setCalculateDirection('right')
															}
														}}>
														{calculateDirection === 'right' &&
															<i className="uil-arrow-right"></i>
														}
														{calculateDirection === 'left' &&
															<i className="uil-arrow-left"></i>
														}
													</button>
												</div>
											</td>
											<td></td>
											<td></td>
											<td className="text-nowrap text-end"><b>{formatMoney(data.overall_weight_gross, 2)}</b></td>
											<td></td>
											<td className="text-nowrap text-end"><b>{formatMoney(data.overall_weight_net, 2)}</b></td>
											<td></td>
											<td className="text-nowrap text-end"><b>{formatMoney(data.overall_weight_finished, 2)}</b></td>
											<td className="text-nowrap text-end"><b>{formatMoney(data.cost_price, 2)}</b></td>
											<td></td>
											<td></td>
										</tr>
									</tfoot>
								</table>
							</div>
						</div>

						<div className="btn-wrapper gap-2">
							<Link className="btn btn-warning btn-rounded btn-wide" to="/products">
								{t('cancel')}
							</Link>
							{!data.id &&
								<button type="button" className="btn btn-secondary btn-rounded btn-wide"
									onClick={(e) => createUpdate(e, true)}>
									{t('save')}
									<i className="uil uil-arrow-right"></i>
								</button>
							}
							<button type="button" className="btn btn-primary btn-rounded btn-wide"
								onClick={(e) => createUpdate(e)}>
								{t('save')}
							</button>
						</div>
					</form>
				</div>
			</div>

			{/* MODAL GALLERY */}
			<Modal Modal show={showModal} animation={false} centered
				dialogClassName="update-modal-width" backdrop="static" onHide={() => {
					setShowModal(false)
				}
				}>
				<Modal.Header closeButton>
					<Modal.Title>
						{t('gallery')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={(e) => createUpdate(e)} autoComplete="off">
						<div className="position-relative">
							<div className="row">
								{productsGallery.map((item, index) => (
									<div className="col-6" key={index}
										onClick={() => {
											setData({ ...data, 'image': item.image })
											setShowModal(false)
										}}>
										<div className="menu-gallery-item">
											<img src={`https://backend.mison.uz/uploads/products/${item.image}`} alt="" />
										</div>
									</div>
								))}
							</div>
						</div>
					</form>
				</Modal.Body>
			</Modal >
			{/* MODAL GALLERY */}

			{/* MODAL INGREDIENT PARAMS */}
			<Modal show={showIngredientParamsModal.bool} animation={false} centered
				dialogClassName="update-modal-width" backdrop="static" onHide={() => {
					toggleIngredientParamsModal(false)
				}}>
				<Modal.Header closeButton>
					<Modal.Title>
						{t('settings')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<span className="text-danger">{t('write_off_ingredient_only')}</span>
					<div className="d-flex justify-content-between mb-2">
						<p className="fw-600 vertical-center">{t('hall')}</p>
						<div className="form-check form-switch form-switch-lg">
							<input type="checkbox" className="form-check-input" name="hall"
								checked={showIngredientParamsModal?.params?.hall}
								onChange={(e) => handleIngredientParamsChange(e, 'checkbox')} />
						</div>
					</div>
					<div className="d-flex justify-content-between mb-2">
						<p className="fw-600 vertical-center">{t('takeaway')}</p>
						<div className="form-check form-switch form-switch-lg">
							<input type="checkbox" className="form-check-input" name="takeaway"
								checked={showIngredientParamsModal?.params?.takeaway}
								onChange={(e) => handleIngredientParamsChange(e, 'checkbox')} />
						</div>
					</div>
					<div className="d-flex justify-content-between mb-2">
						<p className="fw-600 vertical-center">{t('delivery')}</p>
						<div className="form-check form-switch form-switch-lg">
							<input type="checkbox" className="form-check-input" name="delivery"
								checked={showIngredientParamsModal?.params?.delivery}
								onChange={(e) => handleIngredientParamsChange(e, 'checkbox')} />
						</div>
					</div>
					<hr />
					<div className="d-flex justify-content-between mb-2">
						<p className="fw-600 vertical-center">{t('changeable')}</p>
						<div className="form-check form-switch form-switch-lg">
							<input type="checkbox" className="form-check-input" name="changeable"
								checked={showIngredientParamsModal?.changeable}
								onChange={(e) => handleIngredientParamsChange(e, 'checkbox')} />
						</div>
					</div>
					<input type="text" className="form-control" name="note"
						autoComplete="off"
						placeholder={t('note')}
						value={showIngredientParamsModal?.note ?? ''}
						onChange={(e) => handleIngredientParamsChange(e, 'text')} />
					<div className="d-flex gap-2 w-100 mt-3">
						<button type="button" className="btn btn-outline-warning w-100"
							onClick={() => toggleIngredientParamsModal(false)}>
							{t('cancel')}
						</button>
						<button type="button" className="btn btn-primary w-100"
							onClick={saveIngredientParams}>
							{t('save')}
						</button>
					</div>
				</Modal.Body>
			</Modal>
			{/* MODAL INGREDIENT PARAMS */}

			{/* MODAL INGREDIENT HISTORY */}
			<Modal show={modalIngredientsHistory} animation={false} centered size="lg"
				dialogClassName="update-modal-width" backdrop="static"
				onHide={() => toggleModalIngredientsHistory()}>
				<Modal.Header closeButton>
					<Modal.Title>
						{t('history')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="table-responsive">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'minWidth': '40%' }}>{t('name2')}</th>
									<th style={{ 'minWidth': '20%' }} className="text-center">{t('created')}</th>
									<th style={{ 'minWidth': '20%' }} className="text-center">{t('deleted')}</th>
									<th style={{ 'minWidth': '20%' }} className="text-center">{t('deleted_by')}</th>
								</tr>
							</thead>
							<tbody>
								{ingredientsHistory?.map((item, index) => (
									<tr key={index}>
										<td className="text-center">{index + 1}</td>
										<td>{item.name}</td>
										<td className="text-center">{formatDate(item.created_at, 'dd.MM.yyyy HH:mm')}</td>
										<td className="text-center">{formatDate(item.deleted_at, 'dd.MM.yyyy HH:mm')}</td>
										<td className="text-center">{item.deleted_by}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</Modal.Body>
			</Modal>
			{/* MODAL INGREDIENT HISTORY */}

			<DeleteModal
				modalDelete={modalDeleteIngredient}
				setModalDelete={setModalDeleteIngredient}
				deleteItem={deleteIngredient}>
			</DeleteModal>

			<DeleteModal
				modalDelete={modalDeleteSize}
				setModalDelete={setModalDeleteSize}
				deleteItem={deleteSize}>
			</DeleteModal>
		</>
	)
}

export default CreateUpdate