import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useTranslation } from 'react-i18next'
import { Dropdown, Modal } from 'react-bootstrap'
import Select from 'react-select'
import { toast } from 'react-toastify'

import FilterSearch from 'pages/parts/FilterSearch'
import FilterPagination from 'pages/parts/FilterPagination'

import { GET, POST, httpOk } from 'helpers/api'
import { findKeyFromArrayByValue, formatMoney, regexPhoneNumber, returnSign } from 'helpers/helpers'

function Index() {
	const { t } = useTranslation()

	const [data, setData] = useState([])
	const [poses, setPoses] = useState([])
	const [modalPayment, setModalPayment] = useState(false)
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({ pos_id: null })
	const [pagination, setPagination] = useState({
		url: '/report-clients',
		response: null
	})
	const [selectedItem, setSelectedItem] = useState({})

	async function searchWithFilter() {
		var filterUrl = "/report-clients"
		var urlParams = ""
		checkFilter()

		if (filterData.pos_id)
			urlParams += returnSign(urlParams) + 'pos_id=' + filterData.pos_id
		if (filterData.search)
			urlParams += returnSign(urlParams) + 'search=' + filterData.search
		if (filterData.page)
			urlParams += returnSign(urlParams) + 'page=' + filterData.page

		if (`/report-clients${urlParams}` !== filterUrl)
			setFilterDataExist(true)

		filterUrl = filterUrl + urlParams

		const response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) {
			setData(response.data)
			setPagination({ ...pagination, 'url': filterUrl, 'response': response.data })
		}

		setDropdownIsOpen(false)
	}

	function selectItem(item) {
		var itemCopy = { ...item }
		itemCopy.payment_type_id = 1
		itemCopy.amount_type_id = 1
		itemCopy.amount_in = ''
		itemCopy.amount_out = 0
		setSelectedItem(itemCopy)
	}

	function togglePaymentModal(bool) {
		if (!selectedItem?.client_id) {
			toast.warning(t('no_client_selected'))
			return
		}

		var selectedItemCopy = { ...selectedItem }
		if (!bool) {
			selectedItemCopy.payment_type_id = 1
			selectedItemCopy.amount_type_id = 1
			selectedItemCopy.amount_in = ''
			setSelectedItem(selectedItemCopy)
		} else {
			selectedItemCopy.amount_in = selectedItemCopy.balance
			setSelectedItem(selectedItemCopy)
		}

		setModalPayment(bool)
	}

	async function payment(e) {
		e.preventDefault();
		if (Number(selectedItem.balance) < 0) {
			var response = await POST('/clients-debt-writeoff', selectedItem, { loader: true })

			if (httpOk(response)) {
				togglePaymentModal(false)
				searchWithFilter()
			}
		}
	}

	function resetFilter() {
		setFilterData({
			pos_id: null,
			search: '',
		})
		searchWithFilter()
	}

	function checkFilter() {
		if (
			filterData.pos_id === null &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function paginate(page) {
		setFilterData({ ...filterData, 'page': page })
	}

	async function getPoses() {
		const response = await GET('/helper/poses')
		if (httpOk(response)) setPoses(response.data)
	}

	useEffect(() => {
		getPoses()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		searchWithFilter(false)
	}, [filterData.search, filterData.page]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header">
				<h4 className="vertical-center h-100">
					{t('debtors')} [{pagination?.response?.total}]
				</h4>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex justify-content-between flex-wrap mb-3">
						<div className="d-flex gap-2">
							<FilterSearch
								filterData={filterData}
								setFilterData={setFilterData}>
							</FilterSearch>
							<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
								<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100">
									{t('filter')}
								</Dropdown.Toggle>

								<Dropdown.Menu className="dropdown-filter">
									<div className="row">
										<div className="form-group col-md-6">
											<label>{t('pos')}</label>
											<Select
												options={poses}
												value={poses.find(option => option.id === filterData.pos_id) || ''}
												onChange={(option) => setFilterData({ ...filterData, 'pos_id': option.id })}
												placeholder=""
												noOptionsMessage={() => t('list_empty')}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
											/>
										</div>
									</div>
									<div className="row mt-3">
										<div className="col-md-12">
											<div className="d-flex justify-content-end">
												<button className="btn btn-secondary me-2" onClick={() => setDropdownIsOpen(false)}>{t('cancel')}</button>
												<button className="btn btn-primary" onClick={() => searchWithFilter(false)}>{t('apply')}</button>
											</div>
										</div>
									</div>
								</Dropdown.Menu>
							</Dropdown>
							<div>
								<button className="btn btn-outline-primary btn-wide me-2" onClick={togglePaymentModal}>
									{t('write_off')}
								</button>
							</div>
						</div>
						<div className="d-flex">
							<div className="vertical-center me-2">
								<h4>
									<b>{t('total_amount')}</b>:
								</h4>
							</div>
							<div className="vertical-center">
								<h5 className="text-end">
									<span className={`me-1 ${data.total_amount > 0 ? 'text-success' : 'text-danger'}`}>
										{formatMoney(data.total_amount)}
									</span>
								</h5>
							</div>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('filter')}</strong>
								<br />
								{filterData.pos_id &&
									<span className="me-2">
										<b>{t('pos')}: </b>
										{findKeyFromArrayByValue(poses, 'id', filterData.pos_id, 'name')}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('search')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>{t('reset')}</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-bordered table-hover cursor mb-0">
							<thead>
								<tr>
									<th style={{ 'width': '10px' }}>#</th>
									<th>{t('client')}</th>
									<th>{t("mobile")}</th>
									<th>{t("note")}</th>
									<th className="text-end">{t("debt")}</th>
								</tr>
							</thead>
							<tbody>
								{data?.data?.map((item, index) => (
									<tr className={'tr-middle ' + (
										(
											selectedItem &&
											selectedItem.pos_id === item.pos_id &&
											selectedItem.client_id === item.client_id
										) ? 'table-tr-active' : '')}
										key={index}
										onClick={() => selectItem(item)}>
										<td>	{item.row_number}</td>
										<td>
											<Link className="text-primary cursor table-first-column-clickable"
												to={`/report-clients-debts/${item.client_id}`}>
												{item.first_name}
											</Link>
										</td>
										<td>{regexPhoneNumber(item.phone1)}</td>
										<td>{item.note}</td>
										<td className="text-end">
											<div className={item.balance > 0 ? 'text-primary' : 'text-danger'}>
												<span className="me-1">{formatMoney(item.balance)}</span>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					{pagination.response &&
						<FilterPagination
							pagination={pagination}
							paginate={paginate}>
						</FilterPagination>
					}

					{/* MODAL payment */}
					<Modal show={modalPayment} animation={false} centered dialogClassName="update-modal-width"
						backdrop="static" onHide={() => togglePaymentModal(false)}>
						<Modal.Header closeButton>
							<Modal.Title>{t('write_off_debt')}</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<form onSubmit={payment} autoComplete="off">
								<div className="form-group">
									<label>{t('amount')}<span className="required-mark">*</span></label>
									<input type="text" className="form-control" name="amount_in" autoFocus
										value={selectedItem.amount_in ? formatMoney(selectedItem.amount_in) : ''}
										disabled />
								</div>
								<div className="form-group">
									<label>{t('note')}</label>
									<input type="text" className="form-control" name="note"
										onChange={(e) => setSelectedItem({ ...selectedItem, 'note': e.target.value })} />
								</div>
								<div className="d-flex gap-2 w-100 mt-3">
									<button type="button" className="btn btn-outline-warning w-100"
										onClick={() => togglePaymentModal(false)} tabIndex="-1">{t('cancel')}</button>
									<button type="submit" className="btn btn-primary w-100">
										{t('save')}
									</button>
								</div>
							</form>
						</Modal.Body>
					</Modal>
					{/* MODAL payment */}
				</div>
			</div>
		</>
	)
}

export default Index
