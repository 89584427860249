import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Modal, Dropdown } from 'react-bootstrap'
import Select from 'react-select'
import DatePicker from "react-datepicker"
import XLSX from 'xlsx';

import FilterSearch from 'pages/parts/FilterSearch'
import FilterPagination from 'pages/parts/FilterPagination'

import { GET, POST, httpOk } from 'helpers/api'
import { formatMoney, returnSign, findKeyFromArrayByValue, returnMinDate, formatMoneyInput, formatDate } from 'helpers/helpers'
import CustomTooltip from "pages/parts/CustomTooltip"

function Index() {
	const { t } = useTranslation()

	const reduxAccount = useSelector(state => state.account)

	const date = new Date()
	const accountTypes = [
		{ 'id': 1, 'name': t('income2') },
		{ 'id': 2, 'name': t('expense') },
	]
	const expenseTypes = [
		{ 'id': 'id', 'name': t('safe') },
		{ 'id': 'shift_id', 'name': t('cashbox') },
		{ 'id': 'document_in_id', 'name': t('good_reception') },
		{ 'id': 'expense_id', 'name': t('expense') },
		{ 'id': 'employee_payment_id', 'name': t('salary') },
		{ 'id': 'supplier_payment_id', 'name': t('supplier') },
		{ 'id': 'organization_payment_id', 'name': t('organization') },
	]
	const [poses, setPoses] = useState([])
	const [modalPayment, setModalPayment] = useState(false)
	const [selectedItem, setSelectedItem] = useState({
		'type': 2,
		'created_at': date,
	})
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		start_date: formatDate(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd'),
		end_date: formatDate(new Date(), 'yyyy-MM-dd'),
		pos_id: reduxAccount.pos_id
	})
	const [pagination, setPagination] = useState({
		url: `/pos-safe`,
		response: null
	})
	const [data, setData] = useState([])

	async function searchWithFilter() {
		var filterDataCopy = { ...filterData }
		var filterUrl = `/pos-safe`
		var urlParams = ""
		checkFilter()

		if (filterDataCopy.pos_id)
			urlParams += returnSign(urlParams) + 'pos_id=' + filterDataCopy.pos_id
		if (filterDataCopy.expense_type)
			urlParams += returnSign(urlParams) + 'expense_type=' + filterDataCopy.expense_type
		if (filterDataCopy.start_date)
			urlParams += returnSign(urlParams) + 'start_date=' + filterDataCopy.start_date
		if (filterDataCopy.end_date)
			urlParams += returnSign(urlParams) + 'end_date=' + filterDataCopy.end_date
		if (filterDataCopy.search)
			urlParams += returnSign(urlParams) + 'search=' + filterDataCopy.search
		if (filterDataCopy.page)
			urlParams += returnSign(urlParams) + 'page=' + filterDataCopy.page
		filterDataCopy.urlParams = urlParams

		if (`/pos-safe${urlParams}` !== filterUrl)
			setFilterDataExist(true)

		filterUrl = filterUrl + urlParams

		const response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) {
			setData(response.data)
			setPagination({ ...pagination, 'url': filterUrl, 'response': response.data })
		}

		setFilterData(filterDataCopy)
		setDropdownIsOpen(false)
	}

	async function exportToExcel() {
		const response = await GET('/pos-safe-excel')

		var excelHeaders = [[t('pos'), t('date'), t('name'), t('note'), t('income'), t('expense')]]
		for (let i = 0; i < response.data.length; i++) {
			var item = response.data[i]
			var excelItems = []
			excelItems.push(item['pos_name'])
			excelItems.push(formatDate(item['created_at'], 'dd.MM.yy HH:mm'))
			excelItems.push(item['first_name'])
			if (item['shift_id']) {
				excelItems.push(t('cashbox'))
			} else {
				excelItems.push(item['note'])
			}
			excelItems.push(Number(item['amount_in']))
			excelItems.push(Number(item['amount_out']))
			excelHeaders.push(excelItems)
		}
		excelHeaders.push([`${t('total')}`, '', '', '', Number(response.total_amount_in), Number(response.total_amount_out)])
		excelHeaders.push([`${t('safe')}`, '', '', '', '', Number(response.balance)])

		const ws = XLSX.utils.aoa_to_sheet(excelHeaders);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
		XLSX.writeFile(wb, t('safe') + ".xlsx");
	}

	async function payment(e) {
		e.preventDefault();

		var response = await POST('/pos-safe-payment', selectedItem, { loader: true })

		if (httpOk(response)) {
			togglePaymentModal(false)
			searchWithFilter()
		}
	}

	function togglePaymentModal(bool) {
		var selectedItemCopy = { ...selectedItem }
		if (bool) {
			setSelectedItem(selectedItemCopy)
		} else {
			setSelectedItem({
				'type': 2,
				'created_at': new Date()
			})
		}

		setModalPayment(bool)
	}

	function resetFilter() {
		setFilterData({
			start_date: formatDate(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd'),
			end_date: formatDate(new Date(), 'yyyy-MM-dd'),
			search: '',
		})
		searchWithFilter()
	}

	function checkFilter() {
		if (
			filterData.pos_id === null &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function paginate(page) {
		setFilterData({ ...filterData, 'page': page })
	}

	async function getPoses() {
		const response = await GET('/helper/poses')
		if (httpOk(response)) setPoses(response.data)
	}

	useEffect(() => {
		getPoses()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		searchWithFilter()
	}, [filterData.search, filterData.page]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header">
				<h4 className="vertical-center h-100">
					{t('safe')} [{data.total}]
				</h4>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex justify-content-between flex-wrap mb-3">
						<div className="d-flex gap-2">
							<FilterSearch
								filterData={filterData}
								setFilterData={setFilterData}>
							</FilterSearch>
							<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
								<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100">
									{t('filter')}
								</Dropdown.Toggle>

								<Dropdown.Menu className="dropdown-filter">
									<div className="row">
										<div className="form-group col-md-6">
											<label>{t('pos')}</label>
											<Select
												options={poses}
												value={poses.find(option => option.id === filterData.pos_id) || ''}
												onChange={(option) => setFilterData({ ...filterData, 'pos_id': option.id })}
												placeholder=""
												noOptionsMessage={() => t('list_empty')}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
											/>
										</div>
										<div className="form-group col-md-6">
											<label>{t('expense')}</label>
											<Select
												isClearable
												options={expenseTypes}
												value={expenseTypes.find(option => option.id === filterData.expense_type) || ''}
												onChange={(option) => setFilterData({ ...filterData, 'expense_type': option ? option.id : null })}
												placeholder=""
												noOptionsMessage={() => t('list_empty')}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
											/>
										</div>
									</div>
									<div className="row">
										<div className="form-group col-md-6">
											<label>{t('period')}</label>
											<div className="calendar-input">
												<DatePicker
													className="form-control"
													dateFormat="dd.MM.yyyy"
													selected={filterData.start_date ? new Date(filterData.start_date) : ''}
													onChange={(date) => setFilterData({ ...filterData, 'start_date': formatDate(date, 'yyyy-MM-dd') })} />
												<i className="uil uil-calendar-alt"></i>
											</div>
										</div>
										<div className="form-group col-md-6">
											<label className="invisible">.</label>
											<div className="calendar-input">
												<DatePicker
													className="form-control"
													dateFormat="dd.MM.yyyy"
													selected={filterData.end_date ? new Date(filterData.end_date) : ''}
													onChange={(date) => setFilterData({ ...filterData, 'end_date': formatDate(date, 'yyyy-MM-dd') })} />
												<i className="uil uil-calendar-alt"></i>
											</div>
										</div>
									</div>
									<div className="row mt-3">
										<div className="col-md-12">
											<div className="d-flex justify-content-end">
												<button className="btn btn-secondary me-2" onClick={() => setDropdownIsOpen(false)}>{t('cancel')}</button>
												<button className="btn btn-primary" onClick={() => searchWithFilter()}>{t('apply')}</button>
											</div>
										</div>
									</div>
								</Dropdown.Menu>
							</Dropdown>
							<div>
								<button className="btn btn-outline-primary btn-wide"
									onClick={() => togglePaymentModal(true)}>
									{t('pay')}
								</button>
							</div>
							<div>
								<CustomTooltip text={t('EXCEL')}>
									<button className="btn btn-outline-primary btn-wide-2"
										onClick={exportToExcel}>
										<i className="uil uil-file-download-alt"></i>
									</button>
								</CustomTooltip>
							</div>
						</div>
						<div className="d-flex gap-3">
							<h4><b>{t('amount')}</b>:</h4>
							<h4>
								<span className={`me-1 ${data.balance > 0 ? 'text-success' : 'text-danger'}`}>
									{formatMoney(data.balance)}
								</span>
							</h4>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('filter')}</strong>
								<br />
								{(filterData.start_date || filterData.end_date) &&
									<span className="me-2">
										<b>{t('period')}: </b>
										{filterData.start_date &&
											<span>{formatDate(filterData.start_date)}</span>
										}
										{(filterData.start_date && filterData.end_date) &&
											<span>|</span>
										}
										{filterData.end_date &&
											<span>{formatDate(filterData.end_date)}</span>
										}
									</span>
								}
								{(poses.length && filterData.pos_id) &&
									<span className="me-2">
										<b>{t('pos')}: </b>
										{findKeyFromArrayByValue(poses, 'id', filterData.pos_id, 'name')}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('search')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase"
									onClick={resetFilter}>
									{t('reset')}
								</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-bordered table-hover cursor mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '20%' }}>{t('pos')}</th>
									<th style={{ 'width': '10%' }}>{t('name')}</th>
									<th style={{ 'width': '30%' }}>{t('note')}</th>
									<th style={{ 'width': '10%' }}>{t('expense_type')}</th>
									<th style={{ 'width': '10%' }} className="text-center">{t('date')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('income')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('expense')}</th>
								</tr>
							</thead>
							<tbody>
								{data?.data?.map((item, index) => (
									<tr className="tr-middle" key={index}>
										<td className="text-center">{item.row_number}</td>
										<td>{item.pos_name}</td>
										<td>
											<CustomTooltip text={item.ceated_by}>
												{item.first_name}
											</CustomTooltip>
										</td>
										<td>{item.note}</td>
										<td>{item.type}</td>
										<td className="text-center">
											<CustomTooltip text={item.shift_id && t('created_by_system')}>
												<span className={`${item.shift_id && 'bg-success bg-border text-white'}`}>
													{formatDate(item.created_at, 'dd.MM.yy HH:mm')}
												</span>
											</CustomTooltip>
										</td>
										<td className="text-end text-success">
											{formatMoney(item.amount_in)}
										</td>
										<td className="text-end text-danger">
											{formatMoney(item.amount_out)}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					{pagination.response &&
						<FilterPagination
							pagination={pagination}
							paginate={paginate}>
						</FilterPagination>
					}
				</div>
			</div>

			{/* MODAL payment */}
			<Modal show={modalPayment} animation={false} centered dialogClassName="update-modal-width"
				backdrop="static" onHide={() => togglePaymentModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('working_with_safe')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={payment} autoComplete="off">
						<div className="form-group">
							<label>{t('date')}</label>
							<div className="calendar-input">
								<DatePicker
									className="form-control"
									dateFormat="dd.MM.yyyy"
									selected={selectedItem.created_at ? new Date(selectedItem.created_at) : ''}
									onChange={(date) => setSelectedItem({ ...selectedItem, 'created_at': formatDate(date, 'yyyy-MM-dd') })}
									minDate={returnMinDate(new Date(), reduxAccount.backdate ?? 3)}
									maxDate={new Date()}
								/>
								<i className="uil uil-calendar-alt"></i>
							</div>
						</div>
						<div className="form-group">
							<label>{t('payment_type')}</label>
							<Select
								options={accountTypes}
								value={accountTypes.find(option => option.id === selectedItem.type) || ''}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'type': option.id })}
								placeholder=""
								noOptionsMessage={() => t('list_empty')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						<div className="form-group">
							<label>{t('amount')}<span className="required-mark">*</span></label>
							<input type="text" className="form-control" name="amount" autoFocus
								value={selectedItem.amount ? formatMoneyInput(selectedItem.amount) : ''}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'amount': e.target.value.replace(/[^0-9.]/g, '') })} />
						</div>
						<div className="form-group">
							<label>{t('note')}</label>
							<input type="text" className="form-control" name="note"
								onChange={(e) => setSelectedItem({ ...selectedItem, 'note': e.target.value })} />
						</div>
						<div className="d-flex w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100 me-2"
								onClick={() => togglePaymentModal(false)} tabIndex="-1">{t('cancel')}</button>
							<button type="submit" className="btn btn-primary w-100" disabled={!selectedItem.amount && !selectedItem.type}>
								{t('save')}
							</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL payment */}
		</>
	)
}

export default Index
