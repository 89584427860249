const initialState = {
	'fastMenu': [],
	'menu': [],
	'poses': [],
}

const filterData = function (state = initialState, action) {
	switch (action.type) {
		case "SET_DELIVERY_DATA":
			return {
				...state,
				'fastMenu': action.payload.fastMenu ?? [],
				'menu': action.payload.menu ?? [],
				'poses': action.payload.poses ?? [],
			};
		default:
			return state;
	}
};

export default filterData