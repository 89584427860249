import React, { useEffect, useState, useRef } from "react"
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Modal, Dropdown } from 'react-bootstrap'
import { useReactToPrint } from 'react-to-print';
import DatePicker from "react-datepicker"
import XLSX from 'xlsx'

import FilterPagination from 'pages/parts/FilterPagination'

import { GET, httpOk } from 'helpers/api'
import { formatMoney, formatDate, returnSign } from 'helpers/helpers'
import CustomTooltip from "pages/parts/CustomTooltip";

function Shift() {
	const { t } = useTranslation()
	const { cashbox_id } = useParams()
	const date = new Date()

	const printRef = useRef();

	const [data, setData] = useState([])
	const [showModal, setShowModal] = useState(false)
	const [dataInner, setDataInner] = useState({})
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		start_date: formatDate(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd'),
		end_date: formatDate(new Date(), 'yyyy-MM-dd'),
		pos_id: null,
		cashier_login: null,
		payment_type_id: null,
		search: ''
	})
	const [pagination, setPagination] = useState({
		url: `/cashbox/shift/${cashbox_id}`,
		response: null
	})

	async function searchWithFilter() {
		var filterUrl = `/cashbox/shift/${cashbox_id}`
		var urlParams = ""
		checkFilter()

		if (filterData.start_date)
			urlParams += returnSign(urlParams) + 'start_date=' + filterData.start_date
		if (filterData.end_date)
			urlParams += returnSign(urlParams) + 'end_date=' + filterData.end_date
		if (filterData.page)
			urlParams += returnSign(urlParams) + 'page=' + filterData.page

		if (`/cashbox/shift/${cashbox_id}${urlParams}` !== filterUrl)
			setFilterDataExist(true)

		filterUrl = filterUrl + urlParams

		const response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) {
			setData(response.data)
			setPagination({ ...pagination, 'url': filterUrl, 'response': response.data })
		}

		setDropdownIsOpen(false)
	}

	async function openModal(item) {
		var response = await GET(`/report-x?pos_id=${item.pos_id}&cashbox_id=${item.cashbox_id}&shift_id=${item.id}`, {}, { loader: true })
		if (httpOk(response)) {
			setDataInner({ ...item, ...response.data })
			setShowModal(true)
		}
	}

	const handlePrint = useReactToPrint({
		content: () => printRef.current,
	});

	function exportToExcel(item) {
		var data = [
			{ "A": t('pos'), "B": item.pos_name },
			{ "A": t('cashbox'), "B": item.cashbox_name },
			{ "A": t('shift_number'), "B": item.shift_number },
			{ "A": t('cashier'), "B": item.cashier_name },
			{ "A": t('start_of_shift'), "B": formatDate(item.opened_at, 'dd.MM.yy HH:mm') },
			{ "A": t('end_of_shift'), "B": item.closed_at ? formatDate(item.closed_at, 'dd.MM.yy HH:mm') : '' },
			{ "A": '', "B": '' },
			{ "A": `${t('number_of_checks') + '[' + t('hall') + ']'}`, "B": item.count_hall },
			{ "A": `${t('number_of_checks') + '[' + t('takeaway') + ']'}`, "B": item.count_takeaway },
			{ "A": `${t('number_of_checks') + '[' + t('delivery') + ']'}`, "B": item.count_delivery },
			{ "A": `${t('number_of_checks')}`, "B": item.count_cheque },
			{ "A": '', "B": '' },
		];

		if (!!item.total_no_profit) {
			data.push({ "A": `${t('number_of_checks') + t('no_profit')}`, "B": item.total_no_profit })
		}

		if (!!item.total_no_service_percentage) {
			data.push({ "A": `${t('number_of_checks') + t('no_service_percentage')}`, "B": item.total_no_service_percentage })
		}

		data.push({ "A": `${t('sales')}`, "B": item.sales })
		data.push({ "A": `${t('service')}`, "B": item.service_percentage_amount })
		data.push({ "A": `${t('delivery')}`, "B": item.total_delivery_amount })
		data.push({ "A": `${t('sale_amount')}`, "B": item.total_sales })
		data.push({ "A": `${t('discount')}`, "B": item.total_discount })
		data.push({ "A": `${t('debt')}`, "B": item.total_client_debtor_amount })
		data.push({ "A": `${t('organization_debt')}`, "B": item.total_organization_amount })

		if (!!item.total_agent_amount) {
			data.push({ "A": `${t('agents_bonus')}`, "B": item.total_agent_amount })
		}

		if (!!item.loyalty) {
			data.push({ "A": `${t('loyalty')}`, "B": item.loyalty.amount_out })
		}

		data.push({
			"A": `${t('total')}`, "B": formatMoney(
				Number(item.total_discount) +
				Number(item.total_client_debtor_amount) +
				Number(item.total_organization_amount) +
				Number(item.total_agent_amount)
			)
		})

		if (!!item.total_return_debt) {
			data.push({ "A": `${t('total_checkout_sales')}`, "B": formatMoney(dataInner?.total_payments - dataInner?.total_return_debt) })
		}

		data.push({ "A": `${t('total_checkout')}`, "B": item.total_payments })

		if (!!item.loyalty) {
			data.push({ "A": `${t('loyalty')}`, "B": item?.loyalty?.amount_in })
		}

		for (let i = 0; i < item.payments.length; i++) {
			var dataLeft = ""
			var dataRight = 0
			if (Number(item.amount_in) !== 0) {
				dataLeft = t('income2') + ' ' + item.payments[i]['payment_type_name'] + ' ' + item.payments[i]['payment_purpose_name']
			} else {
				dataLeft = t('out_balance') + ' ' + item.payments[i]['payment_type_name'] + ' ' + item.payments[i]['payment_purpose_name']
			}

			if (Number(item.payments[i]['amount_in']) !== 0) {
				dataRight = item.payments[i]['amount_in']
			} else {
				dataRight = item.payments[i]['amount_out']
			}
			data.push({ "A": dataLeft, "B": dataRight })
		}

		if (!!item.total_cash) {
			data.push({ "A": `${t('total_cash')}`, "B": item.total_cash })
		}

		if (!!item.total_bank) {
			data.push({ "A": `${t('total_bank')}`, "B": item.total_bank })
		}

		if (!!item.total_expense) {
			data.push({ "A": `${t('total_expense')}`, "B": item.total_expense })
		}

		data.push({ "A": `${t('cashbox_balance')}`, "B": item.balance })

		var ws = XLSX.utils.json_to_sheet(data, { skipHeader: true });
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
		XLSX.writeFile(wb, t('shifts') + ".xlsx");
	}

	async function exportToExcelItems() {
		var filterUrl = `/shift-payments-excel?id=${dataInner.id}`

		var excelHeaders = [['ID', t('amount'), t('discount')]]
		const response = await GET(filterUrl)

		if (httpOk(response)) {
			for (let i = 0; i < response.data.data.length; i++) {
				var item = response.data.data[i]
				var excelItems = []
				excelItems.push(item.cheque_id)
				excelItems.push(Number(item.amount_in))
				excelItems.push(Number(item.discount))
				excelHeaders.push(excelItems)
			}
			excelHeaders.push([])
			excelHeaders.push([t('cash'), response.data.cash_amount_in])
			excelHeaders.push([t('terminal'), response.data.terminal_amount_in])

			const ws = XLSX.utils.aoa_to_sheet(excelHeaders);
			const wb = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
			XLSX.writeFile(wb, t('payment') + ".xlsx");
		}
	}

	function resetFilter() {
		setFilterData({
			start_date: formatDate(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd'),
			end_date: formatDate(new Date(), 'yyyy-MM-dd'),
			page: 0,
		})
	}

	async function checkFilter() {
		if (
			filterData.start_date === null &&
			filterData.end_date === null
		) {
			setFilterDataExist(false)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function paginate(page) {
		setFilterData({ ...filterData, 'page': page })
	}

	useEffect(() => {
		searchWithFilter()
	}, [filterData.page]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<h4 className="vertical-center">
					{t('shifts')} [{data.total}]
				</h4>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex justify-content-between mb-3">
						<div className="d-flex gap-2">
							<div>
								<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100">
										{t('filter')}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-filter">
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('period')}</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.start_date ? new Date(filterData.start_date) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'start_date': formatDate(date, 'yyyy-MM-dd') })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
											<div className="form-group col-md-6">
												<label className="invisible">.</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.end_date ? new Date(filterData.end_date) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'end_date': formatDate(date, 'yyyy-MM-dd') })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex justify-content-end">
													<button className="btn btn-secondary me-2" onClick={() => setDropdownIsOpen(false)}>{t('cancel')}</button>
													<button className="btn btn-primary" onClick={() => searchWithFilter(false)}>{t('apply')}</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
							<div>
								<button type="button" className="btn btn-outline-primary btn-wide-2" onClick={() => searchWithFilter(true)}>
									<CustomTooltip text={t('EXCEL')}>
										<i className="uil uil-file-download-alt"></i>
									</CustomTooltip>
								</button>
							</div>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('filter')}</strong>
								<br />
								{(filterData.start_date || filterData.end_date) &&
									<span className="me-2">
										<b>{t('period')}: </b>
										{filterData.start_date &&
											<span>{formatDate(filterData.start_date)}</span>
										}
										{(filterData.start_date && filterData.end_date) &&
											<span>|</span>
										}
										{filterData.end_date &&
											<span>{formatDate(filterData.end_date)}</span>
										}
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>{t('reset')}</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th>ID {t('shift')}</th>
									<th>{t('shift_number')}</th>
									<th>{t('cashier')}</th>
									<th className="text-center">{t('open_date')}</th>
									<th className="text-center">{t('close_date')}</th>
									<th className="text-end">
										<CustomTooltip text={t(`${t('cashbox_balance')} = ${t('cash')} + ${t('terminal')} + ${t('debt_returned')} - ${t('expenses')}`)}>
											{t('cashbox_balance')}
										</CustomTooltip>
									</th>
								</tr>
							</thead>
							<tbody>
								{data?.data?.map((item, index) => (
									<tr key={index} onClick={() => openModal(item)} className="bg-on-hover">
										<td>{item.id}</td>
										<td>{item.shift_number}</td>
										<td>{item.cashier_name}</td>
										<td className="text-center">{formatDate(item.opened_at, 'dd.MM.yy HH:mm')}</td>
										<td className="text-center">{formatDate(item.closed_at, 'dd.MM.yy HH:mm')}</td>
										<td className="text-end">{formatMoney(item.balance)}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					{pagination.response &&
						<FilterPagination
							pagination={pagination}
							paginate={paginate}>
						</FilterPagination>
					}
				</div>
			</div>

			{/* MODAL */}
			<Modal show={showModal} animation={false} centered
				dialogClassName="update-modal-width" backdrop="static"
				onHide={() => setShowModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('additional_info')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="d-flex justify-content-between mb-1">
						<span>{t('pos')}</span>
						<span>{dataInner.pos_name}</span>
					</div>
					<div className="d-flex justify-content-between mb-1">
						<span>{t('cashbox')}</span>
						<span>{dataInner.cashbox_name}</span>
					</div>
					<div className="d-flex justify-content-between mb-1">
						<span>{t('shift')} ID</span>
						<span>{dataInner.id}</span>
					</div>
					<div className="d-flex justify-content-between mb-1">
						<span>{t('shift_number')}</span>
						<span>{dataInner.shift_number}</span>
					</div>
					<div className="d-flex justify-content-between mb-1">
						<span>{t('cashier')}</span>
						<span>{dataInner.cashier_name}</span>
					</div>
					<div className="d-flex justify-content-between mb-1">
						<span>{t('start_of_shift')}</span>
						<span>{formatDate(dataInner.opened_at, 'dd.MM.yy HH:mm')}</span>
					</div>
					<div className="d-flex justify-content-between mb-1">
						<span>{t('end_of_shift')}</span>
						<span>{formatDate(dataInner.closed_at, 'dd.MM.yy HH:mm')}</span>
					</div>
					<hr />
					<div className="d-flex justify-content-between mb-1">
						<span>{t('number_of_checks')} [ {t('hall')} ]</span>
						<span>{dataInner.count_hall}</span>
					</div>
					<div className="d-flex justify-content-between mb-1">
						<span>{t('number_of_checks')} [ {t('takeaway')} ]</span>
						<span>{dataInner.count_takeaway}</span>
					</div>
					<div className="d-flex justify-content-between mb-1">
						<span>{t('number_of_checks')} [ {t('delivery')} ]</span>
						<span>{dataInner.count_delivery}</span>
					</div>
					<div className="d-flex justify-content-between mb-1">
						<b>{t('number_of_checks')}</b>
						<b>{dataInner.count_cheque}</b>
					</div>
					{!!(dataInner.total_no_profit || dataInner.total_no_service_percentage) &&
						<hr />
					}
					{!!dataInner.total_no_profit &&
						<div className="d-flex justify-content-between mb-1">
							<span>{t('number_of_checks')} [ {t('no_profit')} ]</span>
							<span>{dataInner.total_no_profit}</span>
						</div>
					}
					{!!dataInner.total_no_service_percentage &&
						<div className="d-flex justify-content-between mb-1">
							<span>{t('number_of_checks')} [ {t('no_service_percentage')} ]</span>
							<span>{dataInner.total_no_service_percentage}</span>
						</div>
					}
					{(dataInner && dataInner?.payments?.length > 0) &&
						<hr />
					}
					<div className="d-flex justify-content-between fz14">
						<span>{t('sales')}</span>
						<span className="text-success">{formatMoney(dataInner?.sales)}</span>
					</div>
					<div className="d-flex justify-content-between fz14">
						<span>{t('service')}</span>
						<span className="text-success">{formatMoney(dataInner?.service_percentage_amount)}</span>
					</div>
					<div className="d-flex justify-content-between fz14">
						<span>{t('delivery')}</span>
						<span className="text-success">{formatMoney(dataInner?.total_delivery_amount)}</span>
					</div>
					<div className="d-flex justify-content-between fz14">
						<b>{t('sale_amount')}</b>
						<b>{formatMoney(dataInner?.total_sales)}</b>
					</div>
					<div className="ms-3">
						<div className="d-flex justify-content-between">
							<p>{t('discount')}</p>
							<p className="text-danger">{formatMoney(dataInner?.total_discount)}</p>
						</div>
						<div className="d-flex justify-content-between">
							<span>{t('debt')}</span>
							<span className="text-danger">{formatMoney(dataInner.total_client_debtor_amount)}</span>
						</div>
						<div className="d-flex justify-content-between">
							<span>{t('organization_debt')}</span>
							<span className="text-danger">{formatMoney(dataInner.total_organization_amount)}</span>
						</div>
						{!!Number(dataInner.total_agent_amount) > 0 &&
							<div className="d-flex justify-content-between">
								<span>{t('agents_bonus')}</span>
								<span className="text-danger">{formatMoney(dataInner.total_agent_amount)}</span>
							</div>
						}
						{dataInner.loyalty &&
							<div className="d-flex justify-content-between">
								<span>{t('loyalty')}</span>
								<span>{formatMoney(dataInner?.loyalty?.amount_out)}</span>
							</div>
						}
						<div className="d-flex justify-content-between">
							<b>
								<CustomTooltip text={t(`${t('total')} = ${t('discount')} + ${t('debt')} + ${t('shortage')} + ${t('agents_bonus')}`)}>
									{t('total')}
								</CustomTooltip>
							</b>
							<b className="text-danger">
								{formatMoney(
									Number(dataInner.total_discount) +
									Number(dataInner.total_client_debtor_amount) +
									Number(dataInner.total_organization_amount) +
									Number(dataInner.total_agent_amount)
								)}
							</b>
						</div>
					</div>
					{!!dataInner?.total_return_debt &&
						<div className="d-flex justify-content-between fz14">
							<b>
								<CustomTooltip text={t(`${t('total_checkout')} = ${t('sale_amount')} - ${t('discount')} - ${t('agents_bonus')} - ${t('sold_on_credit')}`)}>
									{t('total_checkout_sales')}
								</CustomTooltip>
							</b>
							<b>{formatMoney(dataInner?.total_payments - dataInner?.total_return_debt)}</b>
						</div>
					}
					<div className="d-flex justify-content-between fz14">
						<b>
							<CustomTooltip text={t(`${t('total_checkout')} = ${t('sale_amount')} - ${t('discount')} - ${t('agents_bonus')} - ${t('sold_on_credit')} + ${t('amortization')}`)}>
								{t('total_checkout')}
							</CustomTooltip>
						</b>
						<b>{formatMoney(dataInner?.total_payments)}</b>
					</div>
					<div className="ms-3">
						{dataInner.loyalty &&
							<div className="d-flex justify-content-between">
								<span>{t('loyalty')}</span>
								<span>{formatMoney(dataInner?.loyalty?.amount_in)}</span>
							</div>
						}
						{dataInner?.payments?.map((item, index) => (
							<div className="d-flex justify-content-between" key={index}>
								<p>
									{Number(item.amount_in) !== 0 ?
										<span className="me-2">{t('income2')}</span>
										:
										<span className="me-2">{t('out_balance')}</span>
									}
									<span className="me-2">{item.payment_type_name}</span>
									<span>{item.payment_purpose_name}</span>
								</p>
								{Number(item.amount_in) !== 0 ?
									<p className="text-nowrap text-success">{formatMoney(item.amount_in)}</p>
									:
									<p className="text-nowrap text-danger">{formatMoney(item.amount_out)}</p>
								}
							</div>
						))}
						{!!dataInner.total_cash &&
							<div className="d-flex justify-content-between">
								<b>{t('total_cash')}</b>
								<b className="text-success">
									{formatMoney(dataInner.total_cash)}
								</b>
							</div>
						}
						{!!dataInner.total_bank &&
							<div className="d-flex justify-content-between">
								<b>{t('total_bank')}</b>
								<b className="text-success">
									{formatMoney(dataInner.total_bank)}
								</b>
							</div>
						}
						{!!dataInner.total_expense &&
							<div className="d-flex justify-content-between">
								<b>{t('total_expense')}</b>
								<b className="text-danger">
									{formatMoney(dataInner.total_expense)}
								</b>
							</div>
						}
					</div>
					<hr />
					<b className="d-flex justify-content-between">
						<h5 className="color-00">
							<CustomTooltip text={t(`${t('cashbox_balance')} = ${t('cash')} + ${t('debt_returned')} - ${t('expenses')}`)}>
								{t('cashbox_balance')}
							</CustomTooltip>
						</h5>
						<h5 className="text-no-wrap color-00">
							{formatMoney(dataInner.balance)}
						</h5>
					</b>
					<div className="d-flex justify-content-between">
						<span>{t('action')}</span>
						<div className="d-flex gap-2">
							<div className="table-action-button table-action-primary-button"
								onClick={handlePrint}>
								<i className="uil-print"></i>
							</div>
							<div className="table-action-button table-action-primary-button"
								onClick={() => exportToExcel(dataInner)}>
								<i className="uil-file-alt"></i>
							</div>
							<div className="table-action-button table-action-primary-button"
								onClick={() => exportToExcelItems()}>
								<i className="uil-file-alt"></i>
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>
			{/* MODAL */}

			<div id="print" className="d-none">
				<div className="color-00 p-2" ref={printRef}>
					<div className="d-flex justify-content-center mb-1">
						<div className="d-flex flex-column">
							<div className="d-flex justify-content-center mb-1">
								{/* <img src={logo} alt="" width="130" /> */}
							</div>
							<span className="text-center text-uppercase fz14">X {t('report')}</span>
						</div>
					</div>
					<div className="d-flex justify-content-between mb-1 fz14">
						<span>{t('pos')}</span>
						<span>{dataInner.posName}</span>
					</div>
					<div className="d-flex justify-content-between mb-1 fz14">
						<span>{t('cashbox')}</span>
						<span>{dataInner.cashboxName}</span>
					</div>
					<div className="d-flex justify-content-between mb-1 fz14">
						<span>{t('shift_number')}</span>
						<span>{dataInner.shiftNumber}</span>
					</div>
					<div className="d-flex justify-content-between mb-1 fz14">
						<span>{t('cashier')}</span>
						<span>{dataInner.cashierName}</span>
					</div>
					<div className="d-flex justify-content-between mb-1 fz14">
						<span>{t('start_of_shift')}</span>
						<span>{dataInner.shiftOpenDate ? formatDate(dataInner.shiftOpenDate, 'dd.MM.yy HH:mm') : ''}</span>
					</div>
					{dataInner.shiftCloseDate &&
						<div className="d-flex justify-content-between mb-1 fz14">
							<span>{t('end_of_shift')}</span>
							<span>{dataInner.shiftCloseDate ? formatDate(dataInner.shiftCloseDate, 'dd.MM.yy HH:mm') : ''}</span>
						</div>
					}
					**************************************************************************************
					<div className="d-flex justify-content-between mb-1 fz14">
						<span>{t('number_of_checks')} [ {t('hall')} ]</span>
						<span>{dataInner.count_hall}</span>
					</div>
					<div className="d-flex justify-content-between mb-1 fz14">
						<span>{t('number_of_checks')} [ {t('takeaway')} ]</span>
						<span>{dataInner.count_takeaway}</span>
					</div>
					<div className="d-flex justify-content-between mb-1 fz14">
						<span>{t('number_of_checks')} [ {t('delivery')} ]</span>
						<span>{dataInner.count_delivery}</span>
					</div>
					<div className="d-flex justify-content-between mb-1 fz14">
						<span>{t('number_of_checks')}</span>
						<span>{dataInner.count_cheque}</span>
					</div>
					**************************************************************************************
					<div className="d-flex justify-content-between mb-1 fz14">
						<p>{t('sale_amount')}</p>
						<p>{formatMoney(dataInner?.total_sales)}</p>
					</div>
					<div className="d-flex justify-content-between mb-1 fz14">
						<p>{t('received_cashbox')}</p>
						<p>{formatMoney(dataInner?.total_payments)}</p>
					</div>
					{dataInner?.payments?.map((item, index) => (
						<div className="d-flex justify-content-between px-2" key={index}>
							<p>{item.payment_purpose_name} {item.payment_type_name}</p>
							{Number(item.amount_in) !== 0 ?
								<p>{formatMoney(item.amount_in)}</p>
								:
								<p>{formatMoney(item.amount_out)}</p>
							}
						</div>
					))
					}
					{dataInner.debt > 0 &&
						<div className="d-flex justify-content-between mb-1 fz14">
							<span>{t('sold_on_credit')}</span>
							<span>{formatMoney(dataInner.debt)}</span>
						</div>
					}
					<div className="d-flex justify-content-between mb-1 fz14">
						<p>{t('discount')}</p>
						<p>{formatMoney(dataInner?.total_discount)}</p>
					</div>
					**************************************************************************************
					<div className="d-flex justify-content-between mb-1">
						<h5 className="color-00"><b>{t('cashbox_balance')}</b></h5>
						<div>
							<h5 className="text-no-wrap color-00">
								<b>{formatMoney(dataInner.balance)}</b>
							</h5>
						</div>
					</div>
					<div className="d-flex justify-content-between fz14">
						<span>{t('number_of_printed_x_reports')}</span>
						<span>{dataInner.countRequest}</span>
					</div>
				</div>
			</div>
		</>
	)
}

export default Shift
