import React, { useEffect, useState, useRef } from "react"
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from "react-redux"
import { useTranslation } from 'react-i18next'

import ReactSelect from 'pages/parts/ReactSelect'
import { SET_TRANSFER_DATA } from "store/transferData/action";
import { GET, POST, httpOk } from "helpers/api"
import { formatMoney, formatDate } from 'helpers/helpers'

function Preview() {
	const { t } = useTranslation()
	const { id } = useParams()
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const tbodyRef = useRef()
	const scrollRef = useRef()

	const [products, setProducts] = useState([])
	const [productsDuplicate, setProductsDuplicate] = useState([])
	const [data, setData] = useState({})

	function createTransferDocument() {
		dispatch(SET_TRANSFER_DATA(data))
		navigate("/service/wms-transfer-pos/create")
	}

	function existEmpty() {
		var existEmpty = false

		for (let i = 0; i < data?.items?.length; i++) {
			if (!data.items[i]['product_id']) {
				existEmpty = true
				break;
			}
		}

		return existEmpty
	}

	// REACTSELECT LOGIC
	function handleSelectSearch(value) {
		if (!value) {
			setProducts([...productsDuplicate])
			return
		}
		var productsDuplicateCopy = [...productsDuplicate]

		var filteredItems = productsDuplicateCopy.filter(item => item.name.toLowerCase().includes(value.toLowerCase()))
		if (filteredItems[0]) {
			filteredItems[0]['selected'] = true
		}
		setProducts(filteredItems)
	}

	async function handleSelectItem(option, index, item) {
		var dataCopy = { ...data }
		dataCopy.items[index]['id'] = option.id
		dataCopy.items[index]['name'] = option.name

		const response = await POST(`/service/documents-orders-wtppc`, {
			'id': data.id,
			'element_id': option.id,
			'external_element_id': item.external_element_id,
		}, { loader: true })
		if (httpOk(response)) {
			dataCopy.items[index]['element_id'] = option.id
			setData(dataCopy)
		}
	}

	function closeReactSelect() {
		setTimeout(() => {
			var elementsCopy = [...products]
			for (let i = 0; i < elementsCopy.length; i++) {
				elementsCopy[i]['selected'] = false
			}
			if (elementsCopy[0] && elementsCopy[0]['selected'])
				elementsCopy[0]['selected'] = true
			setProducts(elementsCopy)
		}, 100);
	}

	function handleTableKeyDown(event) {
		event.stopPropagation();
		var currentIndex = products.findIndex(el => el.selected);
		if (currentIndex >= 0) {
			var elementsCopy = [...products]
			elementsCopy[currentIndex].selected = false
			switch (event.key) {
				case "ArrowUp":
					if (currentIndex === 0) {
						elementsCopy[products.length - 1].selected = true
						currentIndex = products.length - 1
					} else {
						elementsCopy[currentIndex - 1].selected = true
					}
					scrollRef.current.scrollTop = (currentIndex * 29.6) - 59.2
					break;
				case "ArrowDown":
					if (currentIndex === products.length - 1) {
						elementsCopy[0].selected = true
						currentIndex = 0
					} else {
						elementsCopy[currentIndex + 1].selected = true
					}
					scrollRef.current.scrollTop = (currentIndex * 29.6)
					break;
				default: break;
			}

			setProducts(elementsCopy)
		}
	}
	// REACTSELECT LOGIC

	async function getData() {
		const response = await GET(`/service/documents-orders/${id}`, {}, { loader: true })
		if (httpOk(response)) setData(response.data)
	}

	async function getProducts() {
		const response = await GET(`/service/documents-orders-products`, {}, { loader: true })
		if (httpOk(response)) {
			setProducts(response.data)
			setProductsDuplicate(response.data)
		}
	}

	useEffect(() => {
		getData()
		getProducts()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<div className="d-flex text-truncate">
					<h4 className="vertical-center">
						{t('orders_another_pos')}
					</h4>
					<div className="vertical-center mt-1">
						<i className="uil uil-angle-double-right fz-20"></i>
					</div>
					<div className="vertical-center mt-1">
						<h6>{t('preview')}</h6>
					</div>
				</div>
			</div>

			<div className="card mb-3">
				<div className="card-body">
					<h4 className="fw-600 mb-2">{t('document_from')} {formatDate(data.created_at)}</h4>
					<div className="flex-column preview-info">
						<div className="d-flex gap-2">
							<div className="fw-600">{t('pos')}:</div>
							<div>{data.pos_name}</div>
						</div>
						<div className="d-flex gap-2">
							<div className="fw-600">{t('external_pos')}:</div>
							<div>{data.external_pos_name}</div>
						</div>
						<div className="d-flex gap-2">
							<div className="fw-600">{t('document')}:</div>
							<div>{data.document_number}</div>
						</div>
						<div className="d-flex gap-2">
							<div className="fw-600">{t('note')}:</div>
							<div>{data.note}</div>
						</div>
					</div>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="table-responsive">
						<table className="table table-bordered">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '30%' }}>{t('name2')}</th>
									<th style={{ 'width': '30%' }}>{t('external_name')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('price')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('quantity')}</th>
									<th style={{ 'width': '10%' }}>{t('unit')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('amount')}</th>
								</tr>
							</thead>
							<tbody>
								{data?.items?.map((item, index) => (
									<tr key={index}>
										<td data-id={item.element_id} className="text-center">
											{index + 1}
										</td>
										<td data-id={item.element_id}>
											{item.element_name}
											{!item.element_name &&
												<ReactSelect
													search={handleSelectSearch}
													value={item.name}
													index={index}
													scrollRef={scrollRef}
													options={products}
													onClose={() => closeReactSelect()}
													selectItem={(option) => handleSelectItem(option, index, item)}
													onKeyUp={(e) => {
														if (e.keyCode === 40 || e.keyCode === 38) {
															handleTableKeyDown(e)
														}
													}}
													table={
														<table className="table mb-0">
															<tbody ref={tbodyRef}>
																{products.map((selectItem, selectIndex) => (
																	<tr
																		id={selectItem.id}
																		className={`cursor ${((selectItem.selected || selectItem.id === item.element_id) && 'selected')}`}
																		tabIndex={-1}
																		key={selectIndex}
																		onClick={() => handleSelectItem(selectItem, index, item)}
																		onKeyDown={(e) => handleTableKeyDown(e, selectItem, index)}>
																		<td>{selectItem.name}</td>
																		<td className="text-end">{selectItem.product_type_name}</td>
																	</tr>
																))}
															</tbody>
														</table>
													}
												/>
											}
										</td>
										<td data-id={item.external_element_id}>
											{item.external_element_name}
										</td>
										<td className="text-end">{formatMoney(item.price)}</td>
										<td className="text-end">{item.quantity}</td>
										<td className="text-start">{item.unit_name}</td>
										<td className="text-end">{formatMoney(item.price * item.quantity)}</td>
									</tr>
								))}
								<tr>
									<td></td>
									<td><b>{t('total')}</b></td>
									<td></td>
									<td></td>
									<td className="text-end text-nowrap"><b>{formatMoney(data?.overall?.overall_quantity)}</b></td>
									<td></td>
									<td className="text-end text-nowrap"><b>{formatMoney(data?.overall?.overall_amount)}</b></td>
								</tr>
							</tbody>
						</table>
					</div>

					<div className="d-flex justify-content-end gap-2 mt-5">
						<button type="button" className="btn btn-warning btn-rounded btn-wide"
							onClick={() => navigate(-1)}>
							{t('cancel')}
						</button>
						<button type="button" className="btn btn-primary btn-rounded btn-wide"
							disabled={existEmpty()}
							onClick={() => createTransferDocument()}>
							{t('transfer')}
						</button>
					</div>
				</div>
			</div>
		</>
	)
}

export default Preview