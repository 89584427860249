import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { Modal } from 'react-bootstrap'

import { GET, httpOk } from 'helpers/api'
import { copyText, formatMoney, returnSign } from 'helpers/helpers'
import CustomTooltip from "pages/parts/CustomTooltip"

function Index({ filterData }) {
	const { t } = useTranslation()

	const [data, setData] = useState([])
	const [selectedItem, setSelectedItem] = useState({})
	const [modalDetail, setModalDetail] = useState(false)

	function openDetailModal(item) {
		setSelectedItem(item)
		setModalDetail(true)
	}

	async function searchWithFilter() {
		var filterUrl = "/report-waiters"
		var urlParams = ""

		if (filterData.pos_id)
			urlParams += returnSign(urlParams) + 'pos_id=' + filterData.pos_id
		if (filterData.start_date)
			urlParams += returnSign(urlParams) + 'start_date=' + filterData.start_date
		if (filterData.end_date)
			urlParams += returnSign(urlParams) + 'end_date=' + filterData.end_date

		filterUrl = filterUrl + urlParams

		const response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) {
			setData(response.data)
		}
	}

	useEffect(() => {
		if (filterData) {
			searchWithFilter()
		}
	}, [filterData]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="card">
				<div className="card-body">
					<h5 className="mb-3">{t('waiter_report')}</h5>

					<div className="table-responsive">
						<table className="table table-bordered table-hover mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '20%' }}>{t('waiter')}</th>
									<th style={{ 'width': '5%' }}>KPI %</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('working_days')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('cheques')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('guests')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('products')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('service')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('sale_amount')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('gross_income')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('bonus')}</th>
								</tr>
							</thead>
							<tbody>
								{data?.data?.map((item, index) => (
									<tr className="cursor" key={index} onClick={() => openDetailModal(item)}>
										<td className="text-center">{index + 1}</td>
										<td onClick={() => copyText(item.login)}>
											<CustomTooltip text={item.login}>
												{item.first_name}
											</CustomTooltip>
										</td>
										<td>{item.waiter_percent_bonus}</td>
										<td className="text-end">{item.working_days}</td>
										<td className="text-end">{item.count_cheque}</td>
										<td className="text-end">{item.people_number}</td>
										<td className="text-end">{formatMoney(item.count_products)}</td>
										<td className="text-end">{formatMoney(item.total_service_amount)}</td>
										<td className="text-end">{formatMoney(item.sales)}</td>
										<td className="text-end">{formatMoney(item.gross)}</td>
										<td className="text-end">{formatMoney(item.bonus)}</td>
									</tr>
								))}
								{data?.data?.length > 0 &&
									<tr>
										<td></td>
										<td><b>{t('total')}</b></td>
										<td className="text-end text-nowrap"><b></b></td>
										<td className="text-end text-nowrap"><b></b></td>
										<td className="text-end text-nowrap"><b>{formatMoney(data.overall.overall_cheques)}</b></td>
										<td className="text-end text-nowrap"><b>{formatMoney(data.overall.overall_people)}</b></td>
										<td className="text-end text-nowrap"><b>{formatMoney(data.overall.overall_products)}</b></td>
										<td className="text-end text-nowrap"><b>{formatMoney(data.overall.overall_service_amount)}</b></td>
										<td className="text-end text-nowrap"><b>{formatMoney(data.overall.overall_sales)}</b></td>
										<td className="text-end text-nowrap"><b>{formatMoney(data.overall.overall_gross)}</b></td>
										<td className="text-end text-nowrap"><b>{formatMoney(data.overall.overall_bonus)}</b></td>
									</tr>
								}
							</tbody>
						</table>
					</div>
				</div>
			</div>

			<Modal show={modalDetail} animation={false} size="lg" centered onHide={() => setModalDetail(false)}>
				<Modal.Body>
					<h3 className="mb-3">{selectedItem.first_name}</h3>
					<div className="table-responsive table-fixed inventory-modal-height">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'minWidth': '40%' }} className="text-nowrap">{t('name2')}</th>
									<th style={{ 'minWidth': '10%' }} className="text-end text-nowrap">{t('price')}</th>
									<th style={{ 'minWidth': '10%' }} className="text-end text-nowrap">{t('sale_price')}</th>
									<th style={{ 'minWidth': '10%' }} className="text-end text-nowrap">{t('quantity')}</th>
									<th style={{ 'minWidth': '10%' }} className="text-end text-nowrap">{t('sale_amount')}</th>
									<th style={{ 'minWidth': '10%' }} className="text-end text-nowrap">{t('income')}</th>
									<th style={{ 'minWidth': '10%' }} className="text-end text-nowrap">{t('bonus')}</th>
								</tr>
							</thead>
							<tbody>
								{selectedItem?.products?.map((item, index) => (
									<tr key={index}>
										<td className="text-center">{index + 1}</td>
										<td>{item.name}</td>
										<td className="text-end text-nowrap">{formatMoney(item.price)}</td>
										<td className="text-end text-nowrap">{formatMoney(item.sale_price)}</td>
										<td className="text-end text-nowrap">{formatMoney(item.total_quantity)}</td>
										<td className="text-end text-nowrap">{formatMoney(item.total_amount)}</td>
										<td className="text-end text-nowrap">{formatMoney(item.total_income)}</td>
										<td className="text-end text-nowrap">{formatMoney(item.total_bonus)}</td>
									</tr>
								))}
								<tr>
									<td></td>
									<td><b>{t('total')}</b></td>
									<td className="text-end text-nowrap"><b>{formatMoney(selectedItem?.overall?.overall_price)}</b></td>
									<td className="text-end text-nowrap"><b>{formatMoney(selectedItem?.overall?.overall_sale_price)}</b></td>
									<td className="text-end text-nowrap"><b>{formatMoney(selectedItem?.overall?.overall_quantity)}</b></td>
									<td className="text-end text-nowrap"><b>{formatMoney(selectedItem?.overall?.overall_sales)}</b></td>
									<td className="text-end text-nowrap"><b>{formatMoney(selectedItem?.overall?.overall_gross)}</b></td>
									<td className="text-end text-nowrap"><b>{formatMoney(selectedItem?.overall?.overall_bonus)}</b></td>
								</tr>
							</tbody>
						</table>
					</div>
				</Modal.Body>
			</Modal>
		</>
	)
}

export default Index