import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { Dropdown, Modal } from 'react-bootstrap'
import Select from 'react-select'

import FilterSearch from 'pages/parts/FilterSearch'
import FilterPagination from 'pages/parts/FilterPagination'

import { formatDate, regexLogin, regexPhoneNumber, returnSign } from 'helpers/helpers'
import { GET, POST, PUT, httpOk } from 'helpers/api'

function Index() {
	const { t } = useTranslation()

	const userRoles = [
		{ 'role': 'ROLE_OWNER', 'name': 'OWNER' },
		{ 'role': 'ROLE_PARTNER', 'name': 'PARTNER' },
	]

	const [selectedItem, setSelectedItem] = useState({
		'login': '',
		'phone': '',
		'password': '',
	})
	const [data, setData] = useState([])
	const [modalUser, setModalUser] = useState(false)
	const [filterData, setFilterData] = useState({})
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [pagination, setPagination] = useState({
		url: '/admin/users',
		response: null
	})

	async function searchWithFilter() {
		var filterDataCopy = { ...filterData }
		var filterUrl = "/admin/users"
		var urlParams = ""
		checkFilter()

		if (filterDataCopy.status)
			urlParams += returnSign(urlParams) + 'status=' + filterDataCopy.status

		if (`/admin/users${urlParams}` !== filterUrl)
			setFilterDataExist(true)

		if (filterDataCopy.page >= 0)
			urlParams += returnSign(urlParams) + 'page=' + filterDataCopy.page

		filterUrl = filterUrl + urlParams
		const response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) {
			setData(response.data)
			setPagination({ ...pagination, 'url': filterUrl, 'response': response.data })
		}

		setDropdownIsOpen(false)
	}

	async function createUpdate(e) {
		e.preventDefault();
		var selectedItemCopy = { ...selectedItem }

		if (selectedItemCopy.phone && selectedItemCopy.phone.length === 9)
			selectedItemCopy.phone = '998' + selectedItemCopy.phone

		var response
		if (!selectedItemCopy.id) {
			response = await POST('/admin/users', selectedItemCopy)
		} else {
			response = await PUT('/admin/users', selectedItemCopy)
		}

		if (httpOk(response)) {
			toggleModal(false)
			searchWithFilter()
		}
	}

	function toggleModal(bool, item = {}) {
		if (bool) {
			item.role = 'ROLE_OWNER'
			if (item.phone) item.phone = item.phone.substring(3, 12)
			setSelectedItem(item)
		} else {
			setSelectedItem({
				'login': '',
				'phone': '',
				'password': '',
				'role': 'ROLE_OWNER',
			})
		}
		setModalUser(bool)
	}

	function resetFilter() {
		setFilterData({
			status: null,
			page: 0,
			random: filterData.random + 1
		})
	}

	function checkFilter() {
		if (
			filterData.status === null &&
			filterData.page === null &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function paginate(page) {
		setFilterData({ ...filterData, 'page': page })
	}

	useEffect(() => {
		searchWithFilter()
	}, [filterData.search, filterData.page]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<h4 className="vertical-center">
					Пользователи [{data.total}]
				</h4>
				<div className="d-flex">
					<button className="btn-rounded btn btn-primary"
						onClick={() => toggleModal(true)}>
						{t('create')}
					</button>
				</div>
			</div>

			<div className="card">
				<div className="card-body">

					<div className="d-flex flex-wrap gap-2 mb-3">
						<div>
							<FilterSearch
								filterData={filterData}
								setFilterData={setFilterData}>
							</FilterSearch>
						</div>
						<div>
							<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
								<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100">
									{t('filter')}
								</Dropdown.Toggle>

								<Dropdown.Menu className="dropdown-filter">
									<div className="row mt-3">
										<div className="col-md-12">
											<div className="d-flex justify-content-end gap-2">
												<button className="btn btn-secondary"
													onClick={() => setDropdownIsOpen(false)}>
													{t('cancel')}
												</button>
												<button className="btn btn-primary"
													onClick={() => searchWithFilter(false)}>
													{t('apply')}
												</button>
											</div>
										</div>
									</div>
								</Dropdown.Menu>
							</Dropdown>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('filter')}</strong>
								<br />
								{filterData.search &&
									<span className="me-2">
										<b>{t('search')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase"
									onClick={resetFilter}>
									{t('reset')}
								</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '15%' }}>{t('login')}</th>
									<th style={{ 'width': '15%' }} className="text-center">{t('phone')}</th>
									<th style={{ 'width': '10%' }} className="text-center">{t('date')}</th>
									<th style={{ 'width': '40%' }}></th>
									<th style={{ 'width': '10%' }} className="text-end">{t('active')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('disconnected')}</th>
									<th style={{ 'minWidth': '33px' }} className="text-end"></th>
								</tr>
							</thead>
							<tbody>
								{data?.data?.map((item, index) => (
									<tr key={index}>
										<td className="text-center">{item.row_number}</td>
										<td className="td-ellipsis">{item.login}</td>
										<td className="text-center">{regexPhoneNumber(item.phone)}</td>
										<td className="text-center">{formatDate(item.created_at)}</td>
										<td></td>
										<td className="text-end">{item.count_pos}</td>
										<td className="text-end">{item.count_pos_off}</td>
										<td className="d-flex justify-content-center">
											<div className="table-action-button table-action-primary-button"
												onClick={() => toggleModal(true, item)}>
												<i className="uil-edit-alt"></i>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					{pagination.response &&
						<FilterPagination
							pagination={pagination}
							paginate={paginate}>
						</FilterPagination>
					}
				</div>
			</div>

			{/* MODAL */}
			<Modal show={modalUser} animation={false} centered
				dialogClassName="update-modal-width" backdrop="static"
				onHide={() => toggleModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						{!selectedItem.id ?
							<span>{t('create')}</span>
							:
							<span>{t('edit')}</span>
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={(e) => createUpdate(e)} autoComplete="off">
						<div className="form-group">
							<label>{t('type')}</label>
							<Select
								options={userRoles}
								value={userRoles.find(option => option.role === selectedItem.role) || ''}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'role': option.role })}
								placeholder=""
								noOptionsMessage={() => t('list_empty')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.role}
							/>
						</div>
						{selectedItem.role === 'ROLE_PARTNER' &&
							<div className="form-group">
								<label>Процент бонуса</label>
								<input type="text" className="form-control" name="partner_percentage"
									disabled={selectedItem.id}
									value={selectedItem.partner_percentage}
									onChange={(e) => setSelectedItem({ ...selectedItem, 'partner_percentage': e.target.value })} />
							</div>
						}
						<div className="form-group">
							<label>Имя</label>
							<input type="text" className="form-control" name="first_name" value={selectedItem.first_name}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'first_name': e.target.value })} />
						</div>
						<div className="form-group">
							<label>Логин</label>
							<input type="text" className="form-control" name="login" value={selectedItem.login}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'login': regexLogin(e.target.value) })} />
						</div>
						<div className="form-group">
							<label>Телефон</label>
							<div className="input-group">
								<span className="input-group-text">998</span>
								<input type="number" className="form-control" name="phone"
									value={selectedItem.phone ?? ''}
									onChange={(e) => setSelectedItem({ ...selectedItem, 'phone': e.target.value })} />
							</div>
						</div>
						<div className="form-group">
							<label>Пароль</label>
							<input type="text" className="form-control" name="password" value={selectedItem.password}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'password': e.target.value })} />
						</div>
						<div className="d-flex w-100 gap-2 mt-3">
							<button type="button" className="btn btn-outline-warning w-100"
								onClick={() => toggleModal(false)}>{t('cancel')}</button>
							<button type="submit" className="btn btn-primary w-100">{t('save')}</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL */}
		</>
	)
}

export default Index