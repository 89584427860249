import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import Echo from 'laravel-echo';
import Pusher from 'pusher-js'; // eslint-disable-line no-unused-vars
import Select from 'react-select'

import { POST, GET, FILE, httpOk } from 'helpers/api'

function Index() {
	const { t } = useTranslation()

	const paths = [
		{ 'id': 1, 'name': 'menu' },
		{ 'id': 2, 'name': 'products' }
	]

	const [selectedItem, setSelectedItem] = useState({ 'fromLogin': '', 'toLogin': '' })
	const [imgData, setImgData] = useState({ 'path': 'menu', 'url': '' })
	const [gallery, setGallery] = useState([])

	async function create(type) {
		if (type === 'elements') {
			await POST('/admin/duplicate-elements', selectedItem, { loader: true })
		}
		if (type === 'all') {
			await POST('/admin/duplicate-all', selectedItem, { loader: true })
		}

		if (type === 'product-wms') {
			await POST('/admin/product-wms', selectedItem, { loader: true })
		}
	}

	async function sendUpdate() {
		await GET('/admin/pos-update-all-version', {}, { loader: true })
	}

	async function checkSocket(type) {
		await GET(`/admin/pos-check-socket?type=${type}`, {}, { loader: true })
	}

	async function connectSocket() {
		var wsHost;
		var authEndpoint;
		var isProd;

		if (process.env.NODE_ENV === 'production') {
			wsHost = process.env.REACT_APP_PROD_WSHOST
			authEndpoint = process.env.REACT_APP_PROD_BROADCAST_AUTH
			isProd = true
		} else {
			wsHost = process.env.REACT_APP_DEV_WSHOST
			authEndpoint = process.env.REACT_APP_DEV_BROADCAST_AUTH
			isProd = false
		}

		console.log(wsHost)
		console.log(authEndpoint)

		var echo
		try {
			echo = new Echo({
				broadcaster: 'pusher',
				key: "mison_key",
				wsHost: wsHost,
				wsPort: 6001,
				wssPort: 6001,
				enabledTransports: ['ws', 'wss'],
				disableStats: true,
				encrypted: isProd ? true : false,
				useTLS: isProd ? true : false,
				forceTLS: isProd ? true : false,
				authEndpoint: authEndpoint,
				auth: {
					headers: {
						'Authorization': `Bearer ${'13|V8hNKsXqib7xXO3TtIuXa0DFmfkh7ooCs5g2DRzl'}`
					}
				},
			});
		} catch (error) {
			console.log(error)
		}

		echo.connector.pusher.connection.bind('connected', () => {
			console.log('connected');
		});

		echo.connector.pusher.connection.bind('disconnected', () => {
			console.log('disconnected');
		});

		echo.private(`user.1`)
			.listen('Connection', (payload) => {
				console.log('payload', payload)
			});

		echo.join(`general`)
			.here((users) => {
				console.log('connected to general channel')
				console.log(users)
			}).joining((user) => {
				console.log(user);
			})
			.leaving((user) => {
				console.log(user);
			})
			.listen('General', (payload) => {
				console.log('general', payload)
			});
	}

	async function getGallery(path) {
		const response = await GET(`/${path}-gallery`, {}, { loader: true })
		if (httpOk(response)) setGallery(response.data)
	}

	async function fileUpload(e) {
		let formData = new FormData();
		formData.append("image", e.target.files[0]);
		formData.append("db_name", 'gallery');
		formData.append("column_name", 'image');
		formData.append("path", imgData.path);

		e.target.value = null
		await FILE('/admin-upload-image', formData, {}, { loader: true });
		getGallery(imgData.path)
	}

	useEffect(() => {
		if (process.env.NODE_ENV === 'production')
			connectSocket()
	}, [])// eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		getGallery(imgData.path)
	}, [imgData.path])// eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header">
				<h4 className="vertical-center">
					{t('duplicate')}
				</h4>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="row">
						<div className="col-md-4">
							<form autoComplete="off">
								<div className="form-group">
									<label>От логина</label>
									<input type="text" className="form-control" name="fromLogin"
										value={selectedItem.name}
										onChange={(e) => setSelectedItem({ ...selectedItem, 'fromLogin': e.target.value })} />
								</div>
								<div className="form-group">
									<label>На логин</label>
									<input type="text" className="form-control" name="toLogin"
										value={selectedItem.name}
										onChange={(e) => setSelectedItem({ ...selectedItem, 'toLogin': e.target.value })} />
								</div>

								<button type="button" className="btn btn-primary w-100 mb-2" onClick={() => create('elements')}>Элементы</button>
								<button type="button" className="btn btn-primary w-100 mb-2" onClick={() => create('all')}>Элементы & Продукты</button>
								<button type="button" className="btn btn-primary w-100 mb-2" onClick={() => create('product-wms')}>Product WMS</button>
							</form>
						</div>

						<div className="col-md-4">
							<button className="btn btn-primary mb-2"
								onClick={sendUpdate}>Отправить обновление</button>
							<br />
							<button className="btn btn-primary mb-2"
								onClick={() => checkSocket('bot')}>Проверка бота</button>
							<br />
							<button className="btn btn-primary mb-2"
								onClick={() => checkSocket('general')}>Проверка глобального канака</button>
						</div>
						<div className="col-md-4">
							<div className="form-group">
								<label>{t('type')}<span className="required-mark">*</span></label>
								<Select
									options={paths}
									value={paths.find(option => option.name === imgData.path)}
									onChange={(option) => setImgData({ ...imgData, 'path': option.name })}
									placeholder=""
									noOptionsMessage={() => t('list_empty')}
									getOptionLabel={(option) => option.name}
									getOptionValue={(option) => option.name}
								/>
							</div>

							<div className="con-upload">
								<div className="con-img-upload">
									{selectedItem.image &&
										<div className="img-upload">
											<button type="button" className="btn-x-file" onClick={() => setSelectedItem({ ...selectedItem, 'image': '' })}>
												<i className="uil-times"></i>
											</button>
											<img src={`http://127.0.0.1:8000/uploads/menu/${selectedItem.image}`}
												style={{ maxWidth: 'none', maxHeight: '100%' }} alt={selectedItem.image} />
										</div>
									}
									{!selectedItem.image &&
										<div className="con-input-upload">
											<input type="file" onChange={(e) => fileUpload(e)} />
											<span className="text-input fz14">
												{t('image2')}
											</span>
										</div>
									}
								</div>
							</div>

							{gallery?.map((item, index) => (
								<img src={`http://127.0.0.1:8000/uploads/${imgData.path}/${item.image}`} key={index}
									width={50}
									style={{ maxWidth: 'none', maxHeight: '100%' }} alt={item.image} />
							))}
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Index