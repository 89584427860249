import React, { useEffect, useState, useRef, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Modal, Dropdown } from 'react-bootstrap'
import DatePicker from "react-datepicker"
import Echo from 'laravel-echo';
import Pusher from 'pusher-js'; // eslint-disable-line no-unused-vars

import DeleteModal from 'pages/parts/DeleteModal'
import { clearTemporaryStorage, formatDate, formatMoney, regexNumeric, regexPhoneNumber, returnSign } from 'helpers/helpers'
import { DELETE, GET, PUT, httpOk } from 'helpers/api'
import { SET_DELIVERY_DATA } from "store/delivery/actions"
// status -20 новый в cloud остался telegram bot
// status -15 новый в cloud остался call center
// status 0 не получен торговой точкой
// status 1 Готовиться
// status 2 Пора готовить
// status 3 Готовиться
// status 4 Приготовлено
// status 5 Подано
// status 8 Курьер выехал
// status 9 Курьер доставил и принял оплату
// status 10 успешно оплачен закрытый чек

function Orders({ handleOrderType }) {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const date = new Date()

	const echoRef = useRef(null)
	const intervalId = useRef(null)

	const reduxAccount = useSelector(state => state.account)
	const reduxDelivery = useSelector(state => state.delivery)

	const [modalDelete, setModalDelete] = useState(false)
	const [deleteModalParams, setDeleteModalParams] = useState({ id: 0, index: 0 })
	const [showModal, setShowModal] = useState(false)
	const [selectedItemIndex, setSelectedItemIndex] = useState([])
	const [allData, setAllData] = useState([])
	const [data, setData] = useState([])
	const [orderTypes, setOrderTypes] = useState({
		"order_type_1": 0,
		"order_type_2": 0,
		"order_type_delivery_1": 0,
		"order_type_delivery_2": 0,
		"order_type_delivery_3": 0,
	})
	const [onlinePoses, setOnlinePoses] = useState({
		'poses': [],
		'count': 0
	})
	const [modalOnlinePoses, setModalOnlinePoses] = useState(false)
	const [statuses, setStatuses] = useState([
		{ name: 'Неподтв', active: true, status: -15, quantity: 0, },
		{ name: 'Получен', active: true, status: 0, quantity: 0, },
		{ name: 'Готовится', active: true, status: 1, quantity: 0, },
		{ name: 'В пути', active: true, status: 8, quantity: 0, },
		{ name: 'Оплачен', active: true, status: 10, quantity: 0, },
		{ name: 'Отмененые', active: true, status: -1, quantity: 0, }
	])
	const [chequeStatuses, setChequeStatuses] = useState([])
	// CLIENT UPDATE LOGIC
	const [client, setClient] = useState({})
	const [modalClient, setModalClient] = useState(false)
	// CLIENT UPDATE LOGIC
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [cheques, setCheques] = useState([]);
	const [modalCheques, setModalCheques] = useState(false)
	const [filterData, setFilterData] = useState({
		start_date: formatDate(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd'),
		end_date: formatDate(new Date(), 'yyyy-MM-dd'),
	})

	function changeActiveStatuses(i) {
		clearIntervalId()
		var copyArr = [...statuses]
		copyArr[i].active = !copyArr[i].active
		var arr = [];
		for (let i = 0; i < allData.length; i++) {
			for (let j = 0; j < statuses.length; j++) {
				if (statuses[j].active) {
					if (statuses[j].status === allData[i].status) {
						arr.push(allData[i])
					}
				}
			}
		}
		setData(arr)
		startInterval(arr)
		setStatuses(copyArr)
	}

	function goTo(page) {
		clearIntervalId()
		if (page === -1) {
			dispatch({ type: 'USER_LOGGED_OUT', payload: null })
			clearTemporaryStorage()
			navigate('/auth/login')
			return
		} else {
			handleOrderType()
		}
	}

	async function getCheques() {
		var response = await GET('/delivery/cheques', {}, { loader: true })
		console.log(response)
		var copyStatuses = [...statuses]
		var orderTypesCopy = { ...orderTypes }

		if (httpOk(response)) {
			response = response.data
			for (let i = 0; i < response.length; i++) {
				if (response[i]['order_type'] === 2) orderTypesCopy.order_type_1 += 1
				if (response[i]['order_type'] === 3) orderTypesCopy.order_type_2 += 1
				if (response[i]['order_type_delivery'] === 1) orderTypesCopy.order_type_delivery_1 += 1
				if (response[i]['order_type_delivery'] === 2) orderTypesCopy.order_type_delivery_2 += 1
				if (response[i]['order_type_delivery'] === 3) orderTypesCopy.order_type_delivery_3 += 1

				response[i].time = '30:00'
				if (response[i].status === -15 || response[i].status === -20) {
					copyStatuses[0]['quantity'] += 1
				}
				if (response[i].status === 0) {
					copyStatuses[1]['quantity'] += 1
				}
				if (response[i].status === 1) {
					copyStatuses[2]['quantity'] += 1
				}
				if (response[i].status === 8) {
					copyStatuses[3]['quantity'] += 1
				}
				if (response[i].status === 9) {
					copyStatuses[4]['quantity'] += 1
				}
				if (response[i].status === -1) {
					copyStatuses[5]['quantity'] += 1
				}
			}

			startInterval(response)
			setData(response)
			setAllData(response)
			setOrderTypes(orderTypesCopy)
		}
	}

	async function getChequeStatuses(item, index) {
		setChequeStatuses(item.statuses)
		setShowModal(true)
		setSelectedItemIndex(index)
	}

	async function deleteItem() {
		await DELETE(`/delivery/cheque/${deleteModalParams.id}`, {}, { loader: true })
		setModalDelete(false)
	}

	async function syncCheque(id) {
		await GET(`/delivery/sync/${id}`, {}, { loader: true })
	}

	function startInterval(response) {
		if (intervalId.current) return

		var intervalState = setInterval(() => {
			var dataCopy = [...response]
			for (let i = 0; i < dataCopy?.length; i++) {
				var item = { ...dataCopy[i] }
				var date1 = new Date(item.cheque_opened_at);
				var date2 = new Date();
				if (item.status === 9) {
					date2 = new Date(item?.statuses?.find(item => item.status === 9)?.created_at)
				}
				var diff = date2.getTime() - date1.getTime();
				var msec = 1800 - (diff / 1000);
				item.timeInSeconds = msec
				item.timeInSecondsPercent = (diff / 10) / 1800

				// console.log('1', ((1 * 100) / 1800));
				// console.log('15', ((900 * 100) / 1800));
				// console.log('14', ((840 * 100) / 1800));

				if (msec < -1800) {
					item.time = '- 30:00'
				} else if (msec < 0) {
					msec = Math.abs(msec)
					var mm = Math.floor(msec / 60);
					var ss = Math.floor(msec - (mm * 60));
					item.time = '-' + mm + ':' + (ss >= 10 ? ss : `0${ss}`)
				} else {
					mm = Math.floor(msec / 60);
					ss = Math.floor(msec - (mm * 60));
					item.time = mm + ':' + (ss >= 10 ? ss : `0${ss}`)
				}
				dataCopy[i] = item;
			}
			setData((data) => dataCopy);
		}, 1000)
		intervalId.current = intervalState
	}

	function clearIntervalId() {
		clearInterval(intervalId.current)
		intervalId.current = null
	}

	function getChequeTime(index) {
		var msec = 0
		if (index === 0 && data.length) {
			var date1 = new Date(data[selectedItemIndex]['cheque_opened_at']);
			var date2 = new Date(chequeStatuses[index]['created_at']);
			var diff = date2.getTime() - date1.getTime();
			msec = diff / 1000;
		} else {
			date1 = new Date(chequeStatuses[index - 1]['created_at']);
			date2 = new Date(chequeStatuses[index]['created_at']);
			diff = date2.getTime() - date1.getTime();
			msec = diff / 1000;
		}
		var mm = Math.floor(msec / 60);
		var ss = Math.floor(msec - (mm * 60));

		return mm + ':' + (ss >= 10 ? ss : `0${ss}`)
	}

	function returnStatus(status) {
		if (status === -15 || status === -20) {
			return 'Неподтв'
		}
		if (status === -1) {
			return 'Удален'
		}
		if (status === 0) {
			return 'Получен'
		}
		if (status === 1) {
			return 'Готовиться'
		}
		if (status === 5) {
			return 'Приготовлено'
		}
		if (status === 8) {
			return 'В пути'
		}
		if (status === 9) {
			return 'Оплачен'
		}
	}

	function returnOnlineStatus(item) {
		var found = onlinePoses.poses.find(x => x.pos_id === item.id)

		if (found) {
			return <span className="bg-primary bg-border"></span>
		} else {
			return <span className="bg-danger bg-border"></span>
		}
	}

	async function connectSocket() {
		var wsHost;
		var authEndpoint;
		var isProd;

		if (process.env.NODE_ENV === 'production') {
			wsHost = process.env.REACT_APP_PROD_WSHOST
			authEndpoint = process.env.REACT_APP_PROD_BROADCAST_AUTH
			isProd = true
		} else {
			wsHost = process.env.REACT_APP_DEV_WSHOST
			authEndpoint = process.env.REACT_APP_DEV_BROADCAST_AUTH
			isProd = false
		}

		if (echoRef.current) return

		try {
			echoRef.current = new Echo({
				broadcaster: 'pusher',
				key: "mison_key",
				wsHost: wsHost,
				wsPort: 6001,
				wssPort: 6001,
				enabledTransports: ['ws', 'wss'],
				disableStats: true,
				encrypted: isProd ? true : false,
				useTLS: isProd ? true : false,
				forceTLS: isProd ? true : false,
				authEndpoint: authEndpoint,
				auth: {
					headers: {
						'Authorization': `Bearer ${reduxAccount.token}`
					}
				},
			});
		} catch (error) {
			console.log(error)
		}

		echoRef.current.connector.pusher.connection.bind('connected', () => {
			console.log('connected private channel');
		});

		echoRef.current.connector.pusher.connection.bind('disconnected', () => {
			console.log('disconnected private channel');
		});

		echoRef.current.private(`call-center.${reduxAccount.parent}`)
			.listen('Cheques', (payload) => {
				console.log('payload: ', payload.data)

				var response = payload.data
				var orderTypesCopy = { ...orderTypes }
				for (let i = 0; i < response.length; i++) {
					if (response[i]['order_type'] === 2) orderTypesCopy.order_type_1 += 1
					if (response[i]['order_type'] === 3) orderTypesCopy.order_type_2 += 1
					if (response[i]['order_type_delivery'] === 1) orderTypesCopy.order_type_delivery_1 += 1
					if (response[i]['order_type_delivery'] === 2) orderTypesCopy.order_type_delivery_2 += 1
					if (response[i]['order_type_delivery'] === 3) orderTypesCopy.order_type_delivery_3 += 1
				}
				setOrderTypes(orderTypesCopy)
				clearIntervalId()
				startInterval(payload.data)
			});

		echoRef.current.join(`presence-call-center-${reduxAccount.parent}`)
			.here((users) => { // (users)
				users = users.filter(item => item.role !== "ROLE_CALL_CENTER")
				console.log(users)
				const arrayUniqueByKey = [...new Map(users.map(item => [item['pos_id'], item])).values()];
				setOnlinePoses({
					'poses': users,
					'count': arrayUniqueByKey.length
				})
			})
			.joining((user) => {
				//console.log('joining', user)
				if (user.role === "ROLE_CALL_CENTER") { return }
				var users = onlinePoses.poses
				users.push(user)
				const arrayUniqueByKey = [...new Map(users.map(item => [item['pos_id'], item])).values()];
				setOnlinePoses({
					'poses': users,
					'count': arrayUniqueByKey.length
				})
			})
			.leaving((user) => {
				//console.log('leaving', user)
				if (user.role === "ROLE_CALL_CENTER") { return }
				var users = onlinePoses.poses
				var newUsers = users.filter(item => item.id !== user.id)
				const arrayUniqueByKey = [...new Map(newUsers.map(item => [item['pos_id'], item])).values()];
				setOnlinePoses({
					'poses': newUsers,
					'count': arrayUniqueByKey.length
				})
			})
	}

	async function getData() {
		if (reduxDelivery?.menu?.length > 0) {
			return
		}
		var menu = []
		var poses = []

		if (!reduxDelivery?.poses?.length) {
			poses = await GET('/helper/poses')
		}
		if (!reduxDelivery?.menu?.length) {
			menu = await GET('/delivery/menu')
		}

		dispatch(SET_DELIVERY_DATA({ 'menu': menu.data, 'poses': poses.data }))
	}

	// CLIENT UPDATE LOGIN
	async function toggleModalClient(bool, client_id) {
		if (bool && client_id) {
			var response = await GET(`/clients/${client_id}`, {}, { loader: true })
			if (httpOk(response)) {
				response = response.data
				if (response.phone1)
					response.phone1 = response.phone1.substring(3, 12)
				if (response.phone2)
					response.phone2 = response.phone2.substring(3, 12)
				setClient(response)
			}
		}

		setModalClient(bool)
	}

	async function updateClient(e) {
		e.preventDefault()
		var clientCopy = { ...client }
		if (clientCopy.phone2)
			clientCopy.phone2 = '998' + regexNumeric(clientCopy.phone2)

		var response = await PUT('/clients', clientCopy, { loader: true })
		if (httpOk(response)) {
			toggleModalClient(false)
		}
	}
	// CLIENT UPDATE LOGIN

	async function toggleModalCheques(bool = false) {
		setModalCheques(bool)

		if (bool) {
			var filterDataCopy = { ...filterData }
			var filterUrl = "/report-operators"
			var urlParams = ""

			if (filterDataCopy.start_date)
				urlParams += returnSign(urlParams) + 'start_date=' + filterDataCopy.start_date
			if (filterDataCopy.end_date)
				urlParams += returnSign(urlParams) + 'end_date=' + filterDataCopy.end_date
			filterUrl = filterUrl + urlParams

			const response = await GET(filterUrl, {}, { loader: true })
			if (httpOk(response)) {
				setCheques(response.data)
				setDropdownIsOpen(false)
			}
		} else {
			setCheques([])
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	useEffect(() => {
		connectSocket()
		getCheques()
		getData()
		return () => {
			clearIntervalId()
			echoRef?.current?.leave(`call-center.${reduxAccount.parent}`)
			echoRef?.current?.leave(`presence-call-center-${reduxAccount.parent}`)
		}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	function checkStatus(item) {
		// console.log(item.timeInSeconds);
		if (item.status === 0 && item.timeInSeconds < 1740) {
			return true;
		}
		if (item.status === 1 && item.timeInSeconds < 840) {
			return true;
		}
		if (item.status === 8 && item.timeInSeconds < 0) {
			return true;
		}
		if (item.timeInSeconds < 0) {
			return true;
		}
		return false;
	}

	return (
		<>
			<div className="delivery-top-menu justify-content-between">
				<div className="d-flex gap-3">
					<div>
						<h5 className="d-flex justify-content-between gap-2">
							<span><i className="uil uil-arrow-up-right"></i>{t('takeaway')}:</span>
							<span>{orderTypes.order_type_1}</span>
						</h5>
						<h5 className="d-flex justify-content-between gap-2">
							<span><i className="uil uil-truck"></i>{t('delivery')}:</span>
							<span>{orderTypes.order_type_2}</span>
						</h5>
					</div>
					<div>
						<h5>
							<i className="uil uil-telegram"></i>
							{t('telegram')}: {orderTypes.order_type_delivery_2}
						</h5>
						<h5>
							<i className="uil uil-headphones"></i>
							{t('call_center')}: {orderTypes.order_type_delivery_3}
						</h5>
						<h5>
							<i className="uil uil-shop"></i>
							{t('pos')}: {orderTypes.order_type_delivery_1}
						</h5>
					</div>
				</div>

				<h2 className="vertical-center cursor">
					<div className="d-flex gap-2" onClick={() => setModalOnlinePoses(true)}>
						<span>{t('online')}</span>
						<span className="text-success">{onlinePoses?.count ?? 0}</span>|
						<span>{reduxDelivery?.poses?.length ?? 0}</span>
					</div>
				</h2>
			</div>

			<div className="delivery-statuses">
				{statuses.map((item, index) => (
					<div className={`delivery-status ${(item.active ? 'active' : '')}`}
						onClick={() => changeActiveStatuses(index)} key={index}>
						{item.name}
						<span className="ms-2">
							{item.quantity}
						</span>
					</div>
				))}
			</div>
			<div className="table-responsive h-table-orders table-fixed">
				<table className="table delivery-table" style={{ marginBottom: '75px' }}>
					<thead>
						<tr>
							<th style={{ 'width': '3%' }} className="text-center">#</th>
							<th style={{ 'width': '7%' }} className="text-center">{t('status')}</th>
							<th style={{ 'width': '7%' }} className="text-center">{t('time')}</th>
							<th style={{ 'width': '20%' }} className="text-center">{t('address')}</th>
							<th style={{ 'width': '10%' }} className="text-center">{t('courier')}</th>
							<th style={{ 'width': '10%' }} className="text-center">{t('client')}</th>
							<th style={{ 'width': '10%' }} className="text-center">{t('pos')}</th>
							<th style={{ 'width': '15%' }} className="text-center">{t('comment')}</th>
							<th style={{ 'width': '15%' }} className="text-center">{t('total')}</th>
						</tr>
					</thead>
					<tbody>
						{data?.map((item, index) => (
							<Fragment key={index}>
								<tr className={'status-' + item.status + (checkStatus(item) ? ' table-bg-red' : ' ')}>
									<td className="text-center bg-4">{index + 1}</td>
									<td className="text-center fz-24 bg-4">
										{item.time}
									</td>
									<td className="text-center bg-4">
										<div className="fz-20">
											<b>{formatDate(item.cheque_opened_at, 'HH:mm')}</b>
										</div>
									</td>
									<td className="text-center delivery-address">
										<div>{item.client_address}</div>
										<div>{item.navigate}</div>
									</td>
									<td className="text-center">
										<div className="d-flex flex-column gap-2">
											{!!item.courier_id &&
												<div>{item.courier_name}</div>
											}
											{!!item.delivery_distance &&
												<div>{t('distance')} {item.delivery_distance}km</div>
											}
										</div>
									</td>
									<td>
										<div className="text-center">
											<div className="d-flex flex-column gap-2">
												<div className="fz-16 custom-truncate text-hover"
													style={{ 'width': '180px' }}
													onClick={() => toggleModalClient(true, item.client_id)}>
													{item.client_name}
												</div>
												<div>{regexPhoneNumber(item.client_phone1)}</div>
												<div>{regexPhoneNumber(item.client_phone2)}</div>
											</div>
										</div>
									</td>
									<td className="text-center">
										<div>{item.pos_name ?? ''}</div>
										<div>{regexPhoneNumber(item.pos_phone)}</div>
										<div>{(item.cashier_first_name ?? '') + ' ' + (item.cashier_last_name ?? '')}</div>
									</td>
									<td className="text-center">{item.note}</td>
									<td>
										<div>
											<div className="fz-20 text-center mb-2">
												<b>{formatMoney(item.total_amount_service)}</b>
											</div>
											<div className="d-flex justify-content-center gap-2">
												{(item.status === -20 || item.status === -15) &&
													<div className="table-action-button table-action-success-button"
														onClick={() => syncCheque(item.id)}>
														<i className="uil-refresh"></i>
													</div>
												}
												{(
													(
														item.status === -20 ||
														item.status === -15 ||
														item.status === 0
													) && item.order_type_delivery === 3) &&
													<div className="table-action-button table-action-danger-button"
														onClick={() => {
															setDeleteModalParams({ id: item.id, index: index });
															setModalDelete(true)
														}}>
														<i className="uil-times"></i>
													</div>
												}
											</div>
										</div>
									</td>
								</tr>
								<tr className="bottom-tr">
									<td className="text-center bg-1">
										{item.order_type_delivery === 1 &&
											<i className="uil uil-shop"></i>
										}
										{item.order_type_delivery === 2 &&
											<i className="uil uil-telegram"></i>
										}
										{item.order_type_delivery === 3 &&
											<i className="uil uil-headphones"></i>
										}
									</td>
									<td className="text-center bg-1">
										ID: {item.id}
									</td>
									<td className="text-center bg-1 cursor"
										onClick={() => getChequeStatuses(item, index)}>
										<b>{returnStatus(item.status)}</b>
									</td>
									<td colSpan={5} className="bg-2">
										<div className="d-flex flex-wrap gap-1 ">
											{item?.products.length &&
												<>
													{item?.products?.map((product, productIndex) => (
														<span key={productIndex}>
															{product.name}(x{product.quantity}),
														</span>
													))}
												</>
											}
										</div>
									</td>
									<td colSpan={1} className={`text-center ${item.status === 9 ? 'bg-success text-white' : 'bg-1'}`}>
										{t('paid')}
									</td>
								</tr>
							</Fragment>
						))}
					</tbody>
				</table>
			</div>
			<div className="delivery-footer">
				<div className="d-flex">
					<div className="delivery-footer-item"
						onClick={() => goTo(-1)}>
						<i className="uil uil-signin"></i>
						<span>{t('logout')}</span>
					</div>

					<div className="delivery-footer-item">
						<Link to="/delivery/cheques-rating" target="_blank"
							style={{ height: '45px' }}>
							<i className="uil uil-star"></i>
						</Link>
						<span>{t('check_evaluation')}</span>
					</div>

					<div className="delivery-footer-item"
						onClick={() => toggleModalCheques(true)}>
						<i className="uil-chart-pie-alt"></i>
						<span>{t('operators')}</span>
					</div>
				</div>

				<div className="d-flex">
					<button className="delivery-footer-item text-nowrap"
						onClick={() => goTo()}>
						<i className="uil uil-create-dashboard"></i>
						<span>{t('new_order')}</span>
					</button>
				</div>
			</div>

			{/* MODAL STATUSES */}
			<Modal show={showModal} animation={false} centered size="md"
				onHide={() => setShowModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('status')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="table-responsive">
						<table className="table">
							<thead>
								<tr>
									<th>{t('time')}</th>
									<th>{t('time')}</th>
									<th>{t('status')}</th>
									<th>{t('role')}</th>
									<th>{t('name')}</th>
								</tr>
							</thead>
							<tbody>
								{chequeStatuses.map((item, index) => (
									<tr key={index}>
										<td>{formatDate(item.created_at, 'HH:mm')}</td>
										<td>{getChequeTime(index)}</td>
										<td>{returnStatus(item.status)}</td>
										<td>{item.role_name}</td>
										<td>{item.first_name}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</Modal.Body>
			</Modal>
			{/* MODAL STATUSES */}

			{/* MODAL POSES */}
			<Modal show={modalOnlinePoses} animation={false} centered size="md"
				onHide={() => setModalOnlinePoses(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('poses')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="table-responsive">
						<table className="table">
							<thead>
								<tr>
									<th>{t('pos')}</th>
									<th className="text-center">{t('status')}</th>
								</tr>
							</thead>
							<tbody>
								{reduxDelivery?.poses?.map((item, index) => (
									<tr key={index}>
										<td>{item.name}</td>
										<td className="text-center">{returnOnlineStatus(item)}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</Modal.Body>
			</Modal>
			{/* MODAL POSES */}

			{/* MODAL UPDATE CLIENT */}
			<Modal show={modalClient} animation={false} centered dialogClassName="update-modal-width"
				backdrop="static" onHide={() => toggleModalClient(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('edit')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={(e) => updateClient(e)} autoComplete="off">
						<div className="form-group">
							<label>{t('phone')}</label>
							<div className="input-group">
								<span className="input-group-text">998</span>
								<input type="number" className="form-control" name="phone1"
									disabled
									value={client.phone1 ?? ''}
									onChange={() => { }} />
							</div>
						</div>
						<div className="form-group">
							<label>{t('name')}<span className="required-mark">*</span></label>
							<input type="text" className="form-control" name="first_name"
								value={client.first_name ?? ''}
								onChange={(e) => setClient({ ...client, 'first_name': e.target.value })} />
						</div>
						<div className="form-group">
							<label>{t('additional_phone')}</label>
							<div className="input-group">
								<span className="input-group-text">998</span>
								<input type="number" className="form-control" name="phone2"
									value={client.phone2 ?? ''}
									onChange={(e) => setClient({ ...client, 'phone2': e.target.value })} />
							</div>
						</div>
						<div className="form-group">
							<label>{t('note')}</label>
							<input type="text" className="form-control" name="note"
								value={client.note ?? ''}
								onChange={(e) => setClient({ ...client, 'note': e.target.value })} />
						</div>
						<div className="form-group">
							<label>{t('navigate')}</label>
							<input type="text" className="form-control" name="navigate"
								value={client.navigate ?? ''}
								onChange={(e) => setClient({ ...client, 'navigate': e.target.value })} />
						</div>
						<div className="d-flex w-100 mt-3 gap-2">
							<button type="button" className="btn btn-outline-warning w-100"
								onClick={() => toggleModalClient(false)}>
								{t('cancel')}
							</button>
							<button type="submit" className="btn btn-primary w-100">{t('save')}</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL UPDATE CLIENT */}

			{/* MODAL CHEQUES */}
			<Modal show={modalCheques} animation={false} centered size="xl"
				dialogClassName="update-modal-width"
				onHide={() => toggleModalCheques(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('operators')} [{cheques?.data?.length}]</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="mb-2">
						<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
							<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100">
								{t('filter')}
							</Dropdown.Toggle>

							<Dropdown.Menu className="dropdown-filter">
								<div className="row">
									<div className="form-group col-md-6">
										<label>{t('period')}</label>
										<div className="calendar-input">
											<DatePicker
												className="form-control"
												dateFormat="dd.MM.yyyy"
												selected={filterData.start_date ? new Date(filterData.start_date) : ''}
												onChange={(date) => setFilterData({ ...filterData, 'start_date': formatDate(date, 'yyyy-MM-dd') })} />
											<i className="uil uil-calendar-alt"></i>
										</div>
									</div>
									<div className="form-group col-md-6">
										<label className="invisible">.</label>
										<div className="calendar-input">
											<DatePicker
												className="form-control"
												dateFormat="dd.MM.yyyy"
												selected={filterData.end_date ? new Date(filterData.end_date) : ''}
												onChange={(date) => setFilterData({ ...filterData, 'end_date': formatDate(date, 'yyyy-MM-dd') })} />
											<i className="uil uil-calendar-alt"></i>
										</div>
									</div>
								</div>
								<div className="row mt-3">
									<div className="col-md-12">
										<div className="d-flex justify-content-end gap-2">
											<button className="btn btn-secondary"
												onClick={() => setDropdownIsOpen(false)}>
												{t('cancel')}
											</button>
											<button className="btn btn-primary"
												onClick={() => toggleModalCheques(true)}>
												{t('apply')}
											</button>
										</div>
									</div>
								</div>
							</Dropdown.Menu>
						</Dropdown>
					</div>

					<div className="filter-block">
						<div className="filter">
							<strong>{t('filter')}</strong>
							<br />
							{(filterData.start_date || filterData.end_date) &&
								<span className="me-2">
									<b>{t('period')}: </b>
									{filterData.start_date &&
										<span>{formatDate(filterData.start_date)}</span>
									}
									{(filterData.start_date && filterData.end_date) &&
										<span>|</span>
									}
									{filterData.end_date &&
										<span>{formatDate(filterData.end_date)}</span>
									}
								</span>
							}
						</div>
					</div>

					<div className="table-responsive">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '30%' }} className="td-ellipsis">{t('operators')}</th>
									<th style={{ 'width': '10%' }} className="td-ellipsis text-end">{t('products')}</th>
									<th style={{ 'width': '10%' }} className="td-ellipsis text-end"
										title={t('average_products_per_cheque')}>
										{t('average_products_per_cheque')}
									</th>
									<th style={{ 'width': '10%' }} className="td-ellipsis text-end">{t('average_check')}</th>
									<th style={{ 'width': '10%' }} className="td-ellipsis text-end">{t('takeaway')}</th>
									<th style={{ 'width': '10%' }} className="td-ellipsis text-end">{t('delivery')}</th>
									<th style={{ 'width': '10%' }} className="td-ellipsis text-end">{t('cheques')}</th>
									<th style={{ 'width': '10%' }} className="td-ellipsis text-end">{t('amount')}</th>
								</tr>
							</thead>
							<tbody>
								{cheques?.data?.map((item, index) => (
									<tr key={index}>
										<td className="text-center">{index + 1}</td>
										<td className="td-ellipsis">{item.operator_name}</td>
										<td className="text-end">{formatMoney(item.total_products)}</td>
										<td className="text-end">{formatMoney(item.avg_products_per_cheque, 2)}</td>
										<td className="text-end">{formatMoney(item.avg_total_amount)}</td>
										<td className="text-end">{formatMoney(item.count_takeaway)}</td>
										<td className="text-end">{formatMoney(item.count_delivery)}</td>
										<td className="text-end">{formatMoney(item.count_cheques)}</td>
										<td className="text-end">{formatMoney(item.total_amount)}</td>
									</tr>
								))}
								<tr>
									<td></td>
									<td><b>{t('total')}</b></td>
									<td className="text-end"><b>{formatMoney(cheques?.overall?.overall_products)}</b></td>
									<td></td>
									<td></td>
									<td className="text-end"><b>{formatMoney(cheques?.overall?.overall_takeaway)}</b></td>
									<td className="text-end"><b>{formatMoney(cheques?.overall?.overall_delivery)}</b></td>
									<td className="text-end"><b>{formatMoney(cheques?.overall?.overall_cheques)}</b></td>
									<td className="text-end"><b>{formatMoney(cheques?.overall?.overall_cheques_amount)}</b></td>
								</tr>
							</tbody>
						</table>
					</div>
				</Modal.Body>
			</Modal>
			{/* MODAL CHEQUES */}

			<DeleteModal
				modalDelete={modalDelete}
				setModalDelete={setModalDelete}
				deleteItem={deleteItem}>
			</DeleteModal>
		</>
	)
}

export default Orders