import React, { Fragment, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Modal, Dropdown } from 'react-bootstrap'
import Select, { components } from 'react-select'

import DeleteModal from 'pages/parts/DeleteModal'
import FilterSearch from 'pages/parts/FilterSearch'
import FilterPagination from 'pages/parts/FilterPagination'
import useDidMountEffect from 'pages/parts/useDidMountEffect'

import { GET, POST, PUT, DELETE, FILE, httpOk } from 'helpers/api'
import { formatMoney, returnSign, findKeyFromArrayByValue } from "helpers/helpers"
import CustomTooltip from "pages/parts/CustomTooltip"

function Index() {
	const { t } = useTranslation()

	const reduxAccount = useSelector(state => state.account)

	const statuses = [
		{ 'id': 1, 'name': t('success') },
		{ 'id': -1, 'name': t('deleted') },
	]

	const [data, setData] = useState([])
	const [selectedItem, setSelectedItem] = useState({
		'unit_id': 1,
		'element_type': 1,
		'bool_loss': 0,
	})
	const [elementTypes, setElementTypes] = useState([])
	const [categories, setCategories] = useState([])
	const [categoriesInitial, setCategoriesInitial] = useState([])
	const [units, setUnits] = useState([])
	const [products, setProducts] = useState([])
	const [isOpen, setIsOpen] = useState(false)
	const [showModal, setShowModal] = useState(false)
	const [modalDelete, setModalDelete] = useState(false)
	const [modalProducts, setModalProducts] = useState(false)
	const [deleteModalParams, setDeleteModalParams] = useState({ id: 0, index: 0 })
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		element_type: 1,
		status: 1,
		search: '',
	})
	const [pagination, setPagination] = useState({
		url: '/products',
		response: null
	})

	async function searchWithFilter() {
		var filterDataCopy = { ...filterData }
		var filterUrl = "/elements"
		var urlParams = ""
		checkFilter()

		if (filterDataCopy.element_type)
			urlParams += returnSign(urlParams) + 'element_type=' + filterDataCopy.element_type
		if (filterDataCopy.status)
			urlParams += returnSign(urlParams) + 'status=' + filterDataCopy.status
		if (filterDataCopy.search)
			urlParams += returnSign(urlParams) + 'search=' + filterDataCopy.search
		if (filterDataCopy.page)
			urlParams += returnSign(urlParams) + 'page=' + filterDataCopy.page

		if (`/elements${urlParams}` !== filterUrl)
			setFilterDataExist(true)

		filterUrl = filterUrl + urlParams
		const response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) {
			setData(response.data)
			setPagination({ ...pagination, 'url': filterUrl, 'response': response.data })
		}

		setDropdownIsOpen(false)
	}

	async function createUpdate(e) {
		e.preventDefault();

		var response;
		if (selectedItem.id) {
			response = await PUT('/elements', selectedItem, { loader: true })
		} else {
			response = await POST('/elements', selectedItem, { loader: true })
		}

		if (httpOk(response)) {
			setShowModal(false)
			searchWithFilter()
		}
	}

	function handleSelectUnit(option) {
		setSelectedItem({ ...selectedItem, 'unit_id': option.id })
	}

	async function deleteItem() {
		const response = await DELETE(`/elements/${deleteModalParams.id}`, {}, { loader: true })
		if (httpOk(response)) {
			searchWithFilter()
			setModalDelete(false)
		}
	}

	function toggleModal(
		bool,
		item = {
			'unit_id': 1,
			'element_type': 1,
			'bool_loss': 0,
		}) {
		if (bool) {
			if (item.second_unit_id) item.reform = true
			setSelectedItem(item)
		} else {
			setSelectedItem({
				'unit_id': 1,
				'element_type': 1,
				'bool_loss': 0,
			})
		}
		setShowModal(bool)
	}

	async function fileUpload(e) {
		let formData = new FormData();
		formData.append("id", data.id);
		formData.append("image", e.target.files[0]);
		formData.append("db_name", 'products');
		formData.append("column_name", 'image');
		formData.append("path", 'products');

		var response
		if (!data.id) {
			response = await FILE('/upload-image', formData, {}, { loader: true });
		} else {
			response = await FILE(`/update-image`, formData, {}, { loader: true });
		}
		if (httpOk(response)) setSelectedItem({ ...selectedItem, 'image': response.data.url })
	}

	function resetFilter() {
		setFilterData({
			pos_id: null,
			search: '',
		})
		searchWithFilter()
		setFilterDataExist(false)
	}

	function checkFilter() {
		if (
			filterData.pos_id === null &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function paginate(page) {
		setFilterData({ ...filterData, 'page': page })
	}

	async function selectCategory(option) {
		setIsOpen(!isOpen)
		setSelectedItem({ ...selectedItem, 'category_id': option?.id, 'category_name': option?.name, })
	}

	async function getUnits() {
		const response = await GET('/elements-units')
		if (httpOk(response)) {
			setUnits(response.data)
		}
	}

	async function getProducts(id) {
		const response = await GET(`/products-by-element/${id}`, {}, { loader: true })
		if (httpOk(response)) {
			setProducts(response.data)
			setModalProducts(true)
		}
	}

	async function getCategories() {
		var response = await GET('/helper/categories')
		response = response.data

		var arr = []
		for (let i = 0; i < response.length; i++) {
			response[i]['label'] = ` ${response[i]['name']}`
			response[i].child = false;
			if (response[i].parent_id) {
				const index = response.findIndex(x => x.id === response[i].parent_id)
				response[i].child = true;
				if (response[index]?.options && response[index]?.options?.length > 0) {
					response[index].options.push(response[i])
				} else {
					response[index].options = [response[i]]
				}
			} else {
				arr.push(response[i])
			}
		}

		setCategories(arr)
		setCategoriesInitial(response)
	}

	async function getElementTypes() {
		const response = await GET('/helper/products-element-types')
		if (httpOk(response)) setElementTypes(response.data)
	}

	useEffect(() => {
		getUnits()
		getCategories()
		getElementTypes()
		searchWithFilter()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useDidMountEffect(() => {
		searchWithFilter()
	}, [filterData.search, filterData.page]) // eslint-disable-line react-hooks/exhaustive-deps

	const GroupHead = props => {
		return (
			<div style={{
				padding: '0',
				color: selectedItem.category_id === props.data.id ? '#fff' : '#535277',
				fontWeight: 'bold',
				backgroundColor: selectedItem.category_id === props.data.id ? '#2684FF' : 'transparent',
				fontSize: '1rem'
			}}
				onClick={() => selectCategory(props.data)}>
				<components.Option {...props} />
			</div>
		);
	};

	const Option = props => {
		return (
			<div style={{
				padding: props.data.child ? '0 0 0 20px' : '0',
				color: '#535277',
				fontWeight: props.data.parent_id === 0 ? 'bold' : 'normal',
				fontSize: '.9rem'
			}}>
				<components.Option {...props} />
			</div>
		);
	};

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<h4 className="vertical-center">
					{t('elements')} [{data.total}]
				</h4>
				<div className="d-flex">
					<button className="btn-rounded btn btn-primary"
						onClick={() => toggleModal(true)}>
						{t('create')}
					</button>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex gap-2 mb-3">
						<div>
							<FilterSearch
								filterData={filterData}
								setFilterData={setFilterData}>
							</FilterSearch>
						</div>
						<div>
							<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
								<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100">
									{t('filter')}
								</Dropdown.Toggle>

								<Dropdown.Menu className="dropdown-filter">
									<div className="row">
										<div className="form-group col-md-6">
											<label>{t('type')}</label>
											<Select
												options={elementTypes}
												value={elementTypes.find(option => option.id === filterData.element_type) || ''}
												onChange={(option) => setFilterData({ ...filterData, 'element_type': option.id })}
												placeholder=""
												noOptionsMessage={() => t('list_empty')}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
											/>
										</div>
										<div className="form-group col-md-6">
											<label>{t('status')}</label>
											<Select
												isClearable
												options={statuses}
												value={statuses.find(option => option.id === filterData.status) || ''}
												onChange={(option) => setFilterData({ ...filterData, 'status': option ? option.id : null })}
												placeholder=""
												noOptionsMessage={() => t('list_empty')}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
											/>
										</div>
									</div>

									<div className="row mt-3">
										<div className="col-md-12">
											<div className="d-flex justify-content-end gap-2">
												<button className="btn btn-secondary"
													onClick={() => setDropdownIsOpen(false)}>
													{t('cancel')}
												</button>
												<button className="btn btn-primary"
													onClick={() => searchWithFilter()}>
													{t('apply')}
												</button>
											</div>
										</div>
									</div>
								</Dropdown.Menu>
							</Dropdown>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('filter')}</strong>
								<br />
								{filterData.element_type &&
									<span className="me-2">
										<b>{t('type')}: </b>
										{findKeyFromArrayByValue(elementTypes, 'id', filterData.element_type, 'name')}
									</span>
								}
								{filterData.status &&
									<span className="me-2">
										<b>{t('status')}: </b>
										{findKeyFromArrayByValue(statuses, 'id', filterData.status, 'name')}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('search')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase"
									onClick={resetFilter}>
									{t('reset')}
								</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '30%' }}>{t('name3')}</th>
									<th style={{ 'width': '10%' }}>{t('unit')}</th>
									<th style={{ 'width': '5%' }}>{t('rebuild')}</th>
									<th style={{ 'width': '10%' }}>{t('loss')} %</th>
									<th style={{ 'width': '10%' }}>{t('type')}</th>
									<th style={{ 'width': '10%' }} className="text-center">{t('used')} </th>
									<th style={{ 'width': '25%' }}></th>
									<th style={{ 'minWidth': '33px' }}></th>
								</tr>
							</thead>
							<tbody>
								{data?.data?.map((item, index) => (
									<tr key={index}>
										<td className="text-center">
											<CustomTooltip text={item.id}>
												{item.row_number}
											</CustomTooltip>
										</td>
										<td>
											<CustomTooltip text={item.image} params={'image'}>
												{item.name}
											</CustomTooltip>
										</td>
										<td>
											{item?.unit_name}
											{item.second_unit_name &&
												<span>
													<i className="uil uil-arrow-right"></i>
													{item?.second_unit_name}
												</span>
											}
										</td>
										<td className="text-end">
											{item.quantity &&
												<span>{formatMoney(item.quantity, 3)}</span>
											}
										</td>
										<td>{item.loss}</td>
										<td>
											{item.element_type === 1 &&
												<span>{t('ingredient')}</span>
											}
											{item.element_type === 2 &&
												<span>{t('consumables')}</span>
											}
											{item.element_type === 3 &&
												<span>{t('inventory')}</span>
											}
										</td>
										<td className={`text-center text-white ${item.used ? 'bg-primary cursor' : 'bg-danger'}`}
											onClick={() => getProducts(item.id)}>
											<span>{item.counted}</span>
										</td>
										<td></td>
										<td>
											<div className="table-dropdown">
												<Dropdown id={`dropdown-${index}`}>
													<Dropdown.Toggle variant="outline-primary">
														<i className="uil uil-ellipsis-v"></i>
													</Dropdown.Toggle>

													<Dropdown.Menu>
														<div className="table-dropdown-item"
															onClick={() => toggleModal(true, item)}>
															<i className="uil-edit-alt"></i>
															{t('edit')}
														</div>
														{!item.used &&
															<div className="table-dropdown-item text-danger"
																onClick={() => {
																	setDeleteModalParams({ id: item.id, index: index });
																	setModalDelete(true)
																}}>
																<i className="uil-times"></i>
																{t('delete')}
															</div>
														}
													</Dropdown.Menu>
												</Dropdown>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					{pagination.response &&
						<FilterPagination
							pagination={pagination}
							paginate={paginate}>
						</FilterPagination>
					}
				</div>
			</div>

			{/* MODAL */}
			<Modal show={showModal} animation={false} centered
				dialogClassName="update-modal-width" backdrop="static"
				onHide={() => toggleModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						{!selectedItem?.id ?
							<>{t('create')}</>
							:
							<>{t('edit')}</>
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={(e) => createUpdate(e)} autoComplete="off">
						<div className="d-flex gap-3">
							<div className="form-group w-100">
								<label>{t('name3')}<span className="required-mark">*</span></label>
								<input type="text" className="form-control" name="name"
									value={selectedItem.name ?? ''}
									onChange={(e) => setSelectedItem({ ...selectedItem, 'name': e.target.value })} />
							</div>
							<div className="form-group w-100">
								<label>{t('loss')}</label>
								<div className="d-flex gap-3">
									<div className="form-check">
										<input id="bool_loss1" className="form-check-input" type="radio" name="bool_loss"
											checked={selectedItem.bool_loss === 0 ?? true}
											onChange={(e) => {
												if (e.target.checked) {
													setSelectedItem({ ...selectedItem, 'bool_loss': 0, 'loss': null })
												}
											}}
										/>
										<label className="form-check-label" htmlFor="bool_loss1">
											{t('no')}
										</label>
									</div>
									<div className="form-check">
										<input id="bool_loss2" className="form-check-input" type="radio" name="bool_loss"
											checked={selectedItem.bool_loss === 1 ?? true}
											onChange={(e) => {
												if (e.target.checked) {
													setSelectedItem({ ...selectedItem, 'bool_loss': 1 })
												}
											}}
										/>
										<label className="form-check-label" htmlFor="bool_loss2">
											{t('yes')}
										</label>
									</div>
								</div>
							</div>
						</div>
						<div className="d-flex gap-3">
							<div className="form-group w-100">
								<label>{t('unit')}<span className="required-mark">*</span></label>
								<Select
									options={units}
									value={units.find(option => option.id === selectedItem.unit_id)}
									onChange={(option) => handleSelectUnit(option)}
									placeholder=""
									noOptionsMessage={() => t('list_empty')}
									getOptionLabel={(option) => option.name}
									getOptionValue={(option) => option.id}
								/>
							</div>
							<div className="form-group w-100">
								<label>{t('loss')} %</label>
								<input type="number" className="form-control" name="loss"
									disabled={!selectedItem.bool_loss}
									value={selectedItem.loss ?? ''}
									onChange={(e) => setSelectedItem({ ...selectedItem, 'loss': e.target.value })} />
							</div>
						</div>
						<div className="form-group w-100">
							<label>{t('type')}</label>
							<Select
								isDisabled={selectedItem.id}
								options={elementTypes}
								value={elementTypes.find(option => option.id === selectedItem.element_type) || ''}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'element_type': option.id })}
								placeholder=""
								noOptionsMessage={() => t('list_empty')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						<div className="form-group w-100">
							<label>{t('category')}</label>
							<Select
								components={{ GroupHeading: GroupHead, Option }}
								isClearable
								isSearchable={false}
								onMenuOpen={() => setIsOpen(true)}
								onMenuClose={() => setIsOpen(false)}
								onBlur={() => setIsOpen(false)}
								menuIsOpen={isOpen}
								classNamePrefix='react-select'
								options={categories}
								value={categoriesInitial.find(option => option.id === selectedItem.category_id)}
								onChange={(option) => selectCategory(option)}
								placeholder={t('categories')}
								noOptionsMessage={() => t('list_empty')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						<div className="form-group w-100">
							<label>{t('image2')}</label>
							<input type="file" className="form-control" name="image"
								placeholder=""
								onChange={(e) => fileUpload(e)} />
						</div>
						{selectedItem.unit_id !== 2 &&
							<>
								<div className="form-group w-100">
									<label>Вес 1 единицы измерения КГ</label>
									<input type="number" className="form-control" name="quantity"
										value={selectedItem.quantity ?? ''}
										onChange={(e) => setSelectedItem({ ...selectedItem, 'quantity': e.target.value })} />
								</div>
							</>
						}

						<div className="d-flex w-100 mt-3 gap-2">
							<button type="button" className="btn btn-outline-warning w-100"
								onClick={() => toggleModal(false)}>{t('cancel')}</button>
							<button type="submit" className="btn btn-primary w-100"
								disabled={!(selectedItem.name && selectedItem.unit_id)}>
								{t('save')}
							</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL */}

			{/* MODAL */}
			<Modal show={modalProducts} animation={false} centered
				dialogClassName="update-modal-width" backdrop="static"
				onHide={() => setModalProducts(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						{t('in_products')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="table-responsive">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th style={{ 'width': '33px' }} className="text-center">#</th>
									<th>{t('name2')}</th>
									<th>{t('type')}</th>
									<th style={{ 'width': '33px' }} className="text-center"></th>
								</tr>
							</thead>
							<tbody>
								{products?.map((item, index) => (
									<tr key={index}>
										<td className="text-center">{index + 1}</td>
										<td>{item.name}</td>
										<td>
											{item.in_product === 1 &&
												<span>{t('product')}</span>
											}
											{item.in_product === 2 &&
												<span>{t('dish_preparation')}</span>
											}
											{item.in_product === 3 &&
												<span>{t('modifiers')}</span>
											}
										</td>
										<td className="d-flex justify-content-center">
											<Link to={`/products/update/${reduxAccount.pos_id}/${item.id}`} className="fw-medium text-white">
												<div className="table-action-button table-action-primary-button">
													<i className="uil-edit-alt"></i>
												</div>
											</Link>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</Modal.Body>
			</Modal>
			{/* MODAL */}

			<DeleteModal
				modalDelete={modalDelete}
				setModalDelete={setModalDelete}
				deleteItem={deleteItem}>
			</DeleteModal>
		</>
	)
}

export default Index