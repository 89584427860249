import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Modal } from 'react-bootstrap'
import Select from 'react-select'

import Search from 'pages/parts/Search'
import Pagination from 'pages/parts/Pagination'

import Rightbar from '../cheques/Rightbar'
import { GET, POST, httpOk } from 'helpers/api'
import { formatDate, formatMoney } from 'helpers/helpers'
import CustomTooltip from "pages/parts/CustomTooltip"

function Index() {
	const { t } = useTranslation()

	const settings = useSelector(state => state.settings)
	const reduxReport = useSelector(state => state.report)

	const paymentTypes = [{ 'id': 1, 'name': t('cash') }, { 'id': 2, 'name': t('terminal') }, { 'id': 3, 'name': t('money_transfer') }]

	const [showRightBar, setShowRightBar] = useState(false)
	const [selectedItem, setSelectedItem] = useState({})
	const [pageCount, setPageCount] = useState(0)
	const [poses, setPoses] = useState([])
	const [data, setData] = useState([])
	const [totalAmount, setTotalAmont] = useState(0)
	const [modalPayment, setModalPayment] = useState(false)
	const [paymentData, setPaymentData] = useState({})

	async function toggleRightbar(boolean = false, item, index) {
		var dataCopy = [...data]

		for (let i = 0; i < dataCopy.length; i++) {
			dataCopy[i]['showSidebar'] = false
		}

		if (item.chequeId !== 0) {
			if (boolean) {
				const response = await GET("/cheques/" + item.cheque_id, {}, { loader: true })
				if (httpOk(response)) {
					dataCopy[index]['showSidebar'] = boolean
					setSelectedItem(response.data)
					setShowRightBar(boolean)
				}

			} else {
				setShowRightBar(boolean)
				setSelectedItem({})
			}
		}
	}

	async function payment(e) {
		e.preventDefault();

		var paymentDataCopy = { ...paymentData }

		var sendData = {}
		sendData.amount_in = paymentDataCopy.amount_in
		sendData.agent_id = reduxReport.reportSelectedClient.agent_id
		sendData.payment_type_id = paymentDataCopy.payment_type_id
		sendData.pos_id = paymentDataCopy.pos_id
		sendData.note = paymentDataCopy.note

		var response = await POST('/clients-payment', sendData, { loader: true })

		if (httpOk(response)) {
			showPaymentModal(false)
			getData()
		}
	}

	async function showPaymentModal(bool) {
		var paymentDataCopy = {}
		if (!bool) {
			paymentDataCopy.payment_type_id = 1
			paymentDataCopy.amount_in = ''
		} else {
			const response = await GET('/helper/poses')
			if (httpOk(response)) {
				setPoses(response.data)
			}

			paymentDataCopy.pos_id = response.data[0]['id']
			paymentDataCopy.payment_type_id = 1
			paymentDataCopy.amount_in = ''
			setPaymentData(paymentDataCopy)
		}

		setModalPayment(bool)
	}

	async function getData() {
		const response = await GET("/report-agents-history/" + reduxReport.reportSelectedAgent.agent_id, {}, { loader: true })
		if (httpOk(response)) {
			setPageCount(Math.ceil(response.data.total / settings.size))
			setData(response.data.data);
			setTotalAmont(response.data.total_amount)
		}
	}

	async function exportToExcel() {
		//
	}

	async function paginate(data) {
		const response = await GET("/report-clients-history/" + reduxReport.reportSelectedClient.agent_id, { page: data.selected, size: settings.size })
		if (httpOk(response)) setData(response.data.data);
	}

	async function search(data, searchValue) {
		setData(data);
		if (searchValue.length > 0) {
			setPageCount(0)
		}
	}

	useEffect(() => {
		getData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex text-truncate">
				<h4 className="vertical-center h-100">
					{t('agents')}
				</h4>
				<div className="vertical-center">
					<i className="uil uil-angle-double-right fz-20"></i>
				</div>
				<div className="vertical-center">
					<h6>{reduxReport.reportSelectedAgent.first_name}</h6>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex justify-content-between flex-wrap mb-3">
						<div className="d-flex gap-2">
							<Search url={"/services/web/api/report-client-history-pageList/" + reduxReport.reportSelectedClient.agent_id} search={search} getData={getData}></Search>
							<div>
								<button className="btn btn-outline-primary btn-wide me-2 d-none" onClick={() => showPaymentModal(true)}>
									{t('pay')}
								</button>
							</div>
							<div>
								<CustomTooltip text={t('EXCEL')}>
									<button className="btn btn-outline-primary btn-wide-2 d-none" onClick={exportToExcel}>
										<i className="uil uil-file-download-alt"></i>
									</button>
								</CustomTooltip>
							</div>
						</div>
						<div className="d-flex">
							<div className="vertical-center me-2">
								<h4>
									<b>{t('bonus')}</b>:
								</h4>
							</div>
							<div className="vertical-center">
								<h5 className="text-end" >
									<span className="me-1">{formatMoney(totalAmount)}</span>
								</h5>
							</div>
						</div>
					</div>

					<div className="table-responsive">
						<table className="table table-bordered table-hover cursor mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '20%' }}>{t('pos')}</th>
									<th style={{ 'width': '10%' }} className="text-center">{t('date')}</th>
									<th style={{ 'width': '10%' }} className="text-center">{t('status')}</th>
									<th style={{ 'width': '10%' }} className="text-center">{t("payment_method")}</th>
									<th style={{ 'width': '40%' }} className="text-center">{t('note')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('bonus')}</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr key={index} onClick={() => toggleRightbar(true, item, index)} className={'cursor ' + (item.showSidebar ? 'table-tr-active' : '')}>
										<td className="text-center">{item.row_number}</td>
										<td>{item.pos_name}</td>
										<td className="text-center">{formatDate(item.created_at, 'dd.MM.yy HH:mm')}</td>
										<td className="text-center">{item.payment_purpose_name}</td>
										<td className="text-center">{item.payment_type_name}</td>
										<td className="text-center">{item.note}</td>
										<td className="text-end">{formatMoney(item.amount_out)}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					<Pagination pageCount={pageCount} paginate={paginate}></Pagination>
				</div>
			</div>

			<Rightbar showRightBar={showRightBar} setShowRightBar={setShowRightBar} selectedItem={selectedItem}></Rightbar>

			{/* MODAL payment */}
			<Modal show={modalPayment} animation={false} centered dialogClassName="update-modal-width" backdrop="static" onHide={() => showPaymentModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('pay')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={payment} autoComplete="off">
						<div className="form-group">
							<label>{t('pos')}</label>
							<Select
								options={poses}
								value={poses.find(option => option.id === paymentData.pos_id)}
								onChange={(option) => setPaymentData({ ...paymentData, 'pos_id': option.id })}
								placeholder=""
								noOptionsMessage={() => t('list_empty')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						<div className="form-group">
							<label>{t('payment_method')}</label>
							<Select
								options={paymentTypes}
								value={paymentTypes.find(option => option.id === paymentData.payment_type_id)}
								onChange={(option) => setPaymentData({ ...paymentData, 'payment_type_id': option.id })}
								placeholder=""
								noOptionsMessage={() => t('list_empty')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						<div className="form-group">
							<label>{t('amount')}<span className="required-mark">*</span></label>
							<input type="number" className="form-control" name="amount_in" autoFocus
								onChange={(e) => setPaymentData({ ...paymentData, 'amount_in': e.target.value })} />
						</div>
						<div className="form-group">
							<label>{t('note')}</label>
							<input type="text" className="form-control" name="note"
								onChange={(e) => setPaymentData({ ...paymentData, 'note': e.target.value })} />
						</div>
						<div className="d-flex w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100 me-2"
								onClick={() => showPaymentModal(false)} tabIndex="-1">{t('cancel')}</button>
							<button type="submit" className="btn btn-primary w-100" disabled={!paymentData.amount_in}>
								{t('save')}
							</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL payment */}
		</>
	)
}

export default Index
