import React, { Fragment, useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import DatePicker from "react-datepicker"
import XLSX from 'xlsx'

import { TOGGLE_LEFT_MENU } from 'store/layout/actions'
import { GET, httpOk } from 'helpers/api'
import { formatDate, formatMoney, returnSign } from 'helpers/helpers'

function Index() {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const date = new Date()

	const [data, setData] = useState([])
	const [poses, setPoses] = useState([])
	const [filterData, setFilterData] = useState({
		start_date: formatDate(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd'),
		end_date: formatDate(new Date(), 'yyyy-MM-dd'),
		ids: []
	})

	async function searchWithFilter(filterDataCopy) {
		//var filterDataCopy = { ...filterData }
		var filterUrl = "/chain/element-sales"
		var urlParams = ""

		if (filterDataCopy.ids)
			urlParams += returnSign(urlParams) + 'ids=' + filterDataCopy.ids
		if (filterDataCopy.start_date)
			urlParams += returnSign(urlParams) + 'start_date=' + filterDataCopy.start_date
		if (filterDataCopy.end_date)
			urlParams += returnSign(urlParams) + 'end_date=' + filterDataCopy.end_date

		filterUrl = filterUrl + urlParams
		const response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) setData(response.data)
	}

	function handleCheckboxChangeAll(e) {
		var filterDataCopy = { ...filterData }

		filterDataCopy.ids = []
		if (e.target.checked) {
			for (let i = 0; i < poses.length; i++) {
				filterDataCopy.ids.push(poses[i]['id'])
			}
		}

		setFilterData(filterDataCopy)
	}

	function handleCheckboxChange(e, id) {
		var filterDataCopy = { ...filterData }

		if (e.target.checked) {
			filterDataCopy.ids.push(id)
		} else {
			filterDataCopy.ids = filterDataCopy.ids.filter(item => item !== id)
		}

		setFilterData(filterDataCopy)
	}

	function exportExcel() {
		var excelHeaders = [[t('name2'), t('unit')]]
		for (let i = 0; i < data.poses.length; i++) {
			var pos = data.poses[i]
			excelHeaders[0].push(pos.short_name)
		}
		excelHeaders[0].push(t('total'))

		for (let i = 0; i < data.elements.length; i++) {
			var item = data.elements[i]
			var excelItems = []
			excelItems.push(item.name)
			excelItems.push(item.unit_name)
			for (let i = 0; i < item.poses.length; i++) {
				var item2 = item.poses[i];
				excelItems.push(Number(item2.sale).toFixed(2))
			}
			excelItems.push(Number(item.total_sale).toFixed(2))
			excelHeaders.push(excelItems)
		}

		const ws = XLSX.utils.aoa_to_sheet(excelHeaders);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
		XLSX.writeFile(wb, t('elements_sales') + ".xlsx");
	}

	async function getPoses() {
		const response = await GET('/helper/poses')

		if (httpOk(response)) {
			setPoses(response.data)
		}
	}

	useEffect(() => {
		getPoses()
		dispatch(TOGGLE_LEFT_MENU(false))
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="d-flex gap-3">
				<div style={{ 'minWidth': '200px' }}>
					<div className="card mb-3" style={{ 'height': 'calc(100vh - 300px)' }}>
						<div className="card-body">
							<h5 className="mb-3">{t('poses')}</h5>
							<div className="flex-column" style={{ 'height': 'calc(100vh - 400px)', 'overflow': 'auto' }}>
								<div className="form-check">
									<input id="all"
										className="form-check-input"
										type="checkbox"
										onChange={(e) => handleCheckboxChangeAll(e)}
									/>
									<label className="form-check-label" htmlFor="all">
										{t('all')}
									</label>
								</div>
								{poses?.map((item, index) => (
									<div className="form-check" key={index}>
										<input id={`pos${index}`} className="form-check-input" type="checkbox"
											checked={filterData?.ids?.includes(item.id)}
											onChange={(e) => handleCheckboxChange(e, item.id)}
										/>
										<label className="form-check-label" htmlFor={`pos${index}`}>
											{item.short_name}
										</label>
									</div>
								))}
							</div>
							<button className="btn btn-outline-primary btn-wide-2 w-100"
								onClick={() => exportExcel()}>
								EXCEL
							</button>
						</div>
					</div>

					<div className="card">
						<div className="card-body">
							<div>
								<div className="form-group">
									<label>{t('period')}</label>
									<div className="calendar-input">
										<DatePicker
											className="form-control"
											dateFormat="dd.MM.yyyy"
											selected={filterData.start_date ? new Date(filterData.start_date) : ''}
											onChange={(date) => setFilterData({ ...filterData, 'start_date': formatDate(date, 'yyyy-MM-dd') })} />
										<i className="uil uil-calendar-alt"></i>
									</div>
								</div>
								<div className="form-group">
									<label>{t('period')}</label>
									<div className="calendar-input">
										<DatePicker
											className="form-control"
											dateFormat="dd.MM.yyyy"
											selected={filterData.end_date ? new Date(filterData.end_date) : ''}
											onChange={(date) => setFilterData({ ...filterData, 'end_date': formatDate(date, 'yyyy-MM-dd') })} />
										<i className="uil uil-calendar-alt"></i>
									</div>
								</div>
								<button className="btn btn-primary w-100"
									onClick={() => searchWithFilter(filterData)}>
									{t('apply')}
								</button>
							</div>
						</div>
					</div>
				</div>

				<div className="w-100">
					<div className="card">
						<div className="card-body">
							<div className="table-responsive table-fixed"
								style={{ 'height': 'calc(100vh - 105px)', 'overflow': 'auto' }}>
								<table className="table table-bordered mb-0">
									<thead>
										<tr>
											<th style={{ width: '33px' }} className="text-center">#</th>
											<th style={{ width: '50%' }}>{t('name2')}</th>
											<th style={{ width: '15px' }} className="text-center">
												{t('unit')}
											</th>
											{data?.poses?.map((item, index) => (
												<th key={index} style={{ width: '2rem', height: '' }}>
													<div className="text-end">
														{item.short_name}
													</div>
												</th>
											))}
											<th className="text-nowrap text-end">
												{t('total')}
											</th>
										</tr>
									</thead>
									<tbody>
										{data?.elements?.map((item, index) => (
											<tr key={index}>
												<td className="text-center">{index + 1}</td>
												<td>{item.name}</td>
												<td className="text-center">{item.unit_name}</td>
												{item?.poses?.length &&
													<Fragment>
														{item.poses.map((item2, index2) => (
															<Fragment key={index2}>
																<td className="text-nowrap text-end">
																	{formatMoney(item2.sale, 2)}
																</td>
															</Fragment>
														))}
													</Fragment>
												}
												<td className="text-nowrap text-end">
													<b>{formatMoney(item.total_sale, 2)}</b>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Index