import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useParams } from "react-router-dom"
import { Dropdown } from 'react-bootstrap'
import Select from 'react-select'

import FilterSearch from 'pages/parts/FilterSearch'
import FilterPagination from 'pages/parts/FilterPagination'

import Rightbar from '../cheques/Rightbar'
import { GET, httpOk } from 'helpers/api'
import { findKeyFromArrayByValue, formatDate, formatMoney, returnSign } from 'helpers/helpers'
import CustomTooltip from "pages/parts/CustomTooltip"

function Index() {
	const { t } = useTranslation()
	const { organization_id } = useParams()

	const statuses = [
		{ 'id': 1, 'name': t('success') },
		{ 'id': -1, 'name': t('deleted') },
	]

	const [showRightBar, setShowRightBar] = useState(false)
	const [selectedItem, setSelectedItem] = useState({})
	const [data, setData] = useState([])
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		status: 1,
		search: '',
	})
	const [pagination, setPagination] = useState({
		url: `/report-organizations-history/${organization_id}`,
		response: null
	})

	async function toggleRightbar(boolean = false, item, index) {
		var dataCopy = { ...data }

		for (let i = 0; i < dataCopy.data.length; i++) {
			dataCopy.data[i]['showSidebar'] = false
		}

		if (item.cheque_id !== 0) {
			if (boolean) {
				const response = await GET("/cheques/" + item.cheque_id, {}, { loader: true })
				if (httpOk(response)) {
					dataCopy.data[index]['showSidebar'] = boolean
					setSelectedItem(response)
					setShowRightBar(boolean)
				}

			} else {
				setShowRightBar(boolean)
				setSelectedItem({})
			}
		}
	}

	async function searchWithFilter() {
		var filterUrl = `/report-organizations-history/${organization_id}`
		var urlParams = ""
		checkFilter()

		if (filterData.status)
			urlParams += returnSign(urlParams) + 'status=' + filterData.status
		if (filterData.search)
			urlParams += returnSign(urlParams) + 'search=' + filterData.search
		if (filterData.page)
			urlParams += returnSign(urlParams) + 'page=' + filterData.page

		if (`/report-organizations-history/${organization_id}${urlParams}` !== filterUrl)
			setFilterDataExist(true)

		filterUrl = filterUrl + urlParams

		const response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) {
			setData(response.data)
			setPagination({ ...pagination, 'url': filterUrl, 'response': response.data })
		}

		setDropdownIsOpen(false)
	}

	async function exportToExcel() {
		//
	}

	async function paginate(page) {
		setFilterData({ ...filterData, 'page': page })
	}

	function resetFilter() {
		setFilterData({
			status: 1,
			search: '',
		})
		searchWithFilter()
	}

	function checkFilter() {
		if (
			filterData.status === null &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	useEffect(() => {
		searchWithFilter()
	}, [filterData.search, filterData.page]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex text-truncate">
				<h4 className="vertical-center h-100">
					{t('organizations')}
				</h4>
				<div className="vertical-center">
					<i className="uil uil-angle-double-right fz-20"></i>
				</div>
				<div className="vertical-center">
					<h6>{data.organization_name}</h6>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex justify-content-between flex-wrap mb-3">
						<div className="d-flex gap-2">
							<FilterSearch
								filterData={filterData}
								setFilterData={setFilterData}>
							</FilterSearch>
							<div>
								<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100">
										{t('filter')}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-filter">
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('status')}</label>
												<Select
													options={statuses}
													value={statuses.find(option => option.id === filterData.status) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'status': option.id })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex justify-content-end gap-2">
													<button className="btn btn-secondary"
														onClick={() => setDropdownIsOpen(false)}>{t('cancel')}</button>
													<button className="btn btn-primary"
														onClick={() => searchWithFilter(false)}>{t('apply')}</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
							<div>
								<CustomTooltip text={t('EXCEL')}>
									<button className="btn btn-outline-primary btn-wide-2 d-none" onClick={exportToExcel}>
										<i className="uil uil-file-download-alt"></i>
									</button>
								</CustomTooltip>
							</div>
						</div>
						<div className="d-flex">
							<div className="vertical-center me-2">
								<h4><b>{t('amount')}</b>:</h4>
							</div>
							<div className="vertical-center">
								<h5 className="text-end">
									<span className={`me-1 ${data.total_amount > 0 ? 'text-success' : 'text-danger'}`}>
										{formatMoney(data.total_amount)}
									</span>
								</h5>
							</div>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('filter')}</strong>
								<br />
								{filterData.status &&
									<span className="me-2">
										<b>{t('status')}: </b>
										{findKeyFromArrayByValue(statuses, 'id', filterData.status, 'name')}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('search')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>{t('reset')}</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-bordered table-hover cursor mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '20%' }}>{t('pos')}</th>
									<th style={{ 'width': '40%' }} className="text-center">{t('note')}</th>
									<th style={{ 'width': '10%' }} className="text-center">{t("payment_method")}</th>
									<th style={{ 'width': '10%' }} className="text-center">{t('date')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('amount_of_debt')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('paid')}</th>
								</tr>
							</thead>
							<tbody>
								{data?.data?.map((item, index) => (
									<tr key={index} className={'cursor ' + (item.showSidebar ? 'table-tr-active' : '')}
										onClick={() => {
											if (item.cheque_id) {
												toggleRightbar(true, item, index)
											}
										}}>
										<td className="text-center">{item.row_number}</td>
										<td>{item.pos_name}</td>
										<td className="text-center">{item.note}</td>
										<td className="text-center">{item.payment_type_name}</td>
										<td className="text-center">{formatDate(item.created_at, 'dd.MM.yy HH:mm')}</td>
										<td className="text-end text-danger">{formatMoney(item.transaction_type === 'minus' ? item.amount : 0)}</td>
										<td className="text-end text-success">{formatMoney(item.transaction_type === 'plus' ? item.amount : 0)}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					{pagination.response &&
						<FilterPagination
							pagination={pagination}
							paginate={paginate}>
						</FilterPagination>
					}
				</div>
			</div>

			<Rightbar showRightBar={showRightBar} setShowRightBar={setShowRightBar} selectedItem={selectedItem}></Rightbar>
		</>
	)
}

export default Index
