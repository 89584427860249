import { formatMoney } from "helpers/helpers"
import React from "react"
import { useTranslation } from 'react-i18next'

function PlanSettings({ data, setData }) {
	const { t } = useTranslation()

	function addToList() {
		var dataCopy = { ...data }
		var obj = {
			'percentage': '',
			'profit_percentage': ''
		}
		if (!dataCopy.plan_settings) {
			dataCopy.plan_settings = []
		}
		dataCopy.plan_settings.push(obj)
		setData(dataCopy)
	}

	function deleteItem(index) {
		var dataCopy = { ...data }

		dataCopy.plan_settings.splice(index, 1)
		setData(dataCopy)
	}

	function handleInputChange(e, index) {
		var dataCopy = { ...data }

		dataCopy.plan_settings[index][e.target.name] = e.target.value.replace(/[^0-9.]/g, '')
		setData(dataCopy)
	}

	return (
		<>
			<div className="row">
				<div className="col-md-12">
					<div className="d-flex justify-content-between my-3">
						<div className="vertical-center">
							<h5><b>{t('percentage')}</b></h5>
						</div>
						<div className="d-flex justify-content-center">
							<div className="table-action-button table-action-primary-button"
								onClick={() => addToList()}>
								<i className="uil-plus"></i>
							</div>
						</div>
					</div>

					<div className="table-responsive">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th>{t('percentage')} %</th>
									<th>{t('bonus')} %</th>
									<th className="text-end"></th>
								</tr>
							</thead>
							<tbody>
								{data?.plan_settings?.map((item, index) => (
									<tr key={index}>
										<td className="w-25 td-input">
											<input type="text" className="form-control" name="percentage"
												value={item.percentage}
												onChange={(e) => handleInputChange(e, index)} />
										</td>
										<td className="w-25 td-input">
											<input type="text" className="form-control" name="profit_percentage"
												value={item.profit_percentage ? formatMoney(item.profit_percentage) : ''}
												onChange={(e) => {
													if (e.target.value <= 100) {
														handleInputChange(e, index)
													}
												}} />
										</td>
										<td className="w-50">
											<div className="d-flex justify-content-center">
												<div className="table-action-button table-action-danger-button"
													onClick={() => deleteItem(index)}>
													<i className="uil uil-times"></i>
												</div>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</>
	)
}

export default PlanSettings