import React, { useEffect, useState, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { Dropdown, Modal, DropdownButton } from 'react-bootstrap'
import Select from 'react-select'
import DatePicker from "react-datepicker"
import { toast } from 'react-toastify'

import ReactSelect from 'pages/parts/ReactSelect'
import { SET_TRANSFER_DATA } from "store/transferData/action";
import { GET, POST, DELETE, httpOk, PUT } from 'helpers/api'
import { formatDate, formatMoney, returnMinDate, findKeyFromArrayByValue } from 'helpers/helpers'

function CreateUpdate() {
	const { t } = useTranslation()
	const { id } = useParams()
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const tbodyRef = useRef()
	const scrollRef = useRef()

	const reduxAccount = useSelector(state => state.account)
	const reduxTransferData = useSelector(state => state.transferData)

	const accountTypes = [
		{ 'id': 1, 'name': t('safe') },
		{ 'id': 2, 'name': t('bank_account') },
	]

	const [data, setData] = useState({
		'items': [],
		'from_wms_id': reduxAccount.wms_id ?? null,
		'to_pos_id': null,
		'note': '',
		'total_sale_price': 0,
		'organization_amount_in': '',
		'created_at': formatDate(new Date(), 'yyyy-MM-dd'),
	})
	const [addQuantity, setAddQuantity] = useState(1)
	const [wms, setWms] = useState([])
	const [pos, setPos] = useState({})
	const [poses, setPoses] = useState([])
	const [items, setItems] = useState([])
	const [itemsDuplicate, setItemsDuplicate] = useState([])
	const [modalConfirm, setModalConfirm] = useState(false)
	const [modalTemplate, setModalTemplate] = useState({ 'bool': false, 'templates': [] })
	const [modalMarkup, setModalMarkup] = useState({ 'bool': false, 'products': [] })

	async function createUpdate(stage = 1) {
		var dataCopy = { ...data }
		dataCopy.error = false

		if (dataCopy.items.length === 0) {
			toast.error(t('add_elements'))
			return
		}

		for (let i = 0; i < dataCopy.items.length; i++) {
			if (!dataCopy.items[i]['quantity']) {
				dataCopy.error = true
			}
		}

		var valueArr = dataCopy.items.map(function (item) { return item.product_id });
		var isDuplicate = valueArr.some(function (item, idx) {
			return valueArr.indexOf(item) !== idx
		});

		if (isDuplicate) {
			toast.error(t('identical_elements'))
			return
		}

		if (dataCopy.error) {
			toast.error(t('check_filled_inputs'))
			return
		}

		if (id) {
			const response = await PUT('/service/wms-transfer-pos', dataCopy, { loader: true })

			if (httpOk(response)) {
				navigate('/service/wms-transfer-pos')
			}
			return
		}

		if (stage === 1 || stage === 2) {
			setModalConfirm(true)
			return
		}

		if (!id) {
			const response = await POST('/service/wms-transfer-pos', dataCopy, { loader: true })

			if (httpOk(response)) {
				navigate('/service/wms-transfer-pos')
			}
		}
	}

	async function updateMarkup() {
		const response = await PUT('/products-markup', modalMarkup.products, { loader: true })

		if (httpOk(response)) {
			toggleModalMarkup(false)
			return
		}
	}

	function addToList(length = 1) {
		var dataCopy = JSON.parse(JSON.stringify(data))

		for (let i = 0; i < length; i++) {
			dataCopy.items.push({
				'id': '',
				'sale_price': '',
				'quantity': '',
			})
		}
		countTotalAmount(dataCopy)

		setTimeout(() => {
			const byId = document.getElementById(dataCopy.items.length)
			byId.select()
		}, 200);

	}

	async function deleteItem(index) {
		var dataCopy = JSON.parse(JSON.stringify(data))
		dataCopy.items.splice(index, 1)

		countTotalAmount(dataCopy)
	}

	function countTotalAmount(dataCopy) {
		dataCopy.total_quantity = 0
		dataCopy.total_sale_price = 0
		for (let i = 0; i < dataCopy.items.length; i++) {
			dataCopy.total_quantity += Number(dataCopy.items[i]['quantity'])
			dataCopy.total_sale_price += Number(dataCopy.items[i]['quantity']) * Number(dataCopy.items[i]['sale_price'])
		}

		setData(dataCopy)
	}

	function handleSelectWms(option) {
		getProducts(option.id)
	}

	function handleInputChange(e, index) {
		var dataCopy = { ...data }
		if (e.target.name === 'quantity') {
			if (dataCopy.items[index]['markup']) {
				dataCopy.items[index]['sale_price'] =
					Number(dataCopy.items[index]['cost_price']) +
					Number(dataCopy.items[index]['cost_price'] * dataCopy.items[index]['markup'] / 100)
			}
			if (dataCopy.items[index]['markup_round']) {
				dataCopy.items[index]['sale_price'] =
					Math.round(dataCopy.items[index]['sale_price'] / dataCopy.items[index]['markup_round']) *
					dataCopy.items[index]['markup_round'];
			}
		}

		dataCopy.items[index][e.target.name] = e.target.value
		countTotalAmount(dataCopy)
	}

	function handleChangeMarkup(e, index) {
		var markupProductsCopy = [...modalMarkup?.products]
		markupProductsCopy[index][e.target.name] = e.target.value

		setModalMarkup({ ...modalMarkup, 'products': markupProductsCopy })
	}

	// REACTSELECT LOGIC
	function handleSelectSearch(value) {
		if (!value) {
			setItems([...itemsDuplicate])
			return
		}
		var elementsDuplicateCopy = [...itemsDuplicate]

		var filteredItems = elementsDuplicateCopy.filter(item => item.name.toLowerCase().includes(value.toLowerCase()))
		if (filteredItems[0]) {
			filteredItems[0]['selected'] = true
		}
		setItems(filteredItems)
	}

	function handleSelectElement(option, index) {
		var dataCopy = { ...data }
		dataCopy.items[index] = option
		setData(dataCopy)
	}

	function closeReactSelect() {
		setTimeout(() => {
			var elementsCopy = [...items]
			for (let i = 0; i < elementsCopy.length; i++) {
				elementsCopy[i]['selected'] = false
			}
			if (elementsCopy[0] && elementsCopy[0]['selected'])
				elementsCopy[0]['selected'] = true
			setItems(elementsCopy)
		}, 100);
	}

	function handleTableKeyDown(event) {
		event.stopPropagation();
		var currentIndex = items.findIndex(el => el.selected);
		if (currentIndex >= 0) {
			var elementsCopy = [...items]
			elementsCopy[currentIndex].selected = false
			switch (event.key) {
				case "ArrowUp":
					if (currentIndex === 0) {
						elementsCopy[items.length - 1].selected = true
						currentIndex = items.length - 1
					} else {
						elementsCopy[currentIndex - 1].selected = true
					}
					scrollRef.current.scrollTop = (currentIndex * 29.6) - 59.2
					break;
				case "ArrowDown":
					if (currentIndex === items.length - 1) {
						elementsCopy[0].selected = true
						currentIndex = 0
					} else {
						elementsCopy[currentIndex + 1].selected = true
					}
					scrollRef.current.scrollTop = (currentIndex * 29.6)
					break;
				default: break;
			}

			setItems(elementsCopy)
		}
	}
	// REACTSELECT LOGIC

	// TEMPLATE LOGIC
	async function toggleModalTemplate(bool) {
		if (bool) {
			const response = await GET(`/service/wms-transfer-pos-templates?wms_id=${data.from_wms_id}`, {}, { loader: true })
			if (httpOk(response)) {
				setModalTemplate({ ...modalTemplate, 'bool': bool, 'templates': response.data })
			}
		} else {
			setModalTemplate({ ...modalTemplate, 'bool': bool, 'templates': [] })
		}
	}

	function handleSelectTemplate(item) {
		var dataCopy = { ...data }
		var temporaryItems = []
		for (let i = 0; i < item?.items?.length; i++) {
			for (let j = 0; j < items.length; j++) {
				if (item?.items[i]['element_id'] === items[j]['id']) {
					temporaryItems.push(items[j])
				}
			}
		}

		dataCopy.items = temporaryItems
		setData(dataCopy)
		toggleModalTemplate(false)
	}

	async function deleteTemplate(item) {
		await DELETE(`/service/wms-transfer-pos-templates/${item.wms_transfer_pos_id}`, {}, { loader: true })
		toggleModalTemplate(false)
	}
	// TEMPLATE LOGIC

	async function toggleModalMarkup(bool) {
		if (bool) {
			var response = await GET(`/service/wms-transfer-pos-products`, {}, { loader: true })
			if (httpOk(response)) {
				setModalMarkup({ ...modalTemplate, 'bool': bool, 'products': response.data })
			}
		} else {
			setModalMarkup({ ...modalTemplate, 'bool': bool, 'products': [] })
		}
	}

	async function getPoses() {
		const response = await GET('/helper/integration-access-poses', {}, { loader: true })
		if (httpOk(response)) setPoses(response.data)
	}

	async function getWms() {
		const response = await GET(`/helper/wms`, {}, { loader: true })
		if (httpOk(response)) setWms(response.data)
	}

	async function getPos() {
		const response = await GET("/helper/pos")
		if (httpOk(response)) setPos(response.data)
	}

	async function getProducts(wms_id = reduxAccount.wms_id) {
		var response = await GET(`/service/wms-transfer-pos-items/${wms_id}`, {}, { loader: true })
		if (httpOk(response)) {
			setItems(response.data)
			setItemsDuplicate(response.data)

			var dataCopy = { ...data }
			dataCopy.from_wms_id = wms_id

			console.log(reduxTransferData?.items)
			if (reduxTransferData?.id) {
				var temporaryItems = []
				for (let i = 0; i < reduxTransferData?.items?.length; i++) {
					if (!reduxTransferData.items[i]['product_id']) {
						temporaryItems.push({
							'sale_price': '',
							'quantity': '',
							'id': ''
						})
					}

					for (let j = 0; j < response.data.length; j++) {
						if (reduxTransferData.items[i]['product_id'] === response.data[j]['product_id']) {
							console.log(response.data[j]['product_id'])
							response.data[j]['order_quantity'] = reduxTransferData.items[i]['quantity']
							response.data[j]['quantity'] = reduxTransferData.items[i]['quantity']
							temporaryItems.push(response.data[j])
						}
					}
				}
				dataCopy.document_order_id = reduxTransferData?.id
				dataCopy.to_pos_id = reduxTransferData?.external_pos_id
				dataCopy.items = temporaryItems
			}

			countTotalAmount(dataCopy)
			dispatch(SET_TRANSFER_DATA({}))
		}
	}

	async function getData() {
		const response = await GET(`/service/wms-transfer-pos/${id}`, {}, { loader: true })

		if (httpOk(response)) {
			countTotalAmount(response.data)
		}
	}

	useEffect(() => {
		getPos()
		getPoses()
		getWms()
		if (id) {
			getData()
		} else {
			getProducts()
		}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<div className="d-flex text-truncate">
					<h4 className="vertical-center">
						{t('transfer')}
					</h4>
					<div className="vertical-center mt-1">
						<i className="uil uil-angle-double-right fz-20"></i>
					</div>
					<div className="vertical-center mt-1">
						<h6>{t('create')}</h6>
					</div>
				</div>
				<DropdownButton title={t('action')}>
					<Dropdown.Item onClick={() => toggleModalMarkup(true)}>
						{t('markup')}
					</Dropdown.Item>
					{!id &&
						<Dropdown.Item onClick={() => toggleModalTemplate(true)}>
							{t('templates')}
						</Dropdown.Item>
					}
				</DropdownButton>
			</div>

			<form autoComplete="off">
				<div className="card mb-3">
					<div className="card-body">
						<div className="row">
							<div className="col-md-2">
								<div className="form-group">
									<label>{t('date')}<span className="required-mark">*</span></label>
									<div className="calendar-input">
										<DatePicker
											disabled={id}
											className="form-control"
											dateFormat="dd.MM.yyyy"
											selected={data.created_at ? new Date(data.created_at) : ''}
											onChange={(date) => setData({ ...data, 'created_at': formatDate(date, 'yyyy-MM-dd') })}
											minDate={returnMinDate(new Date(), reduxAccount.backdate ?? 3)}
											maxDate={new Date()} />
										<i className="uil uil-calendar-alt"></i>
									</div>
								</div>
							</div>
							<div className="col-md-2">
								<div className="form-group">
									<label>{t('from_warehouse')}<span className="required-mark">*</span></label>
									<Select
										isDisabled={id}
										options={wms}
										value={wms.find(option => option.id === data.from_wms_id)}
										onChange={(option) => handleSelectWms(option)}
										placeholder=""
										noOptionsMessage={() => t('list_empty')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>
								</div>
							</div>
							<div className="col-md-2">
								<div className="form-group">
									<label>{t('external_pos')}<span className="required-mark">*</span></label>
									<Select
										isDisabled={id}
										options={poses}
										value={poses.find(option => option.id === data.to_pos_id)}
										onChange={(option) => setData({ ...data, 'to_pos_id': option.id })}
										placeholder=""
										noOptionsMessage={() => t('list_empty')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>
								</div>
							</div>
							<div className="col-md-2">
								<div className="form-group">
									<label>{t('note')}</label>
									<input type="text" className="form-control" name="note"
										value={data.note ?? ''}
										onChange={(e) => setData({ ...data, 'note': e.target.value })} />
								</div>
							</div>
							<div className="col-md-1">
								<div className="form-group">
									<label>{t('order')} ID</label>
									<input type="number" className="form-control" name="document_order_id"
										disabled
										value={data.document_order_id ?? ''}
										onChange={(e) => setData({ ...data, 'document_order_id': e.target.value })} />
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="card">
					<div className="card-body">
						<div className="table-responsive">
							<table className="table table-bordered">
								<thead>
									<tr>
										<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
										<th style={{ 'width': '30%' }}>{t('name3')}</th>
										<th style={{ 'width': '10%' }} className="text-end">{t('ordered')}</th>
										<th style={{ 'width': '10%' }} className="text-end">{t('balance2')}</th>
										<th style={{ 'width': '10%' }} className="text-start">{t('unit')}</th>
										<th style={{ 'width': '10%' }} className="text-center">{t('quantity')}</th>
										<th style={{ 'width': '10%' }} className="text-end">{t('cost_price')}</th>
										<th style={{ 'width': '10%' }} className="text-end">{t('sale_price')}</th>
										<th style={{ 'width': '10%' }} className="text-end">{t('amount')}</th>
										<th style={{ 'minWidth': '33px' }}></th>
									</tr>
								</thead>
								<tbody>
									{data?.items?.map((item, index) => (
										<tr key={index}>
											<td className="text-center">{index + 1}</td>
											<td>
												<ReactSelect
													search={handleSelectSearch}
													value={item.name}
													index={index}
													scrollRef={scrollRef}
													options={items}
													onClose={() => closeReactSelect()}
													selectItem={(option) => handleSelectElement(option, index)}
													onKeyUp={(e) => {
														if (e.keyCode === 40 || e.keyCode === 38) {
															handleTableKeyDown(e)
														}
													}}
													table={
														<table className="table mb-0">
															<tbody ref={tbodyRef}>
																{items.map((selectItem, selectIndex) => (
																	<tr
																		id={selectItem.id}
																		className={`cursor ${((selectItem.selected || selectItem.product_id === item.element_id) && 'selected')}`}
																		tabIndex={-1}
																		key={selectIndex}
																		onClick={() => handleSelectElement(selectItem, index)}
																		onKeyDown={(e) => handleTableKeyDown(e, selectItem, index)}>
																		<td>{selectItem.name}</td>
																		<td>{selectItem.product_type_name}</td>
																		<td className="text-end text-nowrap">{formatMoney(selectItem.sale_price)}</td>
																	</tr>
																))}
															</tbody>
														</table>
													}
												/>
											</td>
											<td className="text-end vertical-middle">
												{formatMoney(item.order_quantity, 2)}
											</td>
											<td className="text-end vertical-middle">
												{formatMoney(item.balance, 2)}
											</td>
											<td className="text-start vertical-middle">
												{item.unit_name}
											</td>
											<td className="td-input" style={!item.quantity ? { 'borderBottom': '2px solid red' } : {}}>
												<input
													type="text"
													name="quantity"
													className="form-control text-end"
													placeholder="0"
													value={item.quantity ?? ''}
													onChange={(e) => handleInputChange(e, index)} />
											</td>
											<td className="text-end vertical-middle">
												{formatMoney(item.cost_price)}
											</td>
											<td className="td-input" style={!item.sale_price ? { 'borderBottom': '2px solid red' } : {}}>
												<input
													type="text"
													name="sale_price"
													className="form-control text-end"
													placeholder="0"
													value={item.sale_price ?? ''}
													onChange={(e) => handleInputChange(e, index)}
													onKeyUp={(event) => {
														if (event.key === 'Enter') { addToList() }
													}} />
											</td>
											<td className="vertical-middle text-end text-nowrap">
												{formatMoney(item.quantity * item.sale_price)}
											</td>
											<td className="vertical-middle">
												{!id &&
													<div className="d-flex justify-content-center">
														<div className="table-action-button table-action-danger-button"
															onClick={() => deleteItem(index)}>
															<i className="uil uil-times"></i>
														</div>
													</div>
												}
											</td>
										</tr>
									))}
								</tbody>
								<tfoot>
									<tr>
										<td className="td-input">
											<input
												type="text"
												className="form-control text-center"
												style={{ 'padding': '5px 0' }}
												placeholder="xxx"
												value={addQuantity}
												onChange={(e) => setAddQuantity(e.target.value.replace(/[^0-9.]/g, ''))}
											/>
										</td>
										<td colSpan={1} style={{ 'padding': 0 }}>
											<div className="d-flex">
												<div className="w-100">
													<button type="button" className="btn-sm btn-primary w-100"
														disabled={id}
														onClick={() => {
															setAddQuantity(1)
															addToList(addQuantity)
														}}>
														{t('add')}
													</button>
												</div>
											</div>
										</td>
										<td></td>
										<td></td>
										<td></td>
										<td className="text-end"><b>{formatMoney(data.total_quantity, 2)}</b></td>
										<td></td>
										<td></td>
										<td className="text-end"><b>{formatMoney(data.total_sale_price)}</b></td>
										<td></td>
									</tr>
								</tfoot>
							</table>
						</div>

						<div className="btn-wrapper gap-2">
							<button type="button" className="btn btn-warning btn-rounded btn-wide" tabIndex="-1"
								onClick={() => navigate(-1)}>
								{t('cancel')}
							</button>
							<button type="button" className="btn btn-primary btn-rounded btn-wide" tabIndex="-1"
								disabled={!(data.from_wms_id && data.to_pos_id && data.items.length)}
								onClick={() => createUpdate(1)}>
								{t('save')}
							</button>
						</div>
					</div>
				</div>
			</form >

			{/* MODAL CONFIRM */}
			<Modal Modal show={modalConfirm} animation={false} centered
				dialogClassName="update-modal-width" onHide={() => setModalConfirm(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('confirm')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="d-flex justify-content-between mb-1">
						<div>{t('organization')}</div>
						<div className="fw-700">{findKeyFromArrayByValue(poses, 'id', data.to_pos_id, 'name')}</div>
					</div>
					<div className="d-flex justify-content-between mb-1">
						<div>{t('total_debt')}</div>
						<div className="fw-700">{formatMoney(findKeyFromArrayByValue(poses, 'id', data.to_pos_id, 'balance'))}</div>
					</div>
					<div className="d-flex justify-content-between mb-1">
						<div>{t('total_amount')}</div>
						<div className="fw-700">{formatMoney(data.total_sale_price)}</div>
					</div>
					<div className="form-group">
						<label>{t('replenishment')}</label>
						<Select
							isClearable
							options={accountTypes}
							value={accountTypes.find(option => option.id === data.account_type)}
							onChange={(option) => setData({ ...data, 'account_type': option ? option.id : null })}
							placeholder=""
							noOptionsMessage={() => t('list_empty')}
							getOptionLabel={(option) => option.name}
							getOptionValue={(option) => option.id}
						/>
					</div>
					<div className="d-flex justify-content-between mb-1">
						<span>{t('safe')}</span>
						<b>{formatMoney(pos.safe_balance)}</b>
					</div>
					<div className="d-flex justify-content-between mb-1">
						<span>{t('bank_account')}</span>
						<b>{formatMoney(pos.bank_balance)}</b>
					</div>
					<div className="row">
						<div className="form-group col-md-6">
							<label>{t('payment_amount')}</label>
							<div className="position-relative">
								<input type="text" className="form-control" autoFocus
									value={data.organization_amount_in ? formatMoney(data.organization_amount_in) : ''}
									onChange={(e) => setData({ ...data, 'organization_amount_in': e.target.value.replace(/[^0-9.]/g, '') })} />
								<i className="input-inner-icon uil uil-transaction"
									onClick={() => setData({ ...data, 'organization_amount_in': data.total_sale_price, 'pay_debt_at': '' })}></i>
							</div>
						</div>
						<div className="form-group col-md-6">
							<label>{t('debt_payment_date')}</label>
							<div className="calendar-input">
								<DatePicker
									disabled={Number(data.organization_amount_in) === Number(data.total_sale_price)}
									className="form-control"
									dateFormat="dd.MM.yyyy"
									selected={data.pay_debt_at ? new Date(data.pay_debt_at) : ''}
									onChange={(date) => setData({ ...data, 'pay_debt_at': formatDate(date, 'yyyy-MM-dd') })}
									minDate={returnMinDate(new Date(), 0)} />
								<i className="uil uil-calendar-alt"></i>
							</div>
						</div>
					</div>
					<div className="d-flex gap-2 w-100">
						<button className="btn btn-warning w-100"
							onClick={() => setModalConfirm(false)}>
							{t('cancel')}
						</button>
						<button className="btn btn-primary w-100"
							onClick={() => createUpdate(3)}>
							{t('confirm')}
						</button>
					</div>
				</Modal.Body>
			</Modal>
			{/* MODAL CONFIRM */}

			{/* MODAL TEMPLATE */}
			<Modal show={modalTemplate.bool} animation={false} centered
				dialogClassName="update-modal-width" onHide={() => toggleModalTemplate(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('templates')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="table-responsive">
						<table className="table table-bordered table-hover cursor mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '100%' }}>{t('name2')}</th>
									<th style={{ 'minWidth': '33px' }}></th>
								</tr>
							</thead>
							<tbody>
								{modalTemplate?.templates?.map((item, index) => (
									<tr key={index}>
										<td className="text-center">{index + 1}</td>
										<td onClick={() => handleSelectTemplate(item)}>
											{item.name}
										</td>
										<td>
											<div className="d-flex justify-content-end">
												<div className="table-action-button table-action-danger-button"
													onClick={() => deleteTemplate(item)}>
													<i className="uil uil-times"></i>
												</div>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</Modal.Body>
			</Modal>
			{/* MODAL TEMPLATE */}

			{/* MODAL PRODUCTS MARKUP */}
			<Modal show={modalMarkup.bool} animation={false} centered size="xl"
				dialogClassName="update-modal-width" onHide={() => toggleModalMarkup(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('products')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="table-responsive" style={{ 'height': '500px', 'overflow': 'auto' }}>
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '30%' }}>{t('name2')}</th>
									<th className="text-nowrap">{t('unit')}</th>
									<th style={{ 'width': '20%' }} className="text-nowrap">{t('type')}</th>
									<th style={{ 'width': '20%' }} className="text-end">{t('cost_price')}</th>
									<th style={{ 'width': '20%' }} className="text-end">{t('markup')}%</th>
									<th style={{ 'width': '20%' }} className="text-end">{t('round')}</th>
								</tr>
							</thead>
							<tbody>
								{modalMarkup?.products?.map((item, index) => (
									<tr key={index}>
										<td className="text-center">{index + 1}</td>
										<td className="td-ellipsis">{item.name}</td>
										<td>{item.unit_name}</td>
										<td>{item.product_type_name}</td>
										<td className="text-end">{formatMoney(item.cost_price)}</td>
										<td className="td-input">
											<input
												autoComplete="off"
												type="text"
												name="markup"
												className="form-control text-end"
												placeholder="0"
												value={item.markup ?? ''}
												onChange={(e) => handleChangeMarkup(e, index)} />
										</td>
										<td className="td-input">
											<input
												autoComplete="off"
												type="text"
												name="markup_round"
												className="form-control text-end"
												placeholder="0"
												value={item.markup_round ?? ''}
												onChange={(e) => handleChangeMarkup(e, index)} />
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					<div className="btn-wrapper gap-2">
						<button type="button" className="btn btn-warning btn-rounded btn-wide" tabIndex="-1"
							onClick={() => toggleModalMarkup(false)}>
							{t('cancel')}
						</button>
						<button type="button" className="btn btn-primary btn-rounded btn-wide" tabIndex="-1"
							onClick={() => updateMarkup()}>
							{t('save')}
						</button>
					</div>
				</Modal.Body>
			</Modal>
			{/* MODAL PRODUCTS MARKUP */}
		</>
	)
}

export default CreateUpdate