import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { Dropdown } from 'react-bootstrap'
import DatePicker from "react-datepicker"

import { formatMoney, formatDate } from "helpers/helpers"
import { GET, httpOk } from 'helpers/api'

function Index() {
	const { t } = useTranslation()
	const date = new Date()

	const [data, setData] = useState({})
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterData, setFilterData] = useState({
		start_date: formatDate(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd'),
		end_date: formatDate(new Date(), 'yyyy-MM-dd'),
		is_debt: false,
	})

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function getPaymentHistory() {
		const response = await GET('/admin/payment-history', filterData)
		if (httpOk(response)) {
			setData(response.data)
		}
	}

	useEffect(() => {
		getPaymentHistory()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<div className="vertical-center">
					<h4>
						{t('payment_history')}
					</h4>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex justify-content-between align-items">
						<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen} className="h-fit-content">
							<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100">
								{t('filter')}
							</Dropdown.Toggle>

							<Dropdown.Menu className="dropdown-filter">
								<div className="row">
									<div className="form-group col-md-6">
										<label>{t('period')}</label>
										<div className="calendar-input">
											<DatePicker
												className="form-control"
												dateFormat="dd.MM.yyyy"
												selected={filterData.start_date ? new Date(filterData.start_date) : ''}
												onChange={(date) => setFilterData({ ...filterData, 'start_date': formatDate(date, 'yyyy-MM-dd') })} />
											<i className="uil uil-calendar-alt"></i>
										</div>
									</div>
									<div className="form-group col-md-6">
										<label className="invisible">.</label>
										<div className="calendar-input">
											<DatePicker
												className="form-control"
												dateFormat="dd.MM.yyyy"
												selected={filterData.end_date ? new Date(filterData.end_date) : ''}
												onChange={(date) => setFilterData({ ...filterData, 'end_date': formatDate(date, 'yyyy-MM-dd') })} />
											<i className="uil uil-calendar-alt"></i>
										</div>
									</div>
								</div>
								<div className="d-flex justify-content-between mb-2">
									<p className="fw-600 vertical-center">Показать не оплаченные точки</p>
									<div className="form-check form-switch form-switch-lg">
										<input type="checkbox" className="form-check-input" checked={filterData?.is_debt}
											onChange={(e) => setFilterData({ ...filterData, 'is_debt': !filterData.is_debt })} />
									</div>
								</div>
								<div className="row mt-3">
									<div className="col-md-12">
										<div className="d-flex justify-content-end">
											<button className="btn btn-secondary me-2" onClick={() => setDropdownIsOpen(false)}>{t('cancel')}</button>
											<button className="btn btn-primary" onClick={() => getPaymentHistory()}>{t('apply')}</button>
										</div>
									</div>
								</div>
							</Dropdown.Menu>
						</Dropdown>
						<div className="vertical-center">
							<h5 className="d-flex justify-content-between">
								<b>{t('total_amount')}:</b> {formatMoney(data.total_amount)}
							</h5>
							<h5 className="d-flex justify-content-between text-success">
								<b>{t('total_amount_payment')}:</b> {formatMoney(data.total_amount_payment)}
							</h5>
							<h5 className="d-flex justify-content-between text-danger">
								<b>{t('total_amount_debt')}:</b> {formatMoney(data.total_amount_debt)}
							</h5>
						</div>
					</div>
					<div className="table-responsive">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th>{t('login')}</th>
									<th className="text-center">{t('tariff')}</th>
									<th className="text-center">{t('amount')}</th>
									<th className="text-center">{t('date')}</th>
								</tr>
							</thead>
							<tbody>
								{data?.data?.map((item, index) => (
									<tr key={index}>
										<td>{item.pos_id}. {item.login}</td>
										<td className="text-center">{formatMoney(item.tariff)}</td>
										<td className="text-center">{formatMoney(item.amount)}</td>
										<td className="text-center">{formatDate(item.created_at)}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</>
	)
}

export default Index