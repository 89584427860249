import React, { useEffect, useState, useRef } from "react"
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import DatePicker from "react-datepicker"
import { Modal } from 'react-bootstrap'

import ReactSelect from 'pages/parts/ReactSelect'
import { GET, PUT, httpOk } from "helpers/api"
import { formatMoney, formatDate, returnMinDate, findKeyFromArrayByValue } from 'helpers/helpers'

function Preview() {
	const { t } = useTranslation()
	const { id } = useParams()
	const navigate = useNavigate()

	const tbodyRef = useRef()
	const scrollRef = useRef()

	const accountTypes = [
		{ 'id': 1, 'name': t('safe') },
		{ 'id': 2, 'name': t('bank_account') },
	]

	const [data, setData] = useState({})
	const [elements, setElements] = useState([])
	const [elementsDuplicate, setElementsDuplicate] = useState([])
	const [modalConfirm, setModalConfirm] = useState(false)

	async function update(stage = 1) {
		if (stage === 1) {
			setModalConfirm(true)
			return
		}

		var dataCopy = { ...data }

		const response = await PUT('/service/documents-in', dataCopy, { loader: true })
		if (httpOk(response)) navigate('/documents-in')
	}

	// REACTSELECT LOGIC
	function handleSelectSearch(value) {
		if (!value) {
			setElements([...elementsDuplicate])
			return
		}
		var elementsDuplicateCopy = [...elementsDuplicate]

		var filteredItems = elementsDuplicateCopy.filter(item => item.name.toLowerCase().includes(value.toLowerCase()))
		if (filteredItems[0]) {
			filteredItems[0]['selected'] = true
		}
		setElements(filteredItems)
	}

	function handleSelectElement(option, index) {
		var dataCopy = { ...data }
		dataCopy.items[index]['element_id'] = option.id
		setData(dataCopy)
	}

	function closeReactSelect() {
		setTimeout(() => {
			var elementsCopy = [...elements]
			for (let i = 0; i < elementsCopy.length; i++) {
				elementsCopy[i]['selected'] = false
			}
			if (elementsCopy[0] && elementsCopy[0]['selected'])
				elementsCopy[0]['selected'] = true
			setElements(elementsCopy)
		}, 100);
	}

	function handleTableKeyDown(event) {
		event.stopPropagation();
		var currentIndex = elements.findIndex(el => el.selected);
		if (currentIndex >= 0) {
			var elementsCopy = [...elements]
			elementsCopy[currentIndex].selected = false
			switch (event.key) {
				case "ArrowUp":
					if (currentIndex === 0) {
						elementsCopy[elements.length - 1].selected = true
						currentIndex = elements.length - 1
					} else {
						elementsCopy[currentIndex - 1].selected = true
					}
					scrollRef.current.scrollTop = (currentIndex * 29.6) - 59.2
					break;
				case "ArrowDown":
					if (currentIndex === elements.length - 1) {
						elementsCopy[0].selected = true
						currentIndex = 0
					} else {
						elementsCopy[currentIndex + 1].selected = true
					}
					scrollRef.current.scrollTop = (currentIndex * 29.6)
					break;
				default: break;
			}

			setElements(elementsCopy)
		}
	}
	// REACTSELECT LOGIC

	async function getData() {
		const response = await GET(`/service/documents-in/${id}`, {}, { loader: true })
		if (httpOk(response)) setData(response.data)
	}

	async function getElements() {
		const response = await GET(`/helper/elements`, {}, { loader: true })
		if (httpOk(response)) {
			setElements(response.data)
			setElementsDuplicate(response.data)
		}
	}

	useEffect(() => {
		getData()
		getElements()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<div className="d-flex text-truncate">
					<h4 className="vertical-center">
						{t('receipt_from_supplier')}
					</h4>
					<div className="vertical-center mt-1">
						<i className="uil uil-angle-double-right fz-20"></i>
					</div>
					<div className="vertical-center mt-1">
						<h6>{t('preview')}</h6>
					</div>
				</div>
			</div>

			<div className="card mb-3">
				<div className="card-body">
					<h4 className="fw-600">{t('document_from')} {formatDate(data.created_at)}</h4>
					<div className="flex-column preview-info">
						<div className="d-flex gap-2">
							<div className="fw-600">{t('pos')}:</div>
							<div>{data.pos_name}</div>
						</div>
						<div className="d-flex gap-2">
							<div className="fw-600">{t('supplier')}:</div>
							<div>{data.supplier_name}</div>
						</div>
						<div className="d-flex gap-2">
							<div className="fw-600">{t('document')}:</div>
							<div>{data.document_number}</div>
						</div>
						<div className="d-flex gap-2">
							<div className="fw-600">{t('note')}:</div>
							<div>{data.note}</div>
						</div>
					</div>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="table-responsive">
						<table className="table table-bordered">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '30%' }}>{t('external_name')}</th>
									<th style={{ 'width': '30%' }}>{t('element')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('quantity')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('price')}</th>
									<th style={{ 'width': '20%' }} className="text-end">{t('amount')}</th>
								</tr>
							</thead>
							<tbody>
								{data?.items?.map((item, index) => (
									<tr key={index}>
										<td className="text-center">{index + 1}</td>
										<td>{item.item_name}</td>
										<td>
											<ReactSelect
												search={handleSelectSearch}
												value={findKeyFromArrayByValue(elementsDuplicate, 'id', item.element_id, 'name')}
												index={index}
												scrollRef={scrollRef}
												options={elements}
												onClose={() => closeReactSelect()}
												selectItem={(option) => handleSelectElement(option, index)}
												onKeyUp={(e) => {
													if (e.keyCode === 40 || e.keyCode === 38) {
														handleTableKeyDown(e)
													}
												}}
												table={
													<table className="table mb-0">
														<tbody ref={tbodyRef}>
															{elements.map((selectItem, selectIndex) => (
																<tr
																	id={selectItem.id}
																	className={`cursor ${((selectItem.selected || selectItem.id === item.element_id) && 'selected')}`}
																	tabIndex={-1}
																	key={selectIndex}
																	onClick={() => handleSelectElement(selectItem, index)}
																	onKeyDown={(e) => handleTableKeyDown(e, selectItem, index)}>
																	<td>{selectItem.name}</td>
																</tr>
															))}
														</tbody>
													</table>
												}
											/>
										</td>
										<td className="text-end">{item.quantity}</td>
										<td className="text-end">{formatMoney(item.sale_price)}</td>
										<td className="text-end">{formatMoney(item.sale_price * item.quantity)}</td>
									</tr>
								))}
								<tr>
									<td></td>
									<td><b>{t('total')}</b></td>
									<td></td>
									<td className="text-end text-nowrap">
										<b>{formatMoney(data?.overall?.overall_quantity)}</b>
									</td>
									<td></td>
									<td className="text-end text-nowrap">
										<b>{formatMoney(data?.overall?.overall_amount)}</b>
									</td>
								</tr>
							</tbody>
						</table>
					</div>

					<div className="d-flex justify-content-end gap-2">
						<button type="button" className="btn btn-warning btn-rounded btn-wide"
							onClick={() => navigate(-1)}>
							{t('back')}
						</button>
						{data.status === -2 &&
							<button type="button" className="btn btn-primary btn-rounded btn-wide" tabIndex="-1"
								onClick={() => update(1)}>
								{t('accept')}
							</button>
						}
					</div>
				</div>
			</div>

			{/* MODAL confirm */}
			<Modal show={modalConfirm} animation={false} centered
				dialogClassName="update-modal-width" onHide={() => setModalConfirm(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('confirm')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="d-flex justify-content-between mb-2">
						<div>{t('supplier')}</div>
						<div className="fw-700">{data.supplier_name}</div>
					</div>
					<div className="d-flex justify-content-between mb-2">
						<div>{t('total_debt')}</div>
						<div className="fw-700">{formatMoney(data.balance)}</div>
					</div>
					<div className="d-flex justify-content-between mb-2">
						<div>{t('total_amount')}</div>
						<div className="fw-700">{formatMoney(data.total_sale_price)}</div>
					</div>
					<div className="row">
						<div className="form-group col-md-6">
							<label>{t('supplier_payment_amount')}</label>
							<div className="position-relative">
								<input type="text" className="form-control" autoFocus
									value={data.supplier_amount_in ? formatMoney(data.supplier_amount_in) : ''}
									onChange={(e) => setData({ ...data, 'supplier_amount_in': e.target.value.replace(/[^0-9.]/g, '') })} />
								<i className="input-inner-icon uil uil-transaction"
									onClick={() => setData({ ...data, 'supplier_amount_in': data.total_sale_price })}></i>
							</div>
						</div>
						<div className="form-group col-md-6">
							<label>{t('debt_payment_date')}</label>
							<div className="calendar-input">
								<DatePicker
									disabled={Number(data.supplier_amount_in) === Number(data.total_amount)}
									className="form-control"
									dateFormat="dd.MM.yyyy"
									selected={data.pay_debt_at ? new Date(data.pay_debt_at) : ''}
									onChange={(date) => setData({ ...data, 'pay_debt_at': formatDate(date, 'yyyy-MM-dd') })}
									minDate={returnMinDate(new Date(), 0)} />
								<i className="uil uil-calendar-alt"></i>
							</div>
						</div>
					</div>
					<div className="form-group">
						<label>{t('payment_method')}</label>
						<Select
							isClearable
							options={accountTypes}
							value={accountTypes.find(option => option.id === data.account_type)}
							onChange={(option) => setData({ ...data, 'account_type': option ? option.id : null })}
							placeholder=""
							noOptionsMessage={() => t('list_empty')}
							getOptionLabel={(option) => option.name}
							getOptionValue={(option) => option.id}
						/>
					</div>
					<div className="d-flex gap-2 w-100">
						<button className="btn btn-warning w-100"
							onClick={() => setModalConfirm(false)}>
							{t('cancel')}
						</button>
						<button className="btn btn-primary w-100"
							onClick={() => update(3)}>
							{t('confirm')}
						</button>
					</div>
				</Modal.Body>
			</Modal>
			{/* MODAL confirm */}
		</>
	)
}

export default Preview