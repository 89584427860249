import React, { useState } from 'react'
import Orders from './Orders'
import OrderCreate from './OrderCreate'
//import ChequesRating from '../cheques/Rating'

function Index() {
	const [activeComponent, setActiveComponent] = useState('orders');

	function handleOrderType() {
		setActiveComponent('ordersCreate')
	}

	return (
		<>
			<div className={activeComponent === 'orders' ? '' : 'd-none'}>
				<Orders
					handleOrderType={handleOrderType}>
				</Orders>
			</div>
			{activeComponent === 'ordersCreate' &&
				<OrderCreate
					setActiveComponent={setActiveComponent}>
				</OrderCreate>
			}
		</>
	)
}

export default Index