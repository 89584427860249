import React, { useEffect, useState } from "react"
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Modal } from 'react-bootstrap'
import Select from 'react-select'

import { GET, POST, PUT, httpOk } from 'helpers/api'
import { formatMoney, formatDate } from 'helpers/helpers'
import imgCashbox from 'assets/images/cashbox.png'

function Index() {
	const { t } = useTranslation()

	const [data, setData] = useState([])
	const [selectedItem, setSelectedItem] = useState({})
	const [poses, setPoses] = useState([])
	const [showModal, setShowModal] = useState(false)

	async function createUpdate(e) {
		e.preventDefault()

		var response;
		if (selectedItem.id) {
			response = await PUT('/cashbox', selectedItem, { loader: true })
		} else {
			response = await POST('/cashbox', selectedItem, { loader: true })
		}

		if (httpOk(response)) {
			getData()
			toggleModal(false)
		}
	}

	function toggleModal(bool, item = {}) {
		setShowModal(bool)
		setSelectedItem(item)
	}

	async function getData() {
		const response = await GET('/cashbox', {}, { loader: true })
		if (httpOk(response)) {
			setData(response.data)
		}
	}

	async function getPoses() {
		const response = await GET('/helper/poses')
		if (httpOk(response)) {
			setPoses(response.data)
		}
	}

	useEffect(() => {
		getData()
		getPoses()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<h4 className="vertical-center">
					{t('cashboxes')}
				</h4>
				<div className="d-flex">
					<button className="btn-rounded btn btn-primary"
						onClick={() => toggleModal(true)}>
						{t('create')}
					</button>
				</div>
			</div>

			<div className="row">
				{data?.map((item, index) => (
					<div className="col-md-4 mb-3" key={index}>
						<div className="card">
							<div className="card-body">
								<button type="button" className="table-action-button table-action-primary-button position-absolute"
									style={{ 'right': '17px' }}
									onClick={() => toggleModal(true, item)}>
									<i className="uil-edit-alt"></i>
								</button>
								<div className="d-flex justify-content-center mb-2">
									<img width="100" height="100"
										src={imgCashbox}
										alt="react" />
								</div>
								<div className="d-flex justify-content-between">
									<div className="fw-600">{t('cashbox')}:</div>
									<div className="text-end">
										{item.name}
										{(item.shift && item?.shift?.closed_at == null) &&
											<span>({t('open')})</span>
										}
										{item?.shift?.closed_at &&
											<span>({t('closed')})</span>
										}
									</div>
								</div>
								<div className="d-flex justify-content-between">
									<div className="fw-600">{t('pos')}:</div>
									<div className="text-end">{item?.pos_name}</div>
								</div>
								{item?.shift?.opened_at ?
									<div className="d-flex justify-content-between">
										<div className="fw-600">{t('shift_opening_date')}:</div>
										<div className="text-truncate">{formatDate(item?.shift?.opened_at)}</div>
									</div>
									:
									<div className="d-flex justify-content-between">
										<div className="fw-600">{t('shift_opening_date')}:</div>
										<div className="text-truncate">{formatDate(item?.shift?.closed_at)}</div>
									</div>
								}
								<div className="d-flex justify-content-between">
									<div className="fw-600">{t('cashier')}:</div>
									<div>{item?.shift?.cashier_login}</div>
								</div>
								<div className="d-flex justify-content-between mb-2">
									<div className="fw-600">{t('balance')}:</div>
									<div className="text-truncate">
										{formatMoney(item?.shift?.balance)}
									</div>
								</div>
								<div className="d-flex">
									<Link to={'/cashbox/shift/' + item.id} className="btn btn-primary w-100">{t('shifts')}</Link>
								</div>
							</div>
						</div>
					</div>
				))
				}
			</div>

			{/* MODAL */}
			<Modal show={showModal} animation={false}
				centered dialogClassName="update-modal-width"
				backdrop="static" onHide={() => toggleModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						{selectedItem.id ?
							<>{t('create')}</>
							:
							<>{t('edit')}</>
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={createUpdate} autoComplete="off" >
						<div className="form-group">
							<label>{t('cashbox_name')}<span className="required-mark">*</span></label>
							<input type="text" className="form-control" name="name"
								value={selectedItem.name ?? ''}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'name': e.target.value })} />
						</div>
						<div className="form-group">
							<label>{t('telegram_group_id')}</label>
							<input type="text" className="form-control" name="telegram_group_id"
								value={selectedItem.telegram_group_id ?? ''}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'telegram_group_id': e.target.value })} />
						</div>
						<div className="form-group">
							<label>{t('pos')}<span className="required-mark">*</span></label>
							<Select
								options={poses}
								value={poses.find(option => option.id === selectedItem.pos_id)}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'pos_id': option.id })}
								placeholder=""
								noOptionsMessage={() => t('list_empty')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						<div className="d-flex gap-2 w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100"
								onClick={() => toggleModal(false)}>
								{t('cancel')}
							</button>
							<button type="submit" className="btn btn-primary w-100">{t('save')}</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL */}
		</>
	)
}

export default Index
