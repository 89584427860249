import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Modal, Dropdown } from 'react-bootstrap'
import Select from 'react-select'
import QRCode from "qrcode.react";

import FilterSearch from 'pages/parts/FilterSearch'
import FilterPagination from 'pages/parts/FilterPagination'

import DeleteModal from 'pages/parts/DeleteModal'
import { returnSign, findKeyFromArrayByValue } from "helpers/helpers";
import { GET, POST, PUT, DELETE, httpOk } from 'helpers/api'

function Index() {
	const { t } = useTranslation()

	const reduxAccount = useSelector(state => state.account)

	const [selectedItem, setSelectedItem] = useState({})
	const [modalDelete, setModalDelete] = useState(false)
	const [deleteModalParams, setDeleteModalParams] = useState({ id: 0, index: 0 })
	const [halls, setHalls] = useState([])
	const [poses, setPoses] = useState([])
	const [showModal, setShowModal] = useState(false)
	const [showLoopModal, setShowLoopModal] = useState(false)
	const [loopData, setlLoopData] = useState({
		'number_start': '',
		'number_end': '',
		'hall_id': '',
		'suffix': '',
	})
	const [showQrModal, setShowQrModal] = useState(false)
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		pos_id: reduxAccount.pos_id,
		random: 0,
		page: '',
		search: '',
	})
	const [pagination, setPagination] = useState({
		url: '/tables',
		response: null
	})
	const [data, setData] = useState({})

	async function searchWithFilter() {
		var filterUrl = "/tables"
		var urlParams = ""
		checkFilter()

		if (filterData.pos_id)
			urlParams += returnSign(urlParams) + 'pos_id=' + filterData.pos_id
		if (filterData.search)
			urlParams += returnSign(urlParams) + 'search=' + filterData.search
		if (filterData.page)
			urlParams += returnSign(urlParams) + 'page=' + filterData.page

		if (`/tables${urlParams}` !== filterUrl)
			setFilterDataExist(true)

		filterUrl = filterUrl + urlParams

		const response = await GET(filterUrl, {}, { loader: true })
		const responseHalls = await GET(`/helper/halls${urlParams}`, {}, { loader: true })
		if (httpOk(response) && httpOk(responseHalls)) {
			setHalls(responseHalls.data)
			setData(response.data)
			setPagination({ ...pagination, 'url': filterUrl, 'response': response.data })
		}

		setDropdownIsOpen(false)
	}

	function resetFilter() {
		setFilterData({
			pos_id: null,
			page: '',
			search: '',
			random: filterData.random + 1,
		})
		setFilterDataExist(false)
	}

	function checkFilter() {
		if (
			filterData.pos_id === null &&
			filterData.random === null &&
			filterData.page === '' &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function paginate(page) {
		setFilterData({ ...filterData, 'page': page })
	}

	async function createUpdate(e) {
		e.preventDefault();

		var response;
		if (selectedItem.id) {
			response = await PUT('/tables', selectedItem)
		} else {
			response = await POST('/tables', selectedItem)
		}

		if (httpOk(response)) {
			searchWithFilter()
			setShowModal(false)
		}
	}

	function createUpdateModal(bool, item) {
		if (bool) {
			setSelectedItem(item)
			setShowModal(true)
		} else {
			setSelectedItem({})
			setShowModal(false)
		}
	}

	function qrModal(bool, item) {
		if (bool) {
			setSelectedItem(item)
			setShowQrModal(true)
		} else {
			setShowQrModal(false)
		}
	}

	async function deleteItem() {
		const response = await DELETE('/tables', { 'id': deleteModalParams.id }, { loader: true })
		if (httpOk(response)) {
			searchWithFilter()
			setModalDelete(false)
		}
	}

	async function createLoop(e) {
		e.preventDefault();
		await POST('/tables-several', loopData, { loader: true })

		setShowLoopModal(false)
		searchWithFilter()
	}

	function downloadQRCode() {
		const canvas = document.getElementById("qr-gen");
		const pngUrl = canvas
			.toDataURL("image/png")
			.replace("image/png", "image/octet-stream");
		let downloadLink = document.createElement("a");
		downloadLink.href = pngUrl;
		downloadLink.download = 'qr.png';
		document.body.appendChild(downloadLink);
		downloadLink.click();
		document.body.removeChild(downloadLink);
	}

	async function getPoses() {
		const response = await GET('/helper/poses')
		if (httpOk(response)) {
			setPoses(response.data)
			if (response.data.length !== 0) {
				setSelectedItem({ ...selectedItem, 'pos_id': response.data[0]['id'] })
			}
		}
	}

	useEffect(() => {
		getPoses()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		searchWithFilter()
	}, [filterData.search, filterData.page, filterData.random]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<h4 className="vertical-center">
					{t('tables')} [{data.total}]
				</h4>
				<div className="d-flex">
					<button className="btn-rounded btn btn-primary me-3" onClick={() => setShowLoopModal(true)}>
						<i className="uil uil-plus"></i>
						{t('create_several')}
					</button>
					<button className="btn-rounded btn btn-primary" onClick={() => setShowModal(true)}>
						{t('create')}
					</button>
				</div>
			</div>

			<div className="card">
				<div className="card-body">

					<div className="d-flex flex-wrap mb-3">
						<div className="d-flex flex-wrap gap-2">
							<div>
								<FilterSearch
									filterData={filterData}
									setFilterData={setFilterData}>
								</FilterSearch>
							</div>
							<div>
								<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100">
										{t('filter')}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-filter">
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('pos')}</label>
												<Select
													options={poses}
													value={poses.find(option => option.id === filterData.pos_id) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'pos_id': option.id })}
													placeholder=""
													noOptionsMessage={() => t('list_empty')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex justify-content-end">
													<button className="btn btn-secondary me-2"
														onClick={() => setDropdownIsOpen(false)}>
														{t('cancel')}
													</button>
													<button className="btn btn-primary"
														onClick={() => searchWithFilter()}>
														{t('apply')}
													</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('filter')}</strong>
								<br />
								{filterData.pos_id &&
									<span className="me-2">
										<b>{t('pos')}: </b>
										{findKeyFromArrayByValue(poses, 'id', filterData.pos_id, 'name')}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('search')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>{t('reset')}</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '20%' }}>{t('pos')}</th>
									<th style={{ 'width': '10%' }}>{t('hall')}</th>
									<th style={{ 'width': '10%' }}>{t('name3')}</th>
									<th style={{ 'width': '5%' }}>{t('service')}</th>
									<th style={{ 'width': '55%' }}></th>
									<th style={{ 'minWidth': '33px' }}></th>
								</tr>
							</thead>
							<tbody>
								{data?.data?.map((item, index) => (
									<tr key={index}>
										<td className="text-center">{item.row_number}</td>
										<td>{item.pos_name}</td>
										<td>{item.hall_name}</td>
										<td>{item.name}</td>
										<td>{item.service_percentage}%</td>
										<td></td>
										<td>
											<div className="table-dropdown">
												<Dropdown id={`dropdown-${index}`}>
													<Dropdown.Toggle variant="outline-primary">
														<i className="uil uil-ellipsis-v"></i>
													</Dropdown.Toggle>

													<Dropdown.Menu>
														<div className="table-dropdown-item"
															onClick={() => createUpdateModal(true, item)}>
															<i className="uil-edit-alt"></i>
															{t('edit')}
														</div>
														<div className="table-dropdown-item"
															onClick={() => qrModal(true, item)}>
															<i className="uil-qrcode-scan"></i>
															QR
														</div>
														{item.status > 0 &&
															<div className="table-dropdown-item text-danger"
																onClick={() => {
																	setDeleteModalParams({ id: item.id, index: index });
																	setModalDelete(true)
																}}>
																<i className="uil-times"></i>
																{t('delete')}
															</div>
														}
													</Dropdown.Menu>
												</Dropdown>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					{pagination.response &&
						<FilterPagination
							pagination={pagination}
							paginate={paginate}>
						</FilterPagination>
					}
				</div>
			</div>

			{/* MODAL create UPDATE */}
			<Modal show={showModal} animation={false} centered
				dialogClassName="update-modal-width" onHide={() => createUpdateModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						{!selectedItem.id ?
							<>{t('create')}</>
							:
							<>{t('edit')}</>
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={(e) => createUpdate(e)} autoComplete="off">
						<div className="form-group">
							<label>№ {t('table')}<span className="required-mark">*</span></label>
							<input type="text" className="form-control" name="name"
								value={selectedItem.name}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'name': e.target.value })} />
						</div>
						<div className="form-group">
							<label>{t('percent_service')}<span className="required-mark">*</span></label>
							<input type="text" className="form-control" name="service_percentage"
								value={selectedItem.service_percentage}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'service_percentage': e.target.value })} />
						</div>
						<div className="form-group">
							<label>{t('hall')}<span className="required-mark">*</span></label>
							<Select
								options={halls}
								value={halls.find(option => option.id === selectedItem.hall_id)}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'hall_id': option.id, 'pos_id': option.pos_id })}
								placeholder=""
								noOptionsMessage={() => t('list_empty')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						<div className="d-flex w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100 me-2"
								onClick={() => createUpdateModal(false)}>
								{t('cancel')}
							</button>
							<button type="submit" className="btn btn-primary w-100">{t('save')}</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL create UPDATE */}

			{/* MODAL LOOP */}
			<Modal show={showLoopModal} animation={false} centered
				dialogClassName="update-modal-width" onHide={() => setShowLoopModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						{t('create')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={(e) => createLoop(e)} autoComplete="off">
						<div className="form-group">
							<label>{t('from')}<span className="required-mark">*</span></label>
							<input type="number" className="form-control" name="number_start"
								onChange={(e) => setlLoopData({ ...loopData, 'number_start': e.target.value })} />
						</div>
						<div className="form-group">
							<label>{t('to')}<span className="required-mark">*</span></label>
							<input type="number" className="form-control" name="number_end"
								onChange={(e) => setlLoopData({ ...loopData, 'number_end': e.target.value })} />
						</div>
						<div className="form-group">
							<label>{t('percent_service')}<span className="required-mark">*</span></label>
							<input type="text" className="form-control" name="service_percentage"
								onChange={(e) => setlLoopData({ ...loopData, 'service_percentage': e.target.value })} />
						</div>
						<div className="form-group">
							<label>{t('hall')}<span className="required-mark">*</span></label>
							<Select
								options={halls}
								value={halls.find(option => option.id === loopData.hall_id)}
								onChange={(option) => setlLoopData({ ...loopData, 'hall_id': option.id, 'pos_id': option.pos_id })}
								placeholder=""
								noOptionsMessage={() => t('list_empty')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						<div className="d-flex w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100 me-2" onClick={() => setShowLoopModal(false)}>{t('cancel')}</button>
							<button type="submit" className="btn btn-primary w-100"
								disabled={!loopData.number_start || !loopData.number_end || !loopData.hall_id}>{t('save')}</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL LOOP */}

			{/* QR MODAL */}
			<Modal show={showQrModal} animation={false} centered
				dialogClassName="update-modal-width" backdrop="static" onHide={() => qrModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						QR
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="form-group">
						<div className="mt-3 fz-18 text-center">
							{`${'https://service.mison.uz/menu/'}${selectedItem.pos_id}?table_id=${selectedItem.id}`}
						</div>
						<div className="d-flex justify-content-center">
							<QRCode
								id="qr-gen"
								value={`${'https://service.mison.uz/menu/'}${selectedItem.pos_id}?table_id=${selectedItem.id}`}
								size={300}
								level={"H"}
								includeMargin={true}
							/>
						</div>
					</div>
					<div className="d-flex w-100 mt-3">
						<button type="button" className="btn btn-primary w-100" onClick={() => downloadQRCode()}>
							{t('download')}
						</button>
					</div>
				</Modal.Body>
			</Modal>
			{/* QR MODAL */}

			<DeleteModal
				modalDelete={modalDelete}
				setModalDelete={setModalDelete}
				deleteItem={deleteItem}>
			</DeleteModal>
		</>
	)
}

export default Index