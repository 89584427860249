import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Modal } from 'react-bootstrap'
import DatePicker from "react-datepicker"
import Select from 'react-select'
import XLSX from 'xlsx';

import FilterSearch from 'pages/parts/FilterSearch'
import FilterPagination from 'pages/parts/FilterPagination'

import { GET, POST, httpOk } from 'helpers/api'
import {
	formatMoney, regexPhoneNumber, returnSign,
	formatDate, returnMinDate
} from 'helpers/helpers'
import { toast } from "react-toastify"
import CustomTooltip from "pages/parts/CustomTooltip"

function Index() {
	const { t } = useTranslation()
	const reduxAccount = useSelector(state => state.account)

	const accountTypes = [
		{ 'id': 1, 'name': t('safe') },
		{ 'id': 2, 'name': t('bank_account') },
	]

	const [data, setData] = useState([])
	const [modalPayment, setModalPayment] = useState(false)
	const [selectedItem, setSelectedItem] = useState({})
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		search: '',
	})
	const [pagination, setPagination] = useState({
		url: '/report-organizations',
		response: null
	})

	async function payment(e) {
		e.preventDefault();

		var response = await POST('/organizations-payment', selectedItem, { loader: true })

		if (httpOk(response)) {
			toggleModalPayment(false)
			searchWithFilter()
		}
	}

	function selectItem(item) {
		var itemCopy = { ...item }
		itemCopy.account_type = 1
		itemCopy.amount = ''
		itemCopy.created_at = formatDate(new Date(), 'yyyy-MM-dd HH:mm')
		setSelectedItem(itemCopy)
	}

	async function searchWithFilter(exportToExcel = false) {
		var filterUrl = "/report-organizations"
		var urlParams = ""
		checkFilter()

		if (filterData.search)
			urlParams += returnSign(urlParams) + 'search=' + filterData.search
		if (filterData.page)
			urlParams += returnSign(urlParams) + 'page=' + filterData.page

		if (`/report-organizations${urlParams}` !== filterUrl && !exportToExcel)
			setFilterDataExist(true)

		filterUrl = filterUrl + urlParams

		if (exportToExcel) {
			filterUrl = "/report-organizations-excel" + urlParams + returnSign(filterUrl)

			var excelHeaders = [[
				t('pos'), t('cashier'), t('menu'), t('name2'), t('quantity'),
				t('sale_price'), t('date'), t('total_amount')
			]]
			var response = await GET(filterUrl)
			if (httpOk(response)) {
				response = response.data
				for (let i = 0; i < response.length; i++) {
					var excelItems = []
					excelItems.push(response[i]['pos_name'])
					excelItems.push(response[i]['first_name'])
					excelItems.push(response[i]['menu_name'])
					excelItems.push(response[i]['product_name'])
					excelItems.push(response[i]['quantity'])
					excelItems.push(response[i]['sale_price'])
					excelItems.push(formatDate(response[i]['cheque_closed_at'], 'dd.MM.yy HH:mm'))
					excelItems.push(response[i]['sale_price'] * response[i]['quantity'])
					excelHeaders.push(excelItems)
				}

				const ws = XLSX.utils.aoa_to_sheet(excelHeaders);
				const wb = XLSX.utils.book_new();
				XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
				XLSX.writeFile(wb, t('sales_report') + ".xlsx");
			}
		} else {
			const response = await GET(filterUrl, {}, { loader: true })
			if (httpOk(response)) {
				setData(response.data)
				setPagination({ ...pagination, 'url': filterUrl, 'response': response.data })
			}
		}
	}

	function resetFilter() {
		setFilterData({
			search: '',
		})
		searchWithFilter()
	}

	function checkFilter() {
		if (filterData.search === '') {
			setFilterDataExist(false)
		}
	}

	async function paginate(page) {
		setFilterData({ ...filterData, 'page': page })
	}

	function toggleModalPayment(bool) {
		if (!bool) {
			var selectedItemCopy = {}
			selectedItemCopy.payment_type_id = 1
			selectedItemCopy.amount = ''
		}

		if (!selectedItem?.organization_id) {
			toast.warning(t('no_organization_selected'))
			return
		}

		setModalPayment(bool)
	}

	useEffect(() => {
		searchWithFilter(false)
	}, [filterData.search, filterData.page]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header">
				<h4 className="vertical-center h-100">
					{t('organizations')} [{data?.total}]
				</h4>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex justify-content-between flex-wrap mb-3">
						<div className="d-flex gap-2">
							<FilterSearch
								filterData={filterData}
								setFilterData={setFilterData}>
							</FilterSearch>
							<div>
								<CustomTooltip text={t('EXCEL')}>
									<button className="btn btn-outline-primary btn-wide-2"
										onClick={() => searchWithFilter(true)}>
										<i className="uil uil-file-download-alt"></i>
									</button>
								</CustomTooltip>
							</div>
							<div>
								<button className="btn btn-outline-primary btn-wide"
									onClick={() => toggleModalPayment(true)}>
									{t('pay')}
								</button>
							</div>
						</div>

						<div className="d-flex">
							<div className="vertical-center me-2">
								<h4><b>{t('amount')}</b>:</h4>
							</div>
							<div className="vertical-center">
								<h5 className="text-end">
									<span className={`me-1 ${data.total_amount > 0 ? 'text-success' : 'text-danger'}`}>
										{formatMoney(data.total_amount)}
									</span>
								</h5>
							</div>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('filter')}</strong>
								<br />
								{filterData.search &&
									<span className="me-2">
										<b>{t('search')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase"
									onClick={resetFilter}>{t('reset')}
								</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-bordered table-hover cursor mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '20%' }}>{t('name3')}</th>
									<th style={{ 'width': '20%' }}>{t('legal_name')}</th>
									<th style={{ 'width': '10%' }}>{t("phone")}</th>
									<th style={{ 'width': '30%' }}>{t("note")}</th>
									<th style={{ 'width': '10%' }}></th>
									<th style={{ 'width': '10%' }} className="text-end">{t("debt")}</th>
								</tr>
							</thead>
							<tbody>
								{data?.data?.map((item, index) => (
									<tr className={'tr-middle ' + (
										(
											selectedItem &&
											selectedItem.pos_id === item.pos_id &&
											selectedItem.organization_id === item.organization_id
										) ? 'table-tr-active' : '')}
										key={index}
										onClick={() => selectItem(item)}>
										<td className="text-center">{item.row_number}</td>
										<td>
											<Link className="text-primary cursor table-first-column-clickable"
												to={`/report-organizations-debts/${item.organization_id}`}>
												{item.name}
											</Link>
										</td>
										<td>
											<Link className="text-primary cursor table-first-column-clickable"
												to={`/report-organizations-debts/${item.organization_id}`}>
												{item.legal_name}
											</Link>
										</td>
										<td>{regexPhoneNumber(item.organization_phone)}</td>
										<td>{item.note}</td>
										<td></td>
										<td className="text-end">
											<div className={item.balance > 0 ? 'text-primary' : 'text-danger'}>
												<span className="me-1">{formatMoney(item.balance)}</span>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					{pagination.response &&
						<FilterPagination
							pagination={pagination}
							paginate={paginate}>
						</FilterPagination>
					}
				</div>
			</div>

			{/* MODAL PAYMENT */}
			<Modal show={modalPayment} animation={false} centered dialogClassName="update-modal-width"
				backdrop="static" onHide={() => toggleModalPayment(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('pay')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="d-flex justify-content-between mb-2">
						<span>{t('pos')}</span>
						<span>{selectedItem.pos_name}</span>
					</div>
					<div className="d-flex justify-content-between mb-2">
						<span>{t('organization')}</span>
						<span>{selectedItem.name}</span>
					</div>
					<form onSubmit={payment} autoComplete="off">
						<div className="form-group">
							<label>{t('payment_method')}</label>
							<Select
								options={accountTypes}
								value={accountTypes.find(option => option.id === selectedItem.account_type)}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'account_type': option.id })}
								placeholder=""
								noOptionsMessage={() => t('list_empty')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						<div className="form-group">
							<label>{t('date')}</label>
							<div className="calendar-input">
								<DatePicker
									className="form-control"
									dateFormat="dd.MM.yyyy"
									selected={selectedItem.created_at ? new Date(selectedItem.created_at) : ''}
									onChange={(date) => setSelectedItem({ ...selectedItem, 'created_at': formatDate(date, 'yyyy-MM-dd HH:mm') })}
									minDate={returnMinDate(new Date(), reduxAccount.backdate ?? 3)}
									maxDate={new Date()}
								/>
								<i className="uil uil-calendar-alt"></i>
							</div>
						</div>
						<div className="form-group">
							<label>{t('amount')}<span className="required-mark">*</span></label>
							<input type="text" className="form-control" name="amount" autoFocus
								value={selectedItem.amount ? formatMoney(selectedItem.amount) : ''}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'amount': e.target.value.replace(/[^0-9.]/g, '') })} />
						</div>
						<div className="form-group">
							<label>{t('note')}</label>
							<input type="text" className="form-control" name="note"
								onChange={(e) => setSelectedItem({ ...selectedItem, 'note': e.target.value })} />
						</div>
						<div className="d-flex gap-2 w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100 gap-2"
								onClick={() => toggleModalPayment(false)} tabIndex="-1">
								{t('cancel')}
							</button>
							<button type="submit" className="btn btn-primary w-100" disabled={!selectedItem.amount}>
								{t('save')}
							</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL PAYMENT */}
		</>
	)
}

export default Index
